import React from 'react';
import styled from 'styled-components';


const StyledHeader = styled.header`
    background-color: #333;
    color: #fff;
    padding: 15px 0;
    text-align: center;
`;


const StyledContainer = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
`;

const StyledArticle = styled.article`
    margin-bottom: 40px;
`;

const StyledArticle2 = styled.article`
    margin-bottom: 40px;
`;

const StyledParagraph = styled.p`
    line-height: 1.2;
    text-align: justify;
    margin: 20px auto; /* Center the text vertically and horizontally */
    max-width: 700px; /* Set maximum width for the paragraph */
`;

const StyledAds = styled.div`
    text-align: center;
`;

const StyledAds2 = styled.div`
    text-align: center;
`;

const StyledAdImage = styled.img`
    display: block;
    margin: 0 auto; /* Center the image */
    max-width: 100%;
    height: auto;
    border: 2px solid black;
    border-radius: 5px;
    animation: borderBlink 0.6s infinite alternate;  
`;
const StyledAdImage3 = styled.img`
    display: block;
    margin: 0 auto; /* Center the image */
    width: 62%;
    height: auto;
    border: 2px solid black;
    border-radius: 5px;
    animation: borderBlink 0.6s infinite alternate;

    @media screen and (max-width: 768px) {
        width: 100%; /* Set the width to 100% on smaller screens */
    }
`;

const StyledAdImage2 = styled.img`
    display: block;
    margin: 0 auto; /* Center the image */
    width: 62%;
    height: auto;
    border: 2px solid black;
    border-radius: 5px;
    animation: borderBlink 0.6s infinite alternate;

    @media screen and (max-width: 768px) {
        width: 100%; /* Set the width to 100% on smaller screens */
    }
`;

const NewsWebsite = () => {
    return (
        <>
            <StyledHeader>
                <h1>News Website</h1>
            </StyledHeader>

            <StyledContainer>
                <StyledArticle>
                <div style={{ textAlign: 'center' }}>
                    <h2>NBA Trade Deadline 2024 Overview</h2>
                </div>
                    <StyledAdImage src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRE5n9O_X9pGV0C95vXCMqsgAo_xxplAIeO0NaN3nr1pFccPtLnwbwUAtu3SrEUqBv95hI&usqp=CAU" alt="NBA Image" />
                    <StyledParagraph>As of the 2024 NBA trade deadline, the Los Angeles Lakers chose not to make any significant moves, despite the rampant speculation leading up to the deadline. This decision has been a point of contention and analysis among sports commentators and fans alike. The Lakers, with their storied history and championship aspirations, have been under the microscope, particularly regarding how they plan to optimize their roster around their star players.</StyledParagraph>
                </StyledArticle>

                <StyledArticle>
                    <StyledAds>
                    <div style={{ textAlign: 'center' }}>
                        <h2>Coupon Advertisement</h2>
                    </div>
                        <div id="adContainer">
                            <a href="/for-eyes" target="_blank" rel="noopener noreferrer">
                                <StyledAdImage src="https://prod.loclshop.com/api/uploads/1710845205676-869446120-for_eyes_creative_gif(4).gif" alt="Advertisement" />
                            </a>
                        </div>
                    </StyledAds>
                </StyledArticle>

                <StyledArticle>
                <div style={{ textAlign: 'center' }}>
                        <h2>Super Bowl LVIII Preview</h2>
                </div>
                    <StyledAdImage3 src="https://static.www.nfl.com/image/private/t_new_photo_album/league/xa1aqqu4qzk2hytetzsu.jpg" alt="Super Bowl Image" />
                    <StyledParagraph>Super Bowl LVIII promises to be a thrilling showdown, with the San Francisco 49ers facing off against the Kansas City Chiefs at Allegiant Stadium in Las Vegas, Nevada , Super Bowl LVIII promises to be a thrilling showdown, with the San Francisco 49ers facing off against the Kansas City Chiefs at Allegiant Stadium in Las Vegas, Nevada. This game is not just a battle for the coveted Lombardi Trophy but also a showcase of two of the NFL's most dynamic teams. The 49ers, known for their formidable defense and efficient offense, and the Chiefs, celebrated for their explosive playmaking ability led by quarterback Patrick Mahomes, present a compelling matchup. Beyond the game, the Super Bowl is a cultural phenomenon, featuring a highly anticipated halftime show and a bevy of commercials that have become almost as iconic as the game itself. Additionally, the tradition of Super Bowl Squares adds a layer of engagement for fans, blending the love of football with a popular betting game. This event encapsulates not only the pinnacle of professional football achievement but also a significant moment in American sports culture.
                </StyledParagraph>
                </StyledArticle>

                <StyledArticle2>
                    <StyledAds2>
                        <h4>Coupon Advertisement</h4>
                        <div id="adContainer2">
                        <a href="/marblehill" target="_blank" rel="noopener noreferrer">
                            <StyledAdImage2 src="https://prod.loclshop.com/api/uploads/1710844342093-446523883-Marble hill shop_adding_logo.gif" alt="Advertisement" />
                        </a>

                        </div>
                    </StyledAds2>
                </StyledArticle2>
            </StyledContainer>
        </>
    );
};

export default NewsWebsite;
