import React from 'react';
import styled from 'styled-components';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const StyledContainer = styled(Container)`
  margin-top: 2rem;
  text-align: center;
`;

const StyledHeading = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 7rem;
`;

const StyledButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledButton = styled(Button)`
  background-color: #007bff;
  color: #fff;
  font-size: 1.2rem;
  padding: 0.8rem 1.5rem;
`;

const AdminPortal = () => {
  const navigate = useNavigate();

  const handleAdminClick = () => {
    navigate('/admin');
  };

  const handleAgencyClick = () => {
    navigate('/login');
  };

  return (
    <StyledContainer>
      <StyledHeading>Welcome to LOCLshop Admin Portal</StyledHeading>
      <Row>
        <Col>
          <StyledButtonGroup>
            <StyledButton onClick={handleAdminClick}>
              Log In as LOCLshop Admin
            </StyledButton>
            <StyledButton onClick={handleAgencyClick}>
              Log In as Ad Agency Admin
            </StyledButton>
          </StyledButtonGroup>
        </Col>
      </Row>
    </StyledContainer>
  );
};

export default AdminPortal;