import React, { useEffect } from 'react';
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import SideBar from '../components/UserSideBar';
import { Link } from 'react-router-dom';

const Button = styled.button`
  padding: 15px 30px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  transition: background-color 0.3s;
  width: 100%;
  &:hover {
    background-color: #0056b3;
  }
`;

const ButtonContainer = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Container = styled.div`
  margin-top: -10%; /* Move the buttons up */
`;


function UserOptions() {
  const loadDashboard = async () => {
    try {
      const emailId = localStorage.getItem('emailid');
      await fetch('https://prod.loclshop.com/api/api/loginactivity', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: emailId }),
      });
    } catch (error) {
      console.error('Error loading dashboard:', error);
    }
  };

  useEffect(() => {
    const loginCount = localStorage.getItem('loginCount');
  
    if (!loginCount) {
      localStorage.setItem('loginCount', '1');
      loadDashboard();
    } else if (loginCount < '1') {
      loadDashboard();
    }
  }, []);

  const options = [];
  const d1 = localStorage.getItem('dashboardid');
  const dt1 = localStorage.getItem('token');
  if (d1 !== null && dt1 !== null) {
    options.push({ name: "Dashboard", path: "/branddashboard" });
  }

  const r1 = localStorage.getItem('repaadashboardid');
  const rt1 = localStorage.getItem('repaatoken');
  if (r1 !== null && rt1 !== null) {
    options.push({ name: "Report", path: "/brandrepaa" });
  }

  // const sr1 = localStorage.getItem('samplerepaadashboardid');
  // const srt1 = localStorage.getItem('samplerepaatoken');
  // if (sr1 !== null && srt1 !== null) {
  //   options.push({ name: "Standard Repaa", path: "/samplerepaa" });
  // }

  // const sd1 = localStorage.getItem('sampledashboardid');
  // const sdt1 = localStorage.getItem('sampledashboardtoken');
  // if (sd1 !== null && sdt1 !== null) {
  //   options.push({ name: "Standard Dashboard", path: "/sampledashboard" });
  // }

  options.push({ name: "Standard Repaa", path: "/samplerepaa" });
  options.push({ name: "Standard Dashboard", path: "/sampledashboard" });
  
  return (
    <SideBar>
      <Container className="container">
        <div className="row justify-content-center align-items-center min-vh-100">
          <div className="col-md-8">
            <ButtonContainer>
              {options.map((option, index) => (
                <Link to={option.path} key={index}>
                  <Button>{option.name}</Button>
                </Link>
              ))}
            </ButtonContainer>
          </div>
        </div>
      </Container>
    </SideBar>
  );
}

export default UserOptions;