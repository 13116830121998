import React from 'react';
import SampleEmbeddedRepaa from './samplerepaa';
import SideBar from '../components/UserSideBar'


const SampleRepaaComp = () => {

    return (
        <div>
            <SideBar>
                <SampleEmbeddedRepaa />
            </SideBar>
        </div>
    );
};

export default SampleRepaaComp;
