import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from 'styled-components';
import Footer from '../components/sticky_footer'


// Styled components for custom styling
const DisclosureContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f0f0f0; /* Set background color */
  font-family: Arial, Helvetica, sans-serif;

`;

const DisclosureTextBG = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f0f0f0; /* Set background color */
  font-family: Arial, Helvetica, sans-serif;

`;

const DisclosureCard = styled.div`
  max-width: 800px;
  padding: 20px;
  background-color: #ffffff; /* Set background color */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add box shadow */
  font-family: Arial, Helvetica, sans-serif;

`;

const DisclosureTitle = styled.h2`
  font-size: 35px; /* Increase font size */
  font-weight: bold; /* Add font weight */
  text-align: center;
  margin-bottom: 20px;
  font-family: Arial, Helvetica, sans-serif;

`;

const DisclosureText = styled.p`
  font-size: 18px; /* Increase font size */
  line-height: 2; /* Adjust line height for readability */
  text-align: justify;
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
  font-family: Arial, Helvetica, sans-serif;

`;
const DisclosureText1 = styled.p`
  font-size: 19px; /* Increase font size */
  line-height: 1; /* Adjust line height for readability */
  text-align: justify;
  margin-bottom: 30px;
  margin-left: 10px;
  margin-top: 30px;
  margin-right: 10px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;

`;

export default function DisclosurePage() {
  return (
    <div className="d-flex flex-column min-vh-100 container my-3">
      <div className="row">
        <div className="col-12">
          <DisclosureContainer>
            <DisclosureCard>
              <DisclosureTitle>Advertiser Disclosure</DisclosureTitle>
              <DisclosureTextBG>
                <DisclosureText1>You're our first priority. Every time.</DisclosureText1>
                <DisclosureText>
                  Fairchild RMN, the owner of the LOCLshop Media Network, and the Powered by LOCLshop software platform, 
                  is an independent publisher, software developer, and Retail Proximity Promotion Advertising service, not 
                  a registered advisor or counselor. Its articles, interactive tools, and other content are provided to our 
                  audience for free, as tools to support local stores, find ways to save, and for informational purposes only. 
                  They are not intended to provide investment advice. Fairchild RMN does not and cannot guarantee the accuracy 
                  or applicability of any information regarding anyone's circumstances. Examples are hypothetical, and we 
                  encourage you to seek personalized advice from qualified professionals regarding specific issues. Any anticipated
                  savings or promotion recommendations are based on experience and past promotions, and past promotions are not a 
                  guarantee of future promotions.
                </DisclosureText>
                <DisclosureText>
                  We believe connecting shoppers with local retailers makes communities better. We’re proud that the guidance 
                  we offer, the information we provide and the tools we create are objective, independent, straightforward — 
                  and free. We hope the result is a more delightful shopping experience from supporting the retailers that are 
                  an important part of their respective communities.
                </DisclosureText>
                <DisclosureText>
                  So how do we make money? Our partners compensate us. This may influence which products we review and write 
                  about (and where those products appear on the site), but it in no way affects our recommendations or advice, 
                  which are grounded in thoughtful research. Our partners cannot pay us to guarantee favorable reviews of their 
                  products or services. Here is a list of our partners
                </DisclosureText>
                <ul>
                <li>
                  <a href="https://www.target.com/circle">www.target.com/circle</a>
                </li>
                <li>
                  <a href="https://m.marshalls.com/us/m/jump/topic/Digital-Rewards-Certificates/20500003p">m.marshalls.com</a>
                </li>
                <li>
                  <a href="https://tinyurl.com/macysstarrewards">macysstarrewards.com</a>
                </li>
                <li>
                  <a href="https://www.albertsons.com/foru/program-details.html">www.albertsons.com</a>
                </li>
                <li>
                  <a href="https://clubpublix.com/">www.clubpublix.com</a>
                </li>
              </ul>
              </DisclosureTextBG>
            </DisclosureCard>
            <Footer />
          </DisclosureContainer>
        </div>
      </div>
    </div>
  );
}
