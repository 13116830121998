import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import SideBar from '../components/SideBar';
import Typography from '@mui/material/Typography';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  height: 100vh;
`;

const TextInput = styled.input`
  padding: 12px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 6px;
  box-sizing: border-box;
  width: 30%;
  font-size: 16px;

  @media (max-width: 768px) {
    width: 75%;
  }
`;

const SubmitButton = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #45a049;
  }
`;

const RepaaAdmin = () => {
  const [token, setToken] = useState(null);
  const [guestToken, setGuestToken] = useState(null);
  const [error, setError] = useState(null);
  const [dashboardName, setDashboardName] = useState('');
  const [brandName, setBrandName] = useState('');
  const [dashboardId, setDashboardId] = useState('');
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    const fetchTokens = async () => {
      try {
        const { data: tokenData } = await axios.post(
          'https://ddp.softroniclabs.com/api/v1/security/login',
          {
            username: 'admin',
            password: 'admin',
            provider: 'db',
            refresh: false,
          }
        );

        setToken(tokenData.access_token);

        try {
          const { data: guestTokenData } = await axios.post(
            'https://ddp.softroniclabs.com/api/v1/security/guest_token/',
            {
              "resources": [
                {
                  "id": dashboardId,
                  "type": "dashboard"
                }
              ],
              "rls": [],
              "user": {
                "first_name": "Softroniclabs",
                "last_name": "Admin",
                "username": "admin"
              }
            },
            {
              headers: {
                Authorization: `Bearer ${tokenData.access_token}`,
              },
            }
          );

          setGuestToken(guestTokenData);

          try {
            const response = await axios.post(
              'https://prod.loclshop.com/api/api/repaa',
              {
                name: dashboardName,
                brandName: brandName,
                id: dashboardId,
                token: guestTokenData.token,
              }
            );

            if (response.status === 409) {
              setError('Dashboard with this ID already exists');
            } else {
              window.location.href = '/assign';
            }
          } catch (e) {
            if (e.response && e.response.status === 409) {
              setError('Dashboard with this ID already exists');
            } else {
              setError('An error occurred while posting data to the local endpoint');
            }
          }
        } catch (e) {
          setError(e);
        }
      } catch (e) {
        setError(e);
      }
    };

    if (submitted) {
      fetchTokens();
      setSubmitted(false);
    }
  }, [submitted, dashboardName, brandName, dashboardId]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form fields
    if (!dashboardName || !brandName || !dashboardId) {
      setError('All fields are mandatory');
      return;
    }

    setError(null);
    setSubmitted(true);
  };

  return (
    <SideBar>
      <div>
        <form>
          <FormContainer>
            <h2>Repaa Report Component</h2>
            <br />
            {error && typeof error === 'string' && (
              <Typography variant="body2" color="error" align="center" sx={{ mt: 1 }}>
                {error}
              </Typography>
            )}
            {error && typeof error !== 'string' && (
              <Typography variant="body2" color="error" align="center" sx={{ mt: 1 }}>
                An error occurred. Please try again later.
              </Typography>
            )}
            <label>Repaa Report Name:</label>
            <TextInput
              type="text"
              value={dashboardName}
              onChange={(e) => setDashboardName(e.target.value)}
            />

            <label>Brand Name:</label>
            <TextInput
              type="text"
              value={brandName}
              onChange={(e) => setBrandName(e.target.value)}
            />

            <label>Repaa Report Dashboard ID:</label>
            <TextInput
              type="text"
              value={dashboardId}
              onChange={(e) => setDashboardId(e.target.value)}
            />
            <SubmitButton type="submit" onClick={handleSubmit}>Submit</SubmitButton>
          </FormContainer>
        </form>
      </div>
    </SideBar>
  );
};

export default RepaaAdmin;