import React, { useEffect } from 'react';

const DashboardBrandEmbeddedRepaa = () => {
    useEffect(() => {
        // // Function to fetch guest token
        // const fetchGuestToken = () => localStorage.getItem("sampledashboardtoken");

        // // Retrieve dashboardId from localStorage
        // const dashboardId = localStorage.getItem("sampledashboardid");

        const id = "4e060627-83b6-4180-9d71-5ef84eab484c";

        const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjp7ImZpcnN0X25hbWUiOiJTb2Z0cm9uaWNsYWJzIiwibGFzdF9uYW1lIjoiQWRtaW4iLCJ1c2VybmFtZSI6ImFkbWluIn0sInJlc291cmNlcyI6W3sidHlwZSI6ImRhc2hib2FyZCIsImlkIjoiNGUwNjA2MjctODNiNi00MTgwLTlkNzEtNWVmODRlYWI0ODRjIn1dLCJybHNfcnVsZXMiOltdLCJpYXQiOjE3MTgwOTEwMTkuNzI4NzA2LCJleHAiOjE3NDk2MjcwMTkuNzI4NzA2LCJhdWQiOiJodHRwOi8vc3VwZXJzZXQ6ODA4OC8iLCJ0eXBlIjoiZ3Vlc3QifQ.e5NsVPYMvRK9iwgWDA2M7I14OlTc4IzB-22DHVItopA";

        // Function to fetch guest token
        const fetchGuestToken = () => token;

        // Retrieve dashboardId from localStorage
        const dashboardId = id;
        

        // Check if dashboardId and token exist
        if (dashboardId && fetchGuestToken()) {
            // Load Superset Embedded SDK script dynamically
            const script = document.createElement('script');
            script.src = 'https://unpkg.com/@superset-ui/embedded-sdk';
            script.async = true;
            script.onload = () => {
                window.supersetEmbeddedSdk.embedDashboard({
                    id: dashboardId,
                    supersetDomain: 'https://ddp.softroniclabs.com',
                    mountPoint: document.getElementById('container'),
                    fetchGuestToken: fetchGuestToken,
                    dashboardUiConfig: {
                        filters: {
                            expanded: false
                        }
                    }
                });

                // Additional code to modify iframe dimensions
                const dashboardElement = document.getElementById('container');
                if (dashboardElement) {
                    const iframe = dashboardElement.querySelector('iframe');
                    if (iframe) {
                        iframe.style.width = '100%'; // Set the width to 100% by default
                        iframe.style.height = '1960px'; // Set the height as needed
                    }
                }
            };

            document.body.appendChild(script);

            // Cleanup function
            return () => {
                // Remove the dynamically added script when component unmounts
                document.body.removeChild(script);
            };
        }
    }, []); // Empty dependency array ensures this effect runs only once

    return <div id="container" />;
};

export default DashboardBrandEmbeddedRepaa;
