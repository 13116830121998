import ReactGA from 'react-ga';

export const initGA = () => {
  ReactGA.initialize('AW-16449170732/LwU1CKDy-KUZEKzayaM9');
};

export const logEvent = (category = '', action = '', label = '') => {
  if (category && action && label) {
    ReactGA.event({ category, action, label });
  }
};