import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import { FaBars, FaHome, FaTable, FaUserCircle } from "react-icons/fa";
import { BiLogOut } from "react-icons/bi";
import { AiOutlineUserAdd, AiFillDashboard } from "react-icons/ai";
import { motion, AnimatePresence } from 'framer-motion';
import { ImStatsDots, ImStatsBars } from "react-icons/im";
import { MdManageAccounts } from "react-icons/md";
import { FiActivity } from "react-icons/fi";

import './sidebar.css';

const SideBar = ({ children, onDashboardToggle }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [username, setUsername] = useState(localStorage.getItem('username'));
  const navigate = useNavigate();

  useEffect(() => {
    setUsername(localStorage.getItem('username'));
  }, []);

  const toggle = () => {
    //console.log("Toggle function called");
    setIsOpen(!isOpen);
  };

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };

  const handleRouteClick = (route) => {
    if (route.name === "Dashboard") {
      onDashboardToggle();
    }
    setIsOpen(false);
  };

  const handleLogout = () => {
    localStorage.removeItem('authenticated');
    localStorage.removeItem('User_authenticated');
    localStorage.removeItem('dashboardId');
    navigate('/admin');
  };

  const isAdminOrSpecificUser = username === "michael" || username === "doug" || username?.endsWith('_admin');

  const routes = [
    {
      name: username ? username : "Username",
      icon: <FaUserCircle />,
      path: "/report"
    },
    {
      path: "/table",
      
      name: "Dashboard",
      icon: <ImStatsBars />,
    },
    {
      path: "/report",
      name: "Report",
      icon: <ImStatsDots />,
    }, 

  ];

  // Conditionally add the Manage Users route
  if (isAdminOrSpecificUser) {
    routes.push({
      name: "Manage Users",
      path: "/manageusers",
      icon: <MdManageAccounts />,
    });
  
    // Add another route within the same condition
    routes.push({
      name: "Assign Dashboards",
      path: "/assign",
      icon: <AiFillDashboard />,
    });

    routes.push({
      name: "User Activity",
      path: "/useractivity",
      icon: <FiActivity />,
    });

  }
  
  return (
    <div className="main-container">
      <motion.div
        animate={{
          width: isOpen ? "200px" : "75px",
          transition: {
            duration: 0.5,
            type: "spring",
            damping: 5,
          },
        }}
        className="sidebar"
      >
        <div className="top_section">
          <AnimatePresence>
            {isOpen && (
              <motion.h2
                variants={showAnimation}
                initial="hidden"
                animate="show"
                exit="hidden"
                className="logo"
              >
                RePAA Report
              </motion.h2>
            )}
          </AnimatePresence>
          <div className="bars" onClick={toggle}>
            <FaBars />
          </div>
        </div>
        <section className="routes">
          {routes.map((route, index) => (
            <NavLink
              to={route.path}
              key={index}
              className="link"
              activeClassName="active"
              onClick={() => handleRouteClick(route)}
            >
              <div className="icon">{route.icon}</div>
              <AnimatePresence>
                {isOpen && (
                  <motion.div
                    variants={showAnimation}
                    initial="hidden"
                    animate="show"
                    exit="hidden"
                    className="link_text"
                  >
                    {route.name}
                  </motion.div>
                )}
              </AnimatePresence>
            </NavLink>
          ))}
          <div className="link" onClick={handleLogout}>
            <div className="icon"><BiLogOut /></div>
            {isOpen && <div className="link_text">Logout</div>}
          </div>
        </section>
      </motion.div>
      <main style={{ width: isOpen ? '90vw' : '95vw' }}>
        {children}
      </main>
    </div>
  );
};

export default SideBar;