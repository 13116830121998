import React from "react";
import './sticky_footer_css.css'
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StikyFooter = styled.div`
  text-align: center; /* Apply justify alignment */
  background-color: #060606;
  width: 100%;
  padding: 20px;
`;

const FooterSubHeading = styled.p`
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: 15px;
  color: white; /* Change font color */
  text-decoration: underline; /* Add underline */
`;

const FooterLink = styled(Link)`
  color: white;
  text-decoration: none;
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: 15px;
  text-decoration: underline; /* Add underline */
`;

const StikyFooterComponent = () => (
  <StikyFooter>
    <FooterSubHeading>
      <FooterLink to="/disclosure">Copyright © 2024 Locl Shop - All Rights Reserved.</FooterLink>
    </FooterSubHeading>
  </StikyFooter>
);

export default StikyFooterComponent;
