import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  text-align: center;
`;

const Title = styled.h1`
  color: #333;
  font-size: 2.5rem;
  margin-bottom: 2rem;
`;

const InfoText = styled.p`
  color: #666;
  font-size: 1.2rem;
  line-height: 1.5;
`;

const LogoutButton = styled.button`
  background-color: #ff0000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #cc0000;
  }
`;

const UserReport = () => {
  const handleLogout = () => {
    localStorage.removeItem('User_authenticated'); // Remove the 'User_authenticated' item from local storage
    window.location.href = '/login'; // Redirect to the home page or any desired location after logout
  };

  return (
    <Container>
      <Title>Welcome to LOCLshop</Title>
      <InfoText>
        Thank you for using LOCLshop! We're glad to have you onboard.
      </InfoText>
      <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
    </Container>
  );
};

export default UserReport;
