import React, { useState, useEffect, useMemo } from 'react';
import inf3 from '../assets/images/inf3.png';
import Footer from '../components/sticky_footer';
import postData from './blog.json'; // Make sure to provide the correct path
import { useNavigate, useLocation } from 'react-router-dom';


const Blog = () => {
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [categories, setCategories] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [numColumns, setNumColumns] = useState(3); // Default to 3 columns
  const [isCategorySelected, setIsCategorySelected] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  
  useEffect(() => {
    // Extract categories from the postData
    const loadedCategories = [...new Set(postData.AllPosts.map(category => category.Category))];
    setCategories(['All', ...loadedCategories]);

    // Adjust number of columns based on screen size
    const handleResize = () => {
      if (window.innerWidth < 768) { // Mobile devices
        setNumColumns(1); // Display one post per row
      } else if (window.innerWidth < 992) { // Tablets
        setNumColumns(2); // Display two posts per row
      } else {
        setNumColumns(3); // Display three posts per row on larger screens
      }
    };

    // Initial call to set the initial number of columns
    handleResize();

    // Event listener to handle window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setCurrentIndex(0); // Reset index when changing categories
    setIsCategorySelected(true); // Set flag to true when a category is selected
  };

  // Get the category parameter from the URL
  const queryParams = new URLSearchParams(location.search);
  const categoryParam = queryParams.get('category');
  console.log("categoryParam-----",categoryParam)

  const filteredPosts = useMemo(() => {
    const categoryToUse = categoryParam ? categoryParam : selectedCategory; // Use categoryParam if it exists, otherwise use selectedCategory
    return categoryToUse === 'All'
        ? postData.AllPosts.flatMap(category => category.posts)
        : postData.AllPosts.find(category => category.Category === categoryToUse)?.posts || [];
}, [categoryParam, selectedCategory]);

  
  // Ensure the index stays within the range of pages
  const maxIndex = Math.ceil(filteredPosts.length / numColumns) - 1;

  const handlePrev = () => {
    setCurrentIndex(currentIndex => Math.max(currentIndex - 1, 0));
  };

  const handleNext = () => {
    setCurrentIndex(currentIndex => Math.min(currentIndex + 1, maxIndex));
  };

  // Get the subset of posts to display
  const visiblePosts = useMemo(() => {
    const startIndex = currentIndex * numColumns;
    return filteredPosts.slice(startIndex, startIndex + numColumns);
  }, [currentIndex, filteredPosts, numColumns]);

  return (
    <div>
      <style>
        {`
          .hover-underline:hover {
            text-decoration: underline;
          }
        `}
      </style>
      <div className="position-relative" style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${inf3})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundAttachment: 'fixed',
        minHeight: '130vh',
      }}>
        {/* Overlay Content */}
      <div className="text-center py-5" >
        <h2 className="mb-4 text-white">SUPPORT LOCAL STORIES</h2>

        {/* Category Filter Buttons */}
        <div className="justify-content-start mb-3" style={{ alignItems: 'flex-start' }}>
        {categories.map((category) => (
          <button
            key={category}
            className={`btn btn-light me-2 ${categoryParam === category ? 'active' : selectedCategory === category ? 'active' : ''}`}
            onClick={() => handleCategoryClick(category)}
            aria-label={`Filter posts by ${category}`}
            style={{ marginBottom: '10px' }} // Add a bottom margin to each button
          >
            {category}
          </button>
        ))}
      </div>

      {/* Posts */}
      <div className="container" style={{ height:"35%",paddingTop: '4rem', backgroundColor: 'transparent' }}>
        <div className="row justify-content-center align-items-center mb-8">
          <div className="col-auto">
            <button className="card bg-light btn mx-1" onClick={handlePrev} disabled={currentIndex === 0}>
              {'<'}
            </button>
          </div>
          <div className="col">
            <div className="row">
              {visiblePosts.map((post, index) => (
                <div key={index} className={`col-md-${12 / numColumns} mb-4`}>
                  <div className="card bg-light">
                  <div className="card-body" style={{ height: '350px', overflow: 'auto', padding: '5px', marginBottom: '8vh' }}>
                      <p className="card-text" style={{ padding: '2vh', marginBottom: '4vh' }} >{post.date}</p>
                      <h5 className="card-title" style={{ padding: '2vh', marginBottom: '4vh' }} >{post.title}</h5>
                      <h6 className="card-text hover-underline" onClick={() => navigate(`/single-blog?category=${encodeURIComponent(post.Category)}&title=${encodeURIComponent(post.title)}`)} style={{ cursor: 'pointer' }}>
                        Continue Reading
                      </h6>

                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-auto">
            <button className="card bg-light btn mx-2" onClick={handleNext} disabled={currentIndex === maxIndex}>
              {'>'}
            </button>
          </div>
        </div>
      </div>

    </div>
        {/* Sticky Footer */}
    <div className="position-absolute bottom-0 w-100">
      <Footer />
    </div>
  </div>
</div>
  );
};

export default Blog;
