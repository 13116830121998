// src/App.js
import React from 'react';
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import SideBar from './SideBar';

const Button = styled.button`
  padding: 15px 30px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  transition: background-color 0.3s;
  width: 100%;

  &:hover {
    background-color: #0056b3;
  }
`;

const ButtonContainer = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Container = styled.div`
  margin-top: -10%; /* Move the buttons up */
`;

function AssignPage() {
  return (
    <SideBar>
      <Container className="container">
        <div className="row justify-content-center align-items-center min-vh-100">
          <div className="col-md-8">
            <ButtonContainer>
              <ButtonWrapper>
                <Button onClick={() => window.location.href = '/dashboardadmin'}>
                  Assign Dashboard
                </Button>
              </ButtonWrapper>
              <ButtonWrapper>
                <Button onClick={() => window.location.href = '/repaaadmin'}>
                  Assign REPAA Report
                </Button>
              </ButtonWrapper>
              {/* <ButtonWrapper>
                <Button onClick={() => window.location.href = '/sampledashboardadmin'}>
                  Assign Sample Dashboard
                </Button>
              </ButtonWrapper>
              <ButtonWrapper>
                <Button onClick={() => window.location.href = '/samplerepaaadmin'}>
                  Assign Sample REPAA
                </Button>
              </ButtonWrapper> */}
            </ButtonContainer>
          </div>
        </div>
      </Container>
    </SideBar>
  );
}

export default AssignPage
