import React, { useState, useEffect, useRef } from 'react';
import './landing_page.css'; // Import CSS file for styling
import { motion } from 'framer-motion';
import $ from 'jquery'; // Import jQuery
import 'select2'; // Import Select2 libraryimport Checkbox from '@mui/joy/Checkbox';
import styled from 'styled-components'; // Import styled-components   
import axios from 'axios';
import reverseGeocode from '../components/geoencoding'

export default function Foreyesv1 () {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [imagesData, setImagesData] = useState([]);
    const [isReturningUser, setIsReturningUser] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const selectedSms = useState(null);
    const selectedName = useState(null);
    const [smsSent, setSmsSent] = useState(false); 
    const [showPhoneNumberPopup, setShowPhoneNumberPopup] = useState(false);
    const [openSecond, setOpenSecond] = useState(false);
    const [showError, setShowError] = useState(false);
    const [selectedImages, setSelectedImages] = useState([]);
    const [showTenantAds, setShowTenantAds] = useState(false); 
    const [selectedCategory, setSelectedCategory] = useState('');
    const filteredImages = selectedCategory ? imagesData.filter(image => image.category === selectedCategory) : imagesData;
    const [userLocation, setUserLocation] = useState(null);
    const [userAddress, setUserAddress] = useState(null); // New state for user address
    const selectRef = useRef(null); // Create a ref for the select element
    const newRef = useRef(null);
    const smsnewRef = useRef(null);    

    const defUrl = process.env.REACT_APP_API_URL

    useEffect(() => {
        checkReturningUser();
        // Check if phone number is stored in local storage
        const storedPhoneNumber = localStorage.getItem('phoneNumber');
        if (storedPhoneNumber) {
            setShowPhoneNumberPopup(false); // Hide phone number popup if phone number is already stored
            setIsReturningUser(true);
        } else {
            setShowPhoneNumberPopup(true); // Show phone number popup if phone number is not stored
        }
        const reverseGeocode = (latitude, longitude) => {
            const accessToken = 'sk.eyJ1IjoiamVldmFuLWwtc29mdHJvbmljbGFicyIsImEiOiJjbHI3ZW0zYzMyZjlvMnJvMWJjZ3BhZ2IyIn0.CBJY-lsvhRaaagQPeCs8cw';
            const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${accessToken}`;
            fetch(url)
                .then(response => response.json())
                .then(data => {
                    const place = data.features[0].place_name;
                    setUserAddress(place); // Set user's address
                    console.log("User is currently at:", place);
                    // Call to send SMS once address is available
                    handleSendSMSMessages(storedPhoneNumber, place, { latitude, longitude });
                })
                .catch(error => {
                    console.error('Error reverse geocoding:', error);
                });
        };
        const handleSendSMSMessages = async (phoneNumber, userAddress, { latitude, longitude }) => {
            if (!phoneNumber || !userAddress) {
                console.error('Missing user info for sending SMS');
                return;
            }
            try {
                const response = await fetch('https://prod.loclshop.com/api/for-eyes-sms');
                console.log("useraddress from inside : ", userAddress);
                if (!response.ok) {
                    throw new Error('Failed to fetch SMS data');
                }
                const jsonData = await response.json();
                const messages = jsonData.messages;
                const messageBody = messages.join('\n');
                if (!isReturningUser) {
                await sendSMS(phoneNumber, messageBody, latitude, longitude, userAddress);
                }
            } catch (error) {
                console.error('Error processing SMS messages:', error);
            }
        };

        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setUserLocation({ latitude, longitude });
                    reverseGeocode(latitude, longitude); // Also triggers SMS sending
                },
                (error) => {
                    console.error('Error getting user location:', error);
                }
            );
        } else {
            console.error("Geolocation is not supported by this browser.");
        }
    
        fetchImagesData();        
        document.addEventListener("mousedown", handleOutsideClick);    
        return () => {
                $(selectRef.current).select2('destroy');
                document.removeEventListener("mousedown", handleOutsideClick);
            };
    }, []); // Empty dependency array means this runs once on mount
    
    const StyledFooterButton = styled.button`
        position: absolute;
        bottom: 10px;
        left: 25%;
        margin-top: 4%;
        padding: 10px 20px;
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        &:hover {
            background-color: #0056b3;
        }

        @media screen and (min-width: 768px){
            left: 42%; // Adjusted left position for screens <= 768px width
        }

        @media screen and (min-width: 431px) and (max-width: 768px) {
            left: 35%; // Adjusted left position for screens <= 768px width
        }
    `;

    

    const checkReturningUser = () => {
        const storedPhoneNumber = localStorage.getItem('phoneNumber');
        if (storedPhoneNumber) {
            setIsReturningUser(true);
            setPhoneNumber(storedPhoneNumber);
            setShowPhoneNumberPopup(true); // Set showPhoneNumberPopup to false if the phone number is already in local storage
        } else {
            setShowPhoneNumberPopup(false); // Set showPhoneNumberPopup to true if the phone number is not in local storage
        }
    };

    const handleOutsideClick = (e) => {
        const storedPhoneNumber = localStorage.getItem('phoneNumber');
        if (newRef.current && !newRef.current.contains(e.target)) {
            setSelectedImage(null);
            setOpenSecond(false);
        }
        if (storedPhoneNumber && smsnewRef.current && !smsnewRef.current.contains(e.target)) {
            setSelectedImage(null);
        }
      };
    // Function to fetch images data from the server
    const fetchImagesData = async () => {
        
        try {
            const response = await fetch('https://prod.loclshop.com/api/images2', {
                mode: 'cors' // Add the mode option here
            });
            if (!response.ok) {
                throw new Error('Failed to fetch images data');
            }
            const jsonData = await response.json();
            setImagesData(jsonData);
            
        } catch (error) {
            console.error('Error fetching images data:', error);
        }
    };
    // Function to save phone number in local storage
    const savePhoneNumber = () => {
        if (phoneNumber.trim() !== '') {
            localStorage.setItem('phoneNumber', phoneNumber);
            setShowPhoneNumberPopup(false);
        } else {
            // Show error message
            setShowError(true);
            // Hide error message after 3 seconds
            setTimeout(() => {
                setShowError(false);
            }, 5000);
        }
    };

    const SFooter = () => (
        <div className="stickyfooter">
            <StyledFooterButton type="button" onClick={sendSMS}>
                {selectedImages.length === imagesData.length ? 'Get All Coupon Codes!' : 'Resend All Coupon Codes!'}
            </StyledFooterButton>  
        </div>
    );

    const savePhoneNumber1 = () => {
        localStorage.setItem('phoneNumber', phoneNumber);
        setPhoneNumber(phoneNumber);
        const formattedPhoneNumber = phoneNumber.startsWith('+1') ? phoneNumber : '+1' + phoneNumber.trim();
    
        if (phoneNumber.length !== 10) {
            setShowError(true);
        } else {
            if ("geolocation" in navigator) {
                // Get user's current location
                navigator.geolocation.getCurrentPosition(
                    async (position) => {
                        const { latitude, longitude } = position.coords;
                        console.log(latitude, longitude);
                        const userLocation = { latitude, longitude };
                        try {
                            // Retrieve user's address from reverse geocoding
                            const address = await reverseGeocode(latitude, longitude);
                            setUserAddress(address);
                            console.log("User address:", address);
    
                            // Fetch SMS data
                            const smsResponse = await fetch('https://prod.loclshop.com/api/for-eyes-sms');
                            if (!smsResponse.ok) {
                                throw new Error('Failed to fetch SMS data');
                            }
                            const smsData = await smsResponse.json();
                            const messages = smsData.messages;
                            const messageBody = messages.join('\n');
    
                            // Send SMS
                            await sendSMS(formattedPhoneNumber, messageBody, latitude, longitude, address);
    
                            // Post phone number along with location and address to endpoint
                            const savePhoneNumberResponse = await axios.post('https://prod.loclshop.com/api/save-phone-number', {
                                phoneNumber: formattedPhoneNumber,
                                latitude: latitude,
                                longitude: longitude,
                                address: address
                            });
                            console.log('Phone number, location, and address saved successfully:', savePhoneNumberResponse.data);
    
                            // Reset phone number input
                            setShowPhoneNumberPopup(false);
                        } catch (error) {
                            // Handle errors
                            console.error('Error:', error);
                        }
                    },
                    (error) => {
                        console.error('Error getting user location:', error);
                    }
                );
            } else {
                console.error("Geolocation is not supported by this browser.");
            }
        }
    };
    // Event handler for phone number input change
    const handlePhoneChange = (event) => {
        setPhoneNumber(event.target.value);
    };

    const shouldShowFooter = () => {
        return selectedImage || selectedImages.length > 0; // Footer should appear if any image is selected or if any image is selected through "Select All" checkbox
    };
    // Function to send SMS
    const sendSMS = (phoneNumber, smsValues, latitude, longitude, userAddress) => {
        const formattedPhoneNumber = phoneNumber.startsWith('+1') ? phoneNumber : '+1' + phoneNumber.trim();
        // Concatenate all SMS messages into a single body
        const messageBody = smsValues;
        // Send the concatenated message as one SMS
        fetch('https://prod.loclshop.com/api/loclsms2', {
            mode:  'cors',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
                phoneNumber: formattedPhoneNumber, 
                adname: messageBody,
                address: userAddress, // Include user's address
                latitude: latitude, // Include latitude
                longitude: longitude // Include longitude
            }),
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error('Failed to send SMS');
            }
            console.log('SMS sent successfully');
            setSmsSent(true);
            setTimeout(() => {
                setSmsSent(false);
            }, 3000);
            // Clear the input field if the response is OK
        })
        .catch((error) => {
            console.error('Error sending SMS:', error);
        });
    };
    
    const handleImageClick = (imageItem) => {
        const isSelected = selectedImages.some(item => item.img_url === imageItem.img_url);
        if (!isSelected) {
            setSelectedImages(prevState => [...prevState, imageItem]);
        } else {
            setSelectedImages(prevState => prevState.filter(item => item.img_url !== imageItem.img_url));
        }
    };
    
    // Function to close the popup
    const closePopup = () => {
        setSelectedImage(null);
        setSmsSent(true); 
        setShowPhoneNumberPopup(false);
    };

    const renderImages = () => {
        let filteredImages = imagesData; // Initialize with all images
        if (showTenantAds && !selectedCategory) {
            // Filter images to display only those with type "tenant"
            filteredImages = imagesData.filter(image => image.type === "tenant");
        }
        else if (selectedCategory && showTenantAds){
            filteredImages = filteredImages.filter(image => image.category === selectedCategory);
        }
        else if (selectedCategory){
            filteredImages = filteredImages.filter(image => image.category === selectedCategory);
        }
        return filteredImages.map((imageItem, index) => (
            <div className="col-lg-4 col-md-6 col-sm-12" key={index}>
                <div className="deal" onClick={() => handleImageClick(imageItem)}>
                    <img 
                        src={imageItem.img_url} 
                        alt={imageItem.sms} 
                        style={{ 
                            borderRadius: '20px', 
                            paddingTop: '0px', 
                            borderColor: '#C6C5C5', 
                            boxShadow: '0 16px 24px rgba(0, 0, 0, 0.2)',
                            height: '470px', // Default height for smaller screens
                            width: '95%', // Ensure image fills its container
                        }} 
                    />
                </div>
            </div>
        )); 
    };

    const dropIn = {
        hidden: {
          y: "-100vh",
          opacity: 0,
        },
        visible: {
          y: "0",
          opacity: 1,
          transition: {
            duration: 1,
            type: "spring",
            damping: 20,
            stiffness: 500,
          },
        },
        exit: {
          y: "100vh",
          opacity: 0,
        },
      };

    return (
        <div>
        <div className="containerr">
            <div className="row">
                <div className="col-12">
                    <h1 className="text-center title" style={{ fontSize: '29px' }}>{isReturningUser ? 'Welcome back to For Eyes' : 'Welcome to For Eyes'}</h1>                     
                    </div>
                </div>
            </div>
            <div className="img-container">           
                <div className="row">
                    {renderImages()}
                </div>
            </div>
            {showPhoneNumberPopup && (
                <motion.div className="phone-popup">
                        <motion.div 
                        variants={dropIn}
                        initial="hidden"
                        animate="visible"
                        exit="exit"          
                        className="phone-popup-content">
                        <div className="col-12 phone-container d-flex justify-content-center">
                            <div className="row">
                                <div className="col-12 phonenumber">
                                    <div className="form-group text-center">
                                    <div className="phone-form">
                                        {showError && <p className="error-message">Please enter your phone number.</p>}
                                    </div>
                                    <h2>Unlock Exclusive Offers!</h2>
                                        <label className="info" htmlFor="phone">
                                        Select offers to receive exclusive SMS coupons instantly!
                                        <br></br><br></br>Join our fast-growing network of savvy shoppers for daily exclusive savings!<br></br>
                                        </label>
                                        <div className="phone-form">
                                            <input 
                                                type="text" 
                                                className="form-control phone-number-ip" 
                                                id="phoneinp" 
                                                name="phone" 
                                                value={phoneNumber} 
                                                onChange={handlePhoneChange} 
                                                placeholder="Phone Number" 
                                            />
                                            <button 
                                                type="submit" 
                                                className="submit-link" 
                                                onClick={savePhoneNumber && savePhoneNumber1} 
                                                style={{ 
                                                    width: '70%', 
                                                    backgroundColor: phoneNumber.length === 10 ? 'green' : '#007BFF' // Change button color to green if phone number length is 10
                                                }}
                                            >
                                                Start Saving Now!
                                            </button>
                                            <p>
                                                By entering your number, you consent to our 
                                                <span className="sec-terms-link" onClick={() => setOpenSecond(true)}>Terms</span> 
                                                and will receive SMS coupons.
                                            </p>
                                            <a href="/" className="closeModalLink">No thanks, I prefer to pay full price.</a>
                                        </div>                                      
                                    </div>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
            )}

{openSecond && (
                <motion.div className="second-popup">
                    <motion.div 
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{
                            duration: 0.3,
                            ease: [0, 0.71, 0.2, 1.01],
                            scale: {
                                type: "spring",
                                damping: 5,
                                stiffness: 100,
                                restDelta: 0.001
                            }
                        }}
                        className="second-popup-content" ref={newRef}>
                        <div className="second-container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group text-center">
                                    <label className="text-center info2" htmlFor="phone">
                                    <h4>Privacy Policy</h4>
                                        <ul>
                                            <li>
                                                <strong>Introduction</strong>
                                                <ul>
                                                    <li>This Privacy Policy governs the collection and use of personal information for ad promotions and coupon code distribution by [Your Company Name] ("the Company").</li>
                                                    <li>By providing your personal information for ad promotions and coupon code distribution, you agree to abide by this Privacy Policy.</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <strong>Collection of Personal Information</strong>
                                                <ul>
                                                    <li>The Company collects personal information, including phone numbers, for the purpose of sending ad promotions and coupon codes to users.</li>
                                                    <li>By providing your personal information, you consent to receive promotional messages and coupon codes from the Company.</li>
                                                    <li>Your personal information will be kept confidential and will not be shared with third parties without your consent.</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <strong>Use of Personal Information</strong>
                                                <ul>
                                                    <li>The Company may use the collected personal information to send ad promotions and coupon codes via SMS or other messaging platforms.</li>
                                                    <li>You may opt-out of receiving promotional messages and coupon codes at any time by contacting the Company or following the unsubscribe instructions provided in the messages.</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <strong>Coupon Code Distribution</strong>
                                                <ul>
                                                    <li>Coupon codes distributed by the Company are for promotional purposes only and have no cash value.</li>
                                                    <li>Coupon codes may have expiration dates and usage restrictions, which will be clearly communicated at the time of distribution.</li>
                                                    <li>The Company reserves the right to modify or cancel coupon codes at any time without prior notice.</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <strong>User Responsibilities</strong>
                                                <ul>
                                                    <li>You are responsible for providing accurate and up-to-date personal information to the Company.</li>
                                                    <li>You agree not to misuse or abuse the coupon codes distributed by the Company for personal gain or profit.</li>
                                                    <li>You agree to use the coupon codes in accordance with any terms and conditions provided by the Company.</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <strong>Limitation of Liability</strong>
                                                <ul>
                                                    <li>The Company shall not be liable for any damages or losses arising from the use or misuse of coupon codes distributed to users.</li>
                                                    <li>The Company shall not be responsible for any errors or delays in the delivery of promotional messages or coupon codes.</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <strong>Governing Law</strong>
                                                <ul>
                                                    <li>This Privacy Policy shall be governed by and construed in accordance with the laws of [Your Country/State].</li>
                                                    <li>Any disputes arising out of or relating to this Privacy Policy shall be subject to the exclusive jurisdiction of the courts in [Your Jurisdiction].</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <strong>Changes to Privacy Policy</strong>
                                                <ul>
                                                    <li>The Company reserves the right to update or modify this Privacy Policy at any time without prior notice.</li>
                                                    <li>Users will be notified of any changes to the Privacy Policy via the contact information provided.</li>
                                                </ul>
                                            </li>
                                        </ul>
                                        <button className="btn btn-primary"onClick={() => setOpenSecond(false)}>Close</button>
                                    </label> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
            )}
        {smsSent && (
                <div className="sms-popup">
                    <div className="sms-popup-content">
                        <h2 className="text-center">Coupon Code sent in SMS Successfully</h2>                        
                    </div>
                </div>
            )}
        {isReturningUser && (
            <SFooter/>
        )}
        </div>
    );
};
