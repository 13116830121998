    import React, { useEffect, useState } from 'react';
    import styled, { keyframes } from 'styled-components';
    import './day_deal.css'

    import { useNavigate } from 'react-router-dom';


    const deal_text = 'Enter Cell Phone Number to Receive a Text Message with Your PROMO CODE to Unlock Savings';
    const return_deal = "Welcome back! We've recognized your phone number from our records, and your promo code will be sent automatically to you. Check your messages for the savings. If you have any issues or didn't receive the code, please contact our support team."

    // Define keyframe animation
    const blinkBorder = keyframes`
        0% {
            border-color: transparent;
        }
        50% {
            border-color: #000000;
        }
        100% {
            border-color: transparent;
        }
    `;

    // Styled components
    const Container = styled.div`
        margin: 20px;
    `;

    const Header = styled.div`
        text-align: center;
        margin-bottom: 20px;
        color: #AD8C8C;
        font-family: 'Righteous', sans-serif;
    `;

    const ImageContainer = styled.div`
        text-align: center;
        margin-bottom: 20px;

        @media screen and (min-width: 768px) {
            width: 50%;
            margin-left: 50%;
        }
    `;

    const ImageContainer2 = styled.div`
        text-align: center;
        margin-bottom: 20px;

        @media screen and (min-width: 768px) {
            width: 70%;
            margin-left: 20%;
        }
    `;

    const ImageContainer3 = styled.div`
        text-align: center;
        margin-bottom: 20px;

        @media screen and (min-width: 768px) {
            width: 50%;
            margin-right: 80%;
        }
    `;

    const Image = styled.img`
        border-radius: 0%;
        width: 360px;
        height: 360px;
        margin-bottom: 10px;
    `;

    const Input = styled.input`
        width: 45%;
        padding: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
        box-sizing: border-box;
        border-radius: 5px;
        padding-bottom: 10px;
        text-align: center;
        animation: ${blinkBorder} 0.5s linear infinite;
        border-width: 5px;
    `;

    const CustomImage = styled.img`
        width: 300px;
        height: 300px;
        /* Add any additional styles for the custom image here */
    `;


    const Button = styled.button`
        padding: 15px 20px;
        background-color: #AD8C8C;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;

        &:hover {
            background-color: #874242;
        }
    `;

    const SmallText = styled.p`
        font-size: 14px;
        text-align: justify;

        ol {
            padding-left: 20px;
        }
    `;

    const Para = styled.p`
        font-family: 'Source Sans Pro', Arial, sans-serif;
        font-weight: bold;
        font-size: 23px;
    `;

    const FooterLink = styled.a`
        color: blue;
    `;

    const DayDeal = () => {
        const [phoneNumber, setPhoneNumber] = useState('');
        const [smsSent, setSmsSent] = useState(false); 
        const [isReturningUser, setIsReturningUser] = useState(false);
        const [userLocation, setUserLocation] = useState(null);
        const [userAddress, setUserAddress] = useState(null); // New state for user address
        const [error, setError] = useState(false);
        const [selectedCountry, setSelectedCountry] = useState('US');

        const smsUrl = 'https://prod.loclshop.com/api/loclsms';
        const defUrl = 'https://prod.loclshop.com/'

        useEffect(() => {
            const storedPhoneNumber = localStorage.getItem('phoneNumber');
            if (storedPhoneNumber) {
                setPhoneNumber(storedPhoneNumber);
                hideInputAndButton();
                setIsReturningUser(true);
            }
            if ("geolocation" in navigator) {
                // Get user's current location
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const { latitude, longitude } = position.coords;
                        setUserLocation({ latitude, longitude });
                        reverseGeocode(latitude, longitude);
                    },
                    (error) => {
                        console.error('Error getting user location:', error);
                    }
                );
            } else {
                console.error("Geolocation is not supported by this browser.");
            }
        }, []);

        const reverseGeocode = (latitude, longitude) => {
            const accessToken = 'sk.eyJ1IjoiamVldmFuLWwtc29mdHJvbmljbGFicyIsImEiOiJjbHI3ZW0zYzMyZjlvMnJvMWJjZ3BhZ2IyIn0.CBJY-lsvhRaaagQPeCs8cw';
            const style = 'mapbox://styles/jeevan-l-softroniclabs/clm5u8tjs00xe01qy2itf6w9l';
            const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${accessToken}&style=${style}`;

            // Use Mapbox Geocoding API to reverse geocode the coordinates
            fetch(url)
                .then(response => response.json())
                .then(data => {
                    // Extract the location information from the response
                    const place = data.features[0].place_name;
                    setUserAddress(place); // Set user's address
                    console.log("User is currently at:", place);
                })
                .catch(error => {
                    console.error('Error reverse geocoding:', error);
                });
        };

        const hideInputAndButton = () => {
            const inputField = document.getElementById('phoneInput');
            const submitButton = document.querySelector('button[type="submit"]');
            if (inputField && submitButton) {
                inputField.style.display = 'none';
                submitButton.style.display = 'none';
            }
        }

        const postData = () => {
            const countryCodes = {
                US: '+1',
                IN: '+91',
                UK: '+44',
                JP: '+81'  // Assuming you want to use the same prefix as the US for Canada
            };
        
            let formattedPhoneNumber = '';
        
            if (!isReturningUser) {
                formattedPhoneNumber = phoneNumber.startsWith(countryCodes[selectedCountry]) ? phoneNumber : countryCodes[selectedCountry] + (phoneNumber ? phoneNumber.trim() : '');
                localStorage.setItem('phoneNumber', formattedPhoneNumber);
            } else {
                
                formattedPhoneNumber =  phoneNumber;
            }
        
            console.log("this is formattedPhoneNumber---", formattedPhoneNumber);
        
            setPhoneNumber(formattedPhoneNumber);
        
            sendSMS(formattedPhoneNumber, userLocation.latitude, userLocation.longitude);
        }
        
        
        const navigate = useNavigate();

        const sendSMS = (formattedPhoneNumber, latitude, longitude) => {
            if (!formattedPhoneNumber) {
                console.log("Phone number is null. SMS not sent.");
                return; // Exit the function if phone number is null
            }
        
            setTimeout(() => {
                console.log("---------------this is after send sms formattedPhoneNumber", formattedPhoneNumber);
                fetch(smsUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        phoneNumber: formattedPhoneNumber,
                        address: userAddress, // Include user's address
                        latitude: latitude, // Include latitude
                        longitude: longitude // Include longitude
                    })
                })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Failed to send SMS. Please check the number and try again.');
                    }
                    return response.json();
                })
                .then(data => {
                    console.log(data);
                    setSmsSent(true);
                    setTimeout(() => {
                        navigate('/DealOfTheDay'); // Redirect after SMS is sent successfully
                    }, 2000); // Adjust time as needed
                })
                .catch(error => {
                    setError(true); // Set error after a delay
                        console.error('Error:', error);
                        localStorage.removeItem('phoneNumber');
                    setTimeout(() => {
                        setError(false); // Set error after a delay
                    }, 2000); // Delay error handling by 2000 milliseconds (2 seconds)
                });
                
            }, 1000);
        }
        
        const handleCountryChange = (event) => {
            setSelectedCountry(event.target.value);
        };
        
        

        return (
            <Container>
                <Header>
                <h1>{isReturningUser ? 'Welcome back to LOCLshop' : 'Welcome to LOCLshop'}</h1>
                </Header>
                <div className="row justify-content-around">
                    <div className="col-md-4">
                        <ImageContainer>
                            <a href="https://sovrn.co/gxt0118" target="_blank" rel="noreferrer">
                                <CustomImage src="https://images.footlocker.com/content/dam/final/footlocker/site/homepage/2024/february/240219-fl-up-to-25-sale-m-asp.jpg" alt="Image 1" className="custom-image"/>
                            </a>
                            <div className="image-description">
                            <br/>
                            <br/>
                            <br/>
                            <Para>Deals of the Day</Para>
                            <SmallText>{isReturningUser ? return_deal : deal_text}</SmallText>
                            {!isReturningUser && (
                                <>  
                                <select 
                                    id="countrySelect" 
                                    name="country"
                                    onChange={handleCountryChange}
                                    value={selectedCountry}
                                >
                                    <option value="US">🇺🇸 USA</option>
                                    <option value="IN">🇮🇳 IN</option>
                                    <option value="UK">🇬🇧 UK</option>
                                    <option value="JP">🇯🇵 Japan</option>
                                </select>
                                    <Input type="tel" placeholder="Phone No" id="phoneInput" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                                    <br />                                
                                </>
                            )}
                            <Button 
                                type="button" 
                                onClick={postData}
                            >
                                {isReturningUser 
                                    ? 'Get My Deals of the Day' 
                                    :
                                      'Collect your Deals of the Day'
                                        }
                            </Button>

                        </div>
                        </ImageContainer>
                    </div>
                    <div className="col-md-4">
                        <ImageContainer2>
                        <Image src="https://prod.loclshop.com/api/uploads/1711536909211-788117812-Your-Apps-Graphic-for-LOCLshop-site-02202024-1.png" alt="Image 2" />
                            <div className="image-description">
                                <Para>Your Favorite Store Promotions, Apps & Saved Coupons</Para>
                                <SmallText>In addition to the Deals of the Day, choose any of the Apps, Clubs, Loyalty Programs and Coupons you've saved to your LOCLshop Account.
                                    <ol>
                                        <li><FooterLink href="https://www.target.com/circle" target="_blank" rel="noreferrer">https://www.target.com/circle</FooterLink></li>
                                        <li><FooterLink href="https://m.marshalls.com/us/m/jump/topic/Digital-Rewards-Certificates/20500003p" target="_blank" rel="noreferrer">https://m.marshalls.com/us/m/jump/topic/Digital-Rewards-Certificates/20500003p</FooterLink></li>
                                        <li><FooterLink href="http://tinyurl.com/macysstarrewards" target="_blank" rel="noreferrer">http://tinyurl.com/macysstarrewards</FooterLink></li>
                                        <li><FooterLink href="https://www.albertsons.com/foru/program-details.html" target="_blank" rel="noreferrer">https://www.albertsons.com/foru/program-details.html</FooterLink></li>
                                        <li><FooterLink href="https://clubpublix.com/" target="_blank" rel="noreferrer">https://clubpublix.com/</FooterLink></li>
                                    </ol>
                                </SmallText>
                            </div>
                        </ImageContainer2>
                    </div>
                    <div className="col-md-4">
                        <ImageContainer3>
                            <Image src="https://prod.loclshop.com/api/uploads/1711536957206-874078944-LOCLshop-Mission-Graphic-02202024-1.png" alt="Image 3" />
                            <div className="image-description">
                                <Para>Our Mission to Support Local Stores</Para>
                                <SmallText>We believe that connecting shoppers with local retailers improves the community.</SmallText>
                            </div>
                        </ImageContainer3>
                    </div>
                    {smsSent && (
                <div className="sms-popup">
                    <div className="sms-popup-content">
                        <h2 className="text-center">Coupon Code sent in SMS Successfully</h2>
                    </div>
                </div>
            )}
            {error && (
                <div className="sms-popup-error">
                    <div className="sms-popup-content-error">
                        <h2 className="text-center">Enter Your Correct Phone Number</h2>
                    </div>
                </div>
            )}
                </div>
            </Container>
        );
    }

    export default DayDeal;
