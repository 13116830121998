import React, { useEffect, useState } from 'react';
import Homecoupons from '../components/home_coupons';
import PGASMS from '../pages/pga-sms';

function Forwordpage() {
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  useEffect(() => {
    const fetchLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
          },
          error => {
            console.error('Error getting geolocation:', error);
          }
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
      }
    };

    fetchLocation();

    return () => {
      // Cleanup function
    };
  }, []);

  // Function to calculate distance between two coordinates using Haversine formula
  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the earth in km
    const dLat = (lat2 - lat1) * (Math.PI / 180); // deg2rad below
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
      0.5 -
      Math.cos(dLat) / 2 +
      (Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        (1 - Math.cos(dLon))) /
        2;

    return R * 2 * Math.asin(Math.sqrt(a)) * 1000; // Distance in meters
  };

  const targetLatitude = 27.814631;
  const targetLongitude = -82.638437;

  // Check if the distance between user's location and target coordinates is within 500 meters
  const isWithinRange =
    latitude &&
    longitude &&
    calculateDistance(latitude, longitude, targetLatitude, targetLongitude) <=
      500;

  return (
    <div>
      {isWithinRange ? (
        <PGASMS />
      ) : (
        <Homecoupons />
      )}
    </div>
  );
}

export default Forwordpage;
