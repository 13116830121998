import React, { useState } from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import { FaBars, FaUserCircle } from "react-icons/fa";
import { BiLogOut } from "react-icons/bi";
import { motion, AnimatePresence } from 'framer-motion';
import { IoChevronBackCircleSharp } from "react-icons/io5";

import './sidebar.css';

const emailId = localStorage.getItem('emailid');

const SideBar = ({ children, onDashboardToggle }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };

  const handleRouteClick = (route) => {
    if (route.name === "Dashboard") {
      onDashboardToggle();
    }
    setIsOpen(false);
  };

  const handleLogout = async () => {
    localStorage.removeItem('User_authenticated');
    localStorage.removeItem('dashboardid');
    localStorage.removeItem('token');
    localStorage.removeItem("repaadashboardid");
    localStorage.removeItem("repaatoken");
    localStorage.removeItem("samplerepaadashboardid");
    localStorage.removeItem("samplerepaatoken");
    localStorage.removeItem("sampledashboardid");
    localStorage.removeItem("sampledashboardtoken");
    localStorage.removeItem("loginCount");

    if (emailId) {
      try {
        await fetch('https://prod.loclshop.com/api/api/logoutactivity', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email: emailId }), // Send emailId to your backend
        });
      } catch (error) {
        console.error('Error logging out:', error);
      }
    }


    localStorage.removeItem("emailid");
    navigate('/agency-login');
  };

  // const routes = [
  //   ...(localStorage.getItem('dashboardid') === 1 && localStorage.getItem('token') === 1 ? [{
  //     path: "/branddashboard",
  //     name: "Dashboard",
  //     icon: <FaHome />
  //   }] : []),
  //   ...(localStorage.getItem('repaadashboardid') === 1 && localStorage.getItem('repaatoken') === 1 ? [{
  //     path: "/brandrepaa",
  //     name: "Report",
  //     icon: <FaTable />
  //   }] : []),
  //   ...(localStorage.getItem('samplerepaadashboardid') === 1 && localStorage.getItem('samplerepaatoken') === 1 ? [{
  //     path: "/samplerepaa",
  //     name: "Standard Repaa",
  //     icon: <FaTable />
  //   }] : []),
  //   ...(localStorage.getItem('sampledashboardid') === 1 && localStorage.getItem('sampledashboardtoken') === 1 ? [{
  //     path: "/sampledashboard",
  //     name: "Standard Dashboard",
  //     icon: <FaTable />
  //   }] : [])
  // ];

  const routes = [
    {
      name: emailId ? emailId : "E-Mail",
      icon: <FaUserCircle />,
    },
    {
      name: "Return",
      icon: <IoChevronBackCircleSharp />,
      path: '/useroptions'
    }

  ];

  return (
    <div className="main-container">
      <motion.div
        animate={{
          width: isOpen ? "200px" : "75px",
          transition: {
            duration: 0.5,
            type: "spring",
            damping: 5,
          },
        }}
        className="sidebar"
      >
        <div className="top_section">
          <AnimatePresence>
            {isOpen && (
              <motion.h2
                variants={showAnimation}
                initial="hidden"
                animate="show"
                exit="hidden"
                className="logo"
              >
                RePAA Report
              </motion.h2>
            )}
          </AnimatePresence>
          <div className="bars" onClick={toggle}>
            <FaBars />
          </div>
        </div>
        <section className="routes">
          {routes.map((route, index) => (
            <NavLink
              to={route.path}
              key={index}
              className="link"
              activeClassName="active"
              onClick={() => handleRouteClick(route)}
            >
              <div className="icon">{route.icon}</div>
              <AnimatePresence>
                {isOpen && (
                  <motion.div
                    variants={showAnimation}
                    initial="hidden"
                    animate="show"
                    exit="hidden"
                    className="link_text"
                  >
                    {route.name}
                  </motion.div>
                )}
              </AnimatePresence>
            </NavLink>
          ))}
          <div className="link" onClick={handleLogout}>
            <div className="icon"><BiLogOut /></div>
            {isOpen && <div className="link_text">Logout</div>}
          </div>
        </section>
      </motion.div>
      <main style={{ width: isOpen ? '90vw' : '95vw' }}>
        {children}
      </main>
    </div>
  );
};

export default SideBar;