import React from 'react';
import UserForm from './UserForm';
import SideBar from './SideBar';

const UserFormComp = () => {
    return (
        <div>
            <SideBar>
                <UserForm />
            </SideBar>
        </div>
    );
};

export default UserFormComp;