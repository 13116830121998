import React from 'react';
import SideBar from './SideBar';
import EnhancedTable from './tables';
import Footer from './sticky_footer'
const TableComp = () => {
    return (
        <div>
            <SideBar>
                <EnhancedTable />
            </SideBar>
            <Footer />
        </div>
    );
};

export default TableComp;
