import React, { useState, useEffect, useMemo } from 'react';
import inf3 from '../assets/images/inf3.png';
import Footer from '../components/sticky_footer';
import postData from './blog.json'; // Make sure to provide the correct path
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { FaWhatsapp, FaFacebookF, FaLinkedinIn, FaInstagram} from 'react-icons/fa';
import { BsTwitterX } from "react-icons/bs";

const SingleBlog = () => {
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [categories, setCategories] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [numColumns, setNumColumns] = useState(3); // Default to 3 columns
  const [isCategorySelected, setIsCategorySelected] = useState(false);
  const navigate = useNavigate(); // Initialize the navigate function
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const category = queryParams.get('category');
  const title = queryParams.get('title');
  const [selectedPost, setSelectedPost] = useState(null); // State to hold the selected post
  const shareURL = window.location.href;
  
  useEffect(() => {
    // Extract categories from the postData
    const loadedCategories = [...new Set(postData.AllPosts.map(category => category.Category))];
    
    setCategories(['All', ...loadedCategories]);

    // Adjust number of columns based on screen size
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setNumColumns(1); // Display one post per row
      } else if (window.innerWidth < 992) {
        setNumColumns(2); // Display two posts per row
      } else {
        setNumColumns(3); // Display three posts per row on larger screens
      }
    };

    // Initial call to set the initial number of columns
    handleResize();

    // Event listener to handle window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

    // Check if the current post matches the category and title from the URL params
    useEffect(() => {
      const matchingCategory = postData.AllPosts.find(postCategory => postCategory.Category === category);
      if (matchingCategory) {
        const matchingPost = matchingCategory.posts.find(post => post.title === title);
        if (matchingPost) {
          setSelectedPost(matchingPost);
        }
      }
    }, [category, title]);


    const handleCategoryClick = (category) => {
      setSelectedCategory(category);
      setCurrentIndex(0);
      setIsCategorySelected(true);
      navigate(`/blog?category=${encodeURIComponent(category)}`); // Navigate to /blog with category parameter
    };

    const socialIconLinkStyle = {
      marginRight: '10px',
      fontSize: '30px',
      color: '#000',
    };
    const socialIconStyle = {
      marginRight: '10px',
      fontSize: '30px',
      transition: 'color 0.3s ease-in-out',
    };
  
    const socialIconsStyle = {
      marginTop: '20px',
    };
    
  return (
    <div>
    <style>
      {`
        .hover-underline:hover {
          text-decoration: underline;
        }
        .blog-link {
          color: blue;
          text-decoration: underline;
        }
        .share-icons {
          display: flex;
          justify-content: center;
          margin-top: 20px;
        }
        .share-icons a {
          margin: 0 10px;
        }
      `}
    </style>
    <div className="position-relative" style={{
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${inf3})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundAttachment: 'fixed',
      minHeight: '150vh',
    }}>
      {/* Overlay Content */}
      <div className="text-center py-5">
        <h2 className="mb-4 text-white">SUPPORT LOCAL STORIES</h2>

        {/* Category Filter Buttons */}
        <div className="justify-content-start mb-3" style={{ alignItems: 'flex-start' }}>
          {categories.map((category) => (
            <button
              key={category}
              className={`btn btn-light me-2 ${selectedCategory === category ? 'active' : ''}`}
              onClick={() => handleCategoryClick(category)}
              aria-label={`Filter posts by ${category}`}
              style={{ marginBottom: '10px' }} // Add a bottom margin to each button
            >
              {category}
            </button>
          ))}
        </div>

        <div>
          <div style={{ backgroundColor: 'white' }}>
            <p style={{ textAlign: 'left', padding: '3%' }}>
              <Link to="/blog" style={{ textDecoration: 'none', color: '#AE8F78' }}> Blog</Link> / {category}
            </p>
            <h1 style={{ color: 'black', textAlign: 'center' }}>{title}</h1> 
            {selectedPost && (
              <>
                <h3 style={{ fontSize: '20px', color: '#AE8F78' ,textAlign: 'center' }}>{selectedPost.date} | Author : Michael</h3>

                <div style={{ padding: '1% 19%', textAlign: 'left' }}>
                  {/* Check if selectedPost.paragraph is defined before splitting */}
                  {selectedPost.paragraph && selectedPost.paragraph.split('\n').map((paragraphPart, index) => {
                    // Detect URLs within the paragraph text and convert them into clickable links
                    const urlRegex = /(https?:\/\/[^\s]+)/g;
                    const parts = paragraphPart.split(urlRegex);
                    return parts.map((part, index) => {
                      if (part.match(urlRegex)) {
                        return <a key={index} href={part} className="blog-link" target="_blank" rel="noopener noreferrer">{part}</a>;
                      } else {
                        // Check if the part contains a number followed by a dot
                        const numberDotRegex = /\b\d+\.\b/;
                        if (part.match(numberDotRegex)) {
                          // Split the part into sections based on the number followed by a dot
                          const sections = part.split(numberDotRegex);
                          return sections.map((section, index) => {
                            if (index % 2 === 0) {
                              return <span key={index}>{section}</span>;
                            } else {
                              // Apply bold and color style to the section until encountering ":"
                              const colonIndex = section.indexOf(': ');
                              const boldText = colonIndex !== -1 ? section.substring(0, colonIndex + 1) : section;
                              const normalText = colonIndex !== -1 ? section.substring(colonIndex + 2) : '';
                              return (
                                <span key= {index} >
                                  <span style={{ fontWeight: 'bold', fontSize: '20px', color: '#AE8F78' }}> {boldText} </span>
                                  {normalText}
                                </span>
                              );
                            }
                          });
                        } else {
                          return <p key={index}>{part}</p>;
                        }
                      }
                    });
                  })}
                </div>


                <div style={socialIconsStyle}>
                  <h3>Share this post:</h3>
                  <a href={`https://wa.me/?text=${shareURL}`} target="_blank" rel="noopener noreferrer" style={socialIconLinkStyle}>
                      <FaWhatsapp style={socialIconStyle} />
                  </a>
                  <a href={`https://www.facebook.com/sharer/sharer.php?u=${shareURL}`} target="_blank" rel="noopener noreferrer" style={socialIconLinkStyle}>
                      <FaFacebookF style={socialIconStyle} />
                  </a>
                  <a href={`https://www.linkedin.com/shareArticle?url=${shareURL}`} target="_blank" rel="noopener noreferrer" style={socialIconLinkStyle}>
                      <FaLinkedinIn style={socialIconStyle} />
                  </a>
                  <a href={`https://twitter.com/intent/tweet?url=${shareURL}`} target="_blank" rel="noopener noreferrer" style={socialIconLinkStyle}>
                        <BsTwitterX style={socialIconStyle} />
                  </a>

                  <a href={`https://www.instagram.com/share?url=${shareURL}`} target="_blank" rel="noopener noreferrer" style={socialIconLinkStyle}>
                      <FaInstagram style={socialIconStyle} />
                  </a>
              </div>
              </>
            )}
          </div>
        </div>

      </div>
      {/* Sticky Footer */}
      <div className="position-absolute bottom-0 w-100">
        {/* <Footer /> */}
      </div>
    </div>
  </div>
  );
};

export default SingleBlog;
