import React from 'react';
import ModifyUser from './modify_users';
import SideBar from './SideBar';


const ModifyUserComp = () => {
    return (
        <div>
            <SideBar>
                <ModifyUser />
            </SideBar>
        </div>
    );
};

export default ModifyUserComp;