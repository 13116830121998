// geocoding.js

export default function reverseGeocode (latitude, longitude) {
    const accessToken = 'sk.eyJ1IjoiamVldmFuLWwtc29mdHJvbmljbGFicyIsImEiOiJjbHI3ZW0zYzMyZjlvMnJvMWJjZ3BhZ2IyIn0.CBJY-lsvhRaaagQPeCs8cw';
    const style = 'mapbox://styles/jeevan-l-softroniclabs/clm5u8tjs00xe01qy2itf6w9l';
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${accessToken}&style=${style}`;

    // Use Mapbox Geocoding API to reverse geocode the coordinates
    return fetch(url)
        .then(response => response.json())
        .then(data => {
            // Extract the location information from the response
            const place = data.features[0].place_name;
            return place; // Return the address
        })
        .catch(error => {
            console.error('Error reverse geocoding:', error);
            throw error; // Propagate the error
        });
};

