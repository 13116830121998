import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

// Styled components for UI
const Container = styled.div`
  margin: 5rem auto;
  width: 90%;
  overflow-x: auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  th, td {
    padding: 12px 15px;
    border-bottom: 1px solid #ddd;
  }

  th {
    background-color: #f8f9fa;
  }

  tr:hover {
    background-color: #f1f1f1;
  }
`;

const Button = styled.button`
  margin-right: 5px; /* Adds space between buttons */
  padding: 5px 15px;
  font-size: 16px;
  cursor: pointer;
  background-color: ${props => props.color || '#007bff'};
  color: white;
  border: none;
  border-radius: 5px;

  &:hover {
    background-color: ${props => props.hoverColor || '#0056b3'};
  }
`;

const ConfirmationModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: ${props => props.isOpen ? 'flex' : 'none'};
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;

  & > div {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

function ManageUsers() {
  const [users, setUsers] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch('https://prod.loclshop.com/api/regusers');
        const data = await response.json();
        setUsers(data);
      } catch (error) {
        setError('Failed to fetch users');
        console.error('Failed to fetch users:', error);
      }
    };
    fetchUsers();
  }, []);

  const handleDeleteUser = async () => {
    try {
      const response = await fetch(`https://prod.loclshop.com/api/regusers/${userIdToDelete}`, { method: 'DELETE' });
      if (!response.ok) {
        throw new Error('Failed to delete the user');
      }
      setUsers(users.filter(user => user._id !== userIdToDelete));
      setModalOpen(false);
    } catch (error) {
      console.error('Failed to delete the user:', error);
    }
  };

  const openModal = (userId) => {
    setUserIdToDelete(userId);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleModifyUser = (userId) => {
    navigate(`/modifyuser?id=${userId}`);
  };

  const handleCreateUser = () => {
    navigate('/userform');
  };

  return (
    <Container>
      <Header>
        <h2>Manage Users</h2>
        <Button onClick={handleCreateUser} color="#28a745">+ Create User</Button>
      </Header>
      {error && <p>{error}</p>}
      <Table>
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Username</th>
            <th>Email</th>
            <th>Brand Name</th>
            <th>Location</th>
            <th>Privileges</th>
            <th>User Status</th>
            <th>Frequency</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user._id}>
              <td>{user.firstName}</td>
              <td>{user.lastName}</td>
              <td>{user.userName}</td>
              <td>{user.emailId}</td>
              <td>{user.brandName}</td>
              <td>{user.location}</td>
              <td>
                {user.privileges.dashboard && user.privileges.report && user.privileges.sampleD && user.privileges.sampleR ? 'Dashboard , Report , Sample RePAA & Sample Dashboard' : (
                  <>
                    {user.privileges.dashboard && 'Dashboard, '}
                    {user.privileges.report && 'Report, '}
                    {user.privileges.sampleD && ' Sample Dashboard,'}
                    {user.privileges.sampleR && ' Sample RePAA,'}
                  </>
                )}
              </td>
                <td>
                  {user.status ? (
                    user.status.active ? 'Active' : 'Deactive'
                  ) : 'No Status'}
                </td>


              <td>
                {user.frequency.daily && user.frequency.weekly && user.frequency.monthly ? 'Daily & Weekly & Monthly' : (
                  <>
                    {user.frequency.daily && user.frequency.weekly && 'Daily & Weekly '}
                    {user.frequency.daily && user.frequency.monthly && 'Daily & Monthly '}
                    {user.frequency.weekly && user.frequency.monthly && 'Weekly & Monthly '}
                    {user.frequency.daily && !user.frequency.weekly && !user.frequency.monthly && 'Daily '}
                    {!user.frequency.daily && user.frequency.weekly && !user.frequency.monthly && 'Weekly '}
                    {!user.frequency.daily && !user.frequency.weekly && user.frequency.monthly && 'Monthly '}
                  </>
                )}
              </td>


              <td>
                <Button color="#007bff" onClick={() => handleModifyUser(user._id)}>Modify</Button>
                <Button color="#dc3545" onClick={() => openModal(user._id)}>Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {isModalOpen && (
        <ConfirmationModal isOpen={isModalOpen}>
          <div>
            <p>Are you sure you want to delete this user?</p>
            <div>
              <Button color="#28a745" onClick={handleDeleteUser}>Yes</Button>
              <Button color="#6c757d" onClick={closeModal}>No</Button>
            </div>
          </div>
        </ConfirmationModal>
      )}
    </Container>
  );
}

export default ManageUsers;