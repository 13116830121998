import React, { useEffect, useRef } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import styled from 'styled-components';
// import Footer from '../components/sticky_footer';
import Location_icon from '../assets/images/location_icon.jpeg'

const Header = styled.header`
  color: #000000;
  text-align: center;
  padding: 1em 0;
`;

const Main = styled.main`
  padding: 20px;
`;

const ExclusiveOffersAlert = styled.div`
  background-color: #ffcc00;
  color: #000;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 20px;
`;

const MapContainer = styled.section`
  height: 800px;
  width: 100%;
  border-radius: 4px;

  @media (max-width: 768px) {
    height: 600px;
  }
`;

const Button = styled.button`
  background-color: #0072CE;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;

  &:hover {
    background-color: #0056b3;
  }
`;


const EmptyLocation = () => {
  const mapRef = useRef(null);

  const customIcon = new L.Icon({
    iconUrl : Location_icon,
    iconSize : [35,35], // size of the icon
    iconAnchor : [22,94], // point of the icon which will correspond to marker's location
    popupAnchor : [-3, -76] // point from which the popup should open relative to the iconAnchor

  })

  // let nearbyLocations = [];  // Array to store nearby latitudes and longitudes

  const getDistance = (lat1, lon1, lat2, lon2) => {
      // Calculate distance between two points using Haversine formula
      const R = 6371; // Radius of the Earth in km
      const dLat = (lat2 - lat1) * Math.PI / 180;
      const dLon = (lon2 - lon1) * Math.PI / 180;
      const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
                Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
                Math.sin(dLon/2) * Math.sin(dLon/2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
      const distance = R * c; // Distance in km
      return distance;
  };
  
  const fetchData = async () => {
    try {
        const response = await fetch('https://prod.loclshop.com/api/query-data');
        const data = await response.json();

        if (data && data.brands) {
            return new Promise((resolve, reject) => {
                // Get user's current location
                if ("geolocation" in navigator) {
                    navigator.geolocation.getCurrentPosition(position => {
                        const userLat = position.coords.latitude;
                        const userLong = position.coords.longitude;

                        // Iterate through brands to find a match within 10 km radius
                        const nearbyBrands = data.brands.filter(brand => getDistance(userLat, userLong, brand.lat, brand.long) <= 10 );

                        // Store nearby latitudes and longitudes
                        const nearbyLocations = nearbyBrands.map(brand => ({ lat: brand.lat, long: brand.long, brandname:brand.BrandName }));

                        // Log the nearby locations
                        console.log("nearbyLocations", nearbyLocations);  

                        resolve(nearbyLocations);  // Resolve the promise with nearbyLocations
                    });
                } else {
                    reject(new Error('Geolocation is not supported.'));
                }
            });
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error; // Throw the error to be caught by the caller
    }
};




  useEffect(() => {
    
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(async position => {
            const userLat = position.coords.latitude;
            const userLong = position.coords.longitude;
            if (!mapRef.current) {
              const map = L.map('mapContainer').setView([userLat, userLong], 13);
              L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                maxZoom: 22,
              }).addTo(map);
              fetchData()
              .then(nearbyLocations => {
                  console.log("Returned nearbyLocations:", nearbyLocations);
          
                  // Create markers for each nearby location and add to map
                  nearbyLocations.forEach(location => {
                      const marker = L.marker([location.lat, location.long], { icon: customIcon }).addTo(map);
                      marker.bindPopup(`<b>your near by ${location.brandname} shop</b>`).openPopup();
                  });

                  // Create a marker for the user's current location
                  const userMarker = L.marker([userLat, userLong], { icon: customIcon }).addTo(map);
                  userMarker.bindPopup("<b>Your Current Location!").openPopup();
          
              })
              .catch(error => {
                  console.error("Error:", error);
              });
        
              mapRef.current = map;
            }
        });
    } else {
        console.error('Geolocation is not supported.');
    }

    return () => {
      // Clean up the map instance when the component is unmounted
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, []);

  const scrollToMap = () => {
    document.getElementById('mapContainer').scrollIntoView({
      behavior: 'smooth'
    });
  };

  const headerStyle = {
    fontFamily: 'Righteous, sans-serif', // Specify the "Righteous" font and fallback to sans-serif
    fontWeight: 'normal', // Optional: Adjust the font weight if needed
    color: '#5f7c59',
    marginBottom: '20px',
  };

  return (
    <div>
      <Header>
        <h1 style={headerStyle}>Welcome to LOCLshop</h1>
      </Header>
      <Main>
        <ExclusiveOffersAlert>
          <h2>Exclusive Offers Await Near You!</h2>
          <p>Step into the proximity of our partner malls and tenants to unlock special deals and discounts, exclusively for you.</p>
          <p>Found a nearby offer? Visit <a href="https://loclshop.com/coupons">loclshop.com/coupons</a> to claim your exclusive mall or tenant discounts!</p>
          <Button onClick={scrollToMap}>Show Me Where <span role="img" aria-label="pin">📍</span></Button>
        </ExclusiveOffersAlert>
        <MapContainer id="mapContainer"></MapContainer>
      </Main>
    </div>
  );
};

export default EmptyLocation;
