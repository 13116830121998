import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import './landing_page.css'; // Import CSS file for styling
import { motion } from 'framer-motion';
import reverseGeocode from '../components/geoencoding'
import axios from 'axios';
import Home_coupons from '../components/home_coupons';


// Define styled components for styling
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title1 = styled.h3`
  margin-bottom: 10px;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  justify-items: center;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;

  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
    justify-items: center;
    align-items: center;
    margin-top: -9%;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
`;

const ItemContainer = styled.div`
  text-align: center;
`;

const Title = styled.h3`
  margin-bottom: 10px;
`;

const ImageLink = styled.a`
  display: block;
  width: 100%;

  img {
    width: 100%;
    height: auto;
    border: 2px solid #000;
    border-radius: 0px;
  }
`;

function MyComponent() {
  const [userLocation, setUserLocation] = useState(null);
  const [storeData, setStoreData] = useState(null);
  const [showPhoneNumberPopup, setShowPhoneNumberPopup] = useState(false);
  const [openSecond, setOpenSecond] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [showPopupOnce, setShowPopupOnce] = useState(true); // Flag to control the popup visibility
  const newRef = useRef(null);
  const smsnewRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showError, setShowError] = useState(false);
  const [userAddress, setUserAddress] = useState(null); // New state for user address
  const [isReturningUser, setIsReturningUser] = useState(false);

  
  const handleOutsideClick = (e) => {
    const storedPhoneNumber = localStorage.getItem('phoneNumber');
    if (newRef.current && !newRef.current.contains(e.target)) {
      setSelectedImage(null);
      setOpenSecond(false);
    }
    if (storedPhoneNumber && smsnewRef.current && !smsnewRef.current.contains(e.target)) {
      setSelectedImage(null);
    }
  };

  useEffect(() => {
    checkReturningUser();
    const storedPhoneNumber = localStorage.getItem('phoneNumber');
    if (storedPhoneNumber) {
      setShowPhoneNumberPopup(false);
    } else {
      setShowPhoneNumberPopup(true);
    }

    const getUserLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            setUserLocation({
              lat: position.coords.latitude,
              lng: position.coords.longitude
            });
          },
          error => {
            console.error('Error getting user location:', error);
          }
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
      }
    };
    
    document.addEventListener("mousedown", handleOutsideClick);
    getUserLocation();
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []); 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://prod.loclshop.com/api/location-base-data');
        const data = await response.json();

        for (const store of data.loclshop_coupons) {
          const distance = getDistance(userLocation.lat, userLocation.lng, parseFloat(store.stores.store_1.lat), parseFloat(store.stores.store_1.lang));
          if (distance <= 1) {
            setStoreData(store.stores.store_1);
            break;
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (userLocation) {
      fetchData();
    }
  }, [userLocation]);

  const getDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371;
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d;
  };

  const deg2rad = deg => {
    return deg * (Math.PI / 180);
  };

  const handleClick = url => {
    window.open(url, '_blank');
  };
  
  const checkReturningUser = () => {
    const storedPhoneNumber = localStorage.getItem('phoneNumber');
    if (storedPhoneNumber) {
        setIsReturningUser(true);
        setPhoneNumber(storedPhoneNumber);
        setShowPhoneNumberPopup(true); // Set showPhoneNumberPopup to false if the phone number is already in local storage
    } else {
        setShowPhoneNumberPopup(false); // Set showPhoneNumberPopup to true if the phone number is not in local storage
    }
};

  const savePhoneNumber = () => {
    if (phoneNumber.trim() !== '') {
        localStorage.setItem('phoneNumber', phoneNumber);
        setShowPhoneNumberPopup(false);
    } else {
        // Show error message
        setShowError(true);
        // Hide error message after 3 seconds
        setTimeout(() => {
            setShowError(false);
        }, 5000);
    }
};
const handlePhoneChange = (event) => {
    setPhoneNumber(event.target.value);
};

const dropIn = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: {
        duration: 1,
        type: "spring",
        damping: 20,
        stiffness: 500,
      },
    },
    exit: {
      y: "100vh",
      opacity: 0,
    },
  };
  const savePhoneNumber1 = () => {
    localStorage.setItem('phoneNumber', phoneNumber);
    setPhoneNumber(phoneNumber)
    const formattedPhoneNumber = phoneNumber.startsWith('+1') ? phoneNumber : '+1' + phoneNumber.trim();

    if (phoneNumber.length !== 10) {
        setShowError(true);
    } else {
        if ("geolocation" in navigator) {
            // Get user's current location
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    console.log(latitude,longitude)
                    const userLocation = { latitude, longitude };
                    reverseGeocode(latitude, longitude)
                        .then(address => {
                            setUserAddress(address)
                            console.log("address from inside :", address)
                            // Post phone number along with location and address to endpoint
                            axios.post('https://prod.loclshop.com/api/save-phone-number', {
                                phoneNumber: formattedPhoneNumber,
                                latitude:  latitude, 
                                longitude: longitude,
                                address : address
                            })
                                .then(response => {
                                    // Handle success if needed
                                    console.log('Phone number, location, and address saved successfully:', response.data);
                                    // Reset phone number input
                                    setShowPhoneNumberPopup(false);
                                })
                                .catch(error => {
                                    // Handle error if needed
                                    console.error('Error saving phone number:', error);
                                });
                        })
                        .catch(error => {
                            console.error('Error getting address:', error);
                        });
                },
                (error) => {
                    console.error('Error getting user location:', error);
                }
            );
        } else {
            console.error("Geolocation is not supported by this browser.");
        }
    }
};

  return (
    <div>
        
      {storeData ? (
        <div>
                      {showPhoneNumberPopup && (
                <motion.div className="phone-popup">
                        <motion.div 
                        variants={dropIn}
                        initial="hidden"
                        animate="visible"
                        exit="exit"          
                        className="phone-popup-content">
                        <div className="col-12 phone-container d-flex justify-content-center">
                            <div className="row">
                                <div className="col-12 phonenumber">
                                    <div className="form-group text-center">
                                    <div className="phone-form">
                                        {showError && <p className="error-message">Please enter your phone number.</p>}
                                    </div>
                                    <h2>Unlock Exclusive Offers!</h2>
                                        <label className="info" htmlFor="phone">
                                        Select offers to receive exclusive SMS coupons instantly!
                                        <br></br><br></br>Join our fast-growing network of savvy shoppers for daily exclusive savings!<br></br>
                                        </label>
                                        <div className="phone-form">
                                            <input type="text" className="form-control phone-number-ip" id="phoneinp" name="phone" value={phoneNumber} onChange={handlePhoneChange} placeholder="Phone Number" />
                                            <button type="submit" className="submit-link" onClick={savePhoneNumber && savePhoneNumber1} style={{ width: '70%', backgroundColor: '#007BFF' }}>Start Saving Now!</button>
                                            <p>By entering your number, you consent to our <span class="sec-terms-link" onClick={() => setOpenSecond(true)}>Terms</span> and will receive SMS coupons.</p>
                                            <a href="/" class="closeModalLink">No thanks, I prefer to pay full price.</a>
                                        </div>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
            )}

          {openSecond && (
                <motion.div className="second-popup">
                    <motion.div 
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{
                            duration: 0.3,
                            ease: [0, 0.71, 0.2, 1.01],
                            scale: {
                                type: "spring",
                                damping: 5,
                                stiffness: 100,
                                restDelta: 0.001
                            }
                        }}
                        className="second-popup-content" ref={newRef}>
                        <div className="second-container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group text-center">
                                    <label className="text-center info2" htmlFor="phone">
                                    <h4>Privacy Policy</h4>
                                        <ul>
                                            <li>
                                                <strong>Introduction</strong>
                                                <ul>
                                                    <li>This Privacy Policy governs the collection and use of personal information for ad promotions and coupon code distribution by [Your Company Name] ("the Company").</li>
                                                    <li>By providing your personal information for ad promotions and coupon code distribution, you agree to abide by this Privacy Policy.</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <strong>Collection of Personal Information</strong>
                                                <ul>
                                                    <li>The Company collects personal information, including phone numbers, for the purpose of sending ad promotions and coupon codes to users.</li>
                                                    <li>By providing your personal information, you consent to receive promotional messages and coupon codes from the Company.</li>
                                                    <li>Your personal information will be kept confidential and will not be shared with third parties without your consent.</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <strong>Use of Personal Information</strong>
                                                <ul>
                                                    <li>The Company may use the collected personal information to send ad promotions and coupon codes via SMS or other messaging platforms.</li>
                                                    <li>You may opt-out of receiving promotional messages and coupon codes at any time by contacting the Company or following the unsubscribe instructions provided in the messages.</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <strong>Coupon Code Distribution</strong>
                                                <ul>
                                                    <li>Coupon codes distributed by the Company are for promotional purposes only and have no cash value.</li>
                                                    <li>Coupon codes may have expiration dates and usage restrictions, which will be clearly communicated at the time of distribution.</li>
                                                    <li>The Company reserves the right to modify or cancel coupon codes at any time without prior notice.</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <strong>User Responsibilities</strong>
                                                <ul>
                                                    <li>You are responsible for providing accurate and up-to-date personal information to the Company.</li>
                                                    <li>You agree not to misuse or abuse the coupon codes distributed by the Company for personal gain or profit.</li>
                                                    <li>You agree to use the coupon codes in accordance with any terms and conditions provided by the Company.</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <strong>Limitation of Liability</strong>
                                                <ul>
                                                    <li>The Company shall not be liable for any damages or losses arising from the use or misuse of coupon codes distributed to users.</li>
                                                    <li>The Company shall not be responsible for any errors or delays in the delivery of promotional messages or coupon codes.</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <strong>Governing Law</strong>
                                                <ul>
                                                    <li>This Privacy Policy shall be governed by and construed in accordance with the laws of [Your Country/State].</li>
                                                    <li>Any disputes arising out of or relating to this Privacy Policy shall be subject to the exclusive jurisdiction of the courts in [Your Jurisdiction].</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <strong>Changes to Privacy Policy</strong>
                                                <ul>
                                                    <li>The Company reserves the right to update or modify this Privacy Policy at any time without prior notice.</li>
                                                    <li>Users will be notified of any changes to the Privacy Policy via the contact information provided.</li>
                                                </ul>
                                            </li>
                                        </ul>
                                        <button className="btn btn-primary"onClick={() => setOpenSecond(false)}>Close</button>
                                    </label> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
            )}

        <Title1 className="text-center title">{isReturningUser ? `Welcome back to ${storeData.title}` : `Welcome to ${storeData.title}`}</Title1>                 
          <GridContainer>
            {storeData.affiliate_links.map(link => (
              <ItemContainer key={link.id} onClick={() => handleClick(link.link.url)}>
                <Title><a href={link.link.url}></a></Title>
                <ImageLink href={link.link.url} target="_blank">
                  <img src={link.image.src} alt={link.image.alt} />
                </ImageLink>
              </ItemContainer>
            ))}
          </GridContainer>
        </div>
      ) : (
        <Home_coupons/>
      )}
    </div>
  );
}

export default MyComponent;
