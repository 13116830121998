// SupersetEmbeddedDashboard.js
import React, { useEffect } from 'react';

const SupersetEmbeddedDashboard = ({ isOpen }) => {
    useEffect(() => {
        // Load Superset Embedded SDK script dynamically
        const script = document.createElement('script');
        script.src = 'https://unpkg.com/@superset-ui/embedded-sdk';
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
            const fetchGuestToken = () =>
                'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjp7InVzZXJuYW1lIjoiamVldmFuX3VzZXIiLCJmaXJzdF9uYW1lIjoiamVldmFuIiwibGFzdF9uYW1lIjoidXNlciJ9LCJyZXNvdXJjZXMiOlt7ImlkIjoiNzIyYjhjMTktMjQ4MS00OWRiLTg3ODAtOWE4YjBkYTk3NmUyIiwidHlwZSI6ImRhc2hib2FyZCJ9XSwicmxzX3J1bGVzIjpbXSwiaWF0IjoxNzA4OTIxMzgyLjI0MjY4OTgsImV4cCI6MTc0MDQ1NzM4Mi4yNDI2ODk4LCJhdWQiOiJodHRwOi8vc3VwZXJzZXQ6ODA4OC8iLCJ0eXBlIjoiZ3Vlc3QifQ.lfS1UrGXRNLDdhknhrWNHuCWYw4jbFarjTZnT7HXjwA'; // guest_token

            // Embed dashboard once the Superset Embedded SDK is loaded
            window.supersetEmbeddedSdk.embedDashboard({
                id: '722b8c19-2481-49db-8780-9a8b0da976e2',
                supersetDomain: 'https://ddp.softroniclabs.com',
                mountPoint: document.getElementById('container'),
                fetchGuestToken: fetchGuestToken,
                dashboardUiConfig: {
                    filters: {
                        expanded: false
                    }
                }
            });

            // Additional code to modify iframe dimensions
            const dashboardElement = document.getElementById('container');
            if (dashboardElement) {
                const iframe = dashboardElement.querySelector('iframe');
                if (iframe) {
                    iframe.style.width = '100%'; // Set the width to 100% by default
                    iframe.style.height = '1960px'; // Set the height as needed
                }
            }
        };

        // Cleanup function
        return () => {
            // Remove the dynamically added script when component unmounts
            document.body.removeChild(script);
        };
    }, []);

    return <div id="container" />;
};

export default SupersetEmbeddedDashboard;
