import React from 'react';
import BrandEmbeddedRepaa from './repaadashboard';
import SideBar from '../components/UserSideBar'


const BrandRepaa = () => {

    return (
        <div>
            <SideBar>
                <BrandEmbeddedRepaa />
            </SideBar>
        </div>
    );
};

export default BrandRepaa;
