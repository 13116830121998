import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

// Styled components
const UnsubscribeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f4f4f4;
  padding: 20px;
`;

const Title = styled.h1`
  font-size: 24px;
  color: #333;
`;

const Description = styled.p`
  text-align: center;
  color: #666;
  margin: 10px 0 20px;
`;

const Button = styled.button`
  padding: 10px 20px;
  font-size: 18px;
  color: #fff;
  background-color: #db4437;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #c33;
  }
`;

const Input = styled.input`
  margin-bottom: 20px;
  padding: 10px;
  width: calc(100% - 40px); // Adjust width to account for padding
  max-width: 300px; // Limit width for larger screens
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const ResponsiveWrapper = styled.div`
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const UnsubscribePage = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
  
    const handleUnsubscribe = () => {
      axios.post('https://prod.loclshop.com/api/unsubscribe', { email })
        .then(response => {
          setMessage(response.data.message);
          setEmail(''); // Clear the input field
          setTimeout(() => {
            window.location.reload();
        }, 1000);
        })
        .catch(error => {
          setMessage(error.response ? error.response.data.message : 'Failed to unsubscribe');
        });
    };
  
    return (
      <UnsubscribeContainer>
        <ResponsiveWrapper>
          <Title>Unsubscribe from Our Emails</Title>
          <Description>Enter your email address to confirm your unsubscribe request:</Description>
          <Input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <Button onClick={handleUnsubscribe}>Unsubscribe</Button>
          {message && <p>{message}</p>}
        </ResponsiveWrapper>
      </UnsubscribeContainer>
    );
  };
  
  export default UnsubscribePage;