import React, { useState } from 'react';
import { IoMdShare, IoMdCopy } from 'react-icons/io';
import { FaWhatsapp, FaFacebookF, FaLinkedinIn, FaInstagram} from 'react-icons/fa';
import { BsTwitterX } from "react-icons/bs";



const ShareButton = () => {
  const shareURL = window.location.href;
  const [showModal, setShowModal] = useState(false);
  const [copyClicked, setCopyClicked] = useState(false);
  const [showNotification, setShowNotification] = useState(false); // State for notification

  const [isHovered, setIsHovered] = useState(false);

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Share this page',
          url: shareURL,
        });
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      setShowModal(true); // Show the modal
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareURL);
    setCopyClicked(true);
    setShowNotification(true); // Show notification
    setTimeout(() => {
      setShowNotification(false); // Hide notification after 3 seconds
    }, 3000);
  };
  
  const closeModal = () => {
    setShowModal(false);
    setCopyClicked(false);
  };

  const buttonStyle = {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    zIndex: 9999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#8BBD98',
    borderRadius: '50%',
    padding: '17px',
    border: 'none',
    cursor: 'pointer',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    fontSize: '34px',
    transition: 'transform 0.3s ease-in-out', // Add transition for zoom effect
  };
  
  const buttonHoverStyle = {
    transform: 'scale(1.1)', // Zoom-in effect
  };


  const modalOverlayStyle = {
    position: 'fixed',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,
  };

  const modalStyle = {
    backgroundColor: '#fff',
    padding: '30px',
    borderRadius: '8px',
    width: '500px',
    textAlign: 'center',
  };

  const notificationStyle = {
    position: 'fixed',
    bottom: '20px',
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: '#333',
    color: '#fff',
    padding: '10px 20px',
    borderRadius: '4px',
    zIndex: 9999,
    display: showNotification ? 'block' : 'none',
  };

  const socialIconsStyle = {
    marginTop: '20px',
  };

  const socialIconLinkStyle = {
    marginRight: '10px',
    fontSize: '30px',
    color: '#000',
  };

  const copyIconStyle = {
    marginRight: '10px',
    fontSize: '30px',
    color: copyClicked ? '#333' : '#000',
    cursor: 'pointer',
  };

  const socialIconStyle = {
    marginRight: '10px',
    fontSize: '30px',
    color: '#000',
    transition: 'color 0.3s ease-in-out',
  };

  const inputStyle = {
    border: '2px solid #000000', // Add border style
    borderRadius: '4px', // Optional: Add border radius for rounded corners
    padding: '9px', // Optional: Add padding for better appearance
    width: '80%', // Optional: Adjust width as needed
    transition: 'color 0.3s ease-in-out',
  };
  
  const headerStyle = {
    fontFamily: 'Righteous, sans-serif', // Specify the "Righteous" font and fallback to sans-serif
    fontWeight: 'normal', // Optional: Adjust the font weight if needed
    color: '#5f7c59',
    marginBottom: '20px',
  };
  
  return (
    <>
      <button 
  onClick={handleShare} 
  style={isHovered ? {...buttonStyle, ...buttonHoverStyle} : buttonStyle} 
  onMouseEnter={() => setIsHovered(true)}
  onMouseLeave={() => setIsHovered(false)}
  aria-label="Share this page"
>
  <IoMdShare />
</button>


      {showModal && (
        <div style={modalOverlayStyle} onClick={closeModal}>
          <div style={modalStyle} onClick={(e) => e.stopPropagation()}>
          <h3 style={headerStyle}>Share our LOCLshop deals with your friends</h3>
            <div style={{ marginBottom: '20px' }}>
            <input type="text" value={shareURL} readOnly style={inputStyle}  />

              <IoMdCopy style={copyIconStyle} onClick={copyToClipboard} />
            </div>
            <div style={socialIconsStyle}>
                  <a href={`https://wa.me/?text=${shareURL}`} target="_blank" rel="noopener noreferrer" style={socialIconLinkStyle}>
                      <FaWhatsapp style={socialIconStyle} />
                  </a>
                  <a href={`https://www.facebook.com/sharer/sharer.php?u=${shareURL}`} target="_blank" rel="noopener noreferrer" style={socialIconLinkStyle}>
                      <FaFacebookF style={socialIconStyle} />
                  </a>
                  <a href={`https://www.linkedin.com/shareArticle?url=${shareURL}`} target="_blank" rel="noopener noreferrer" style={socialIconLinkStyle}>
                      <FaLinkedinIn style={socialIconStyle} />
                  </a>
                  <a href={`https://twitter.com/intent/tweet?url=${shareURL}`} target="_blank" rel="noopener noreferrer" style={socialIconLinkStyle}>
                        <BsTwitterX style={socialIconStyle} />
                  </a>

                  <a href={`https://www.instagram.com/share?url=${shareURL}`} target="_blank" rel="noopener noreferrer" style={socialIconLinkStyle}>
                      <FaInstagram style={socialIconStyle} />
                  </a>
              </div>


          </div>
        </div>
      )}

      {/* Notification */}
      <div style={notificationStyle}>
        Copied to clipboard
      </div>
    </>
  );
};

export default ShareButton;
