import React from 'react';
import DashboardBrandEmbeddedRepaa from './sampledashboard';
import SideBar from '../components/UserSideBar'


const SampleDashboardComp = () => {

    return (
        <div>
            <SideBar>
                <DashboardBrandEmbeddedRepaa />
            </SideBar>
        </div>
    );
};

export default SampleDashboardComp;
