import React, { useState } from 'react';
import homeImage from '../assets/images/cover.png';
import styled, { keyframes } from 'styled-components';
import 'react-slideshow-image/dist/styles.css';
import { Button, Form } from 'react-bootstrap'; // Import Bootstrap components
import axios from 'axios';
import { Toast } from 'react-bootstrap';
import Footer from '../components/sticky_footer';
import DayDeal from '../components/day_deal';
import ImageSlide from '../components/img_slide';
import Carousel from '../components/Carousel';


const moveImages = keyframes`
  0% {
    transform: translateX(240%) scale(1); /* Start from initial position and scale */
  }
  50% {
    transform: translateX(0) scale(1.4); /* Stop halfway and scale */
  }
  100% {
    transform: translateX(-100%) scale(1); /* Move to the left by 100% and reset scale */
  }
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column; /* Stack children vertically */
  height: 100%;
`;

const ImgContainer = styled.div`
  width: 100%;
  background-color: black;
  color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertical alignment */
  padding-bottom : 0%;
  

  @media screen and (min-width: 768px) {
    flex-direction: row;
    align-items: center; /* Horizontal alignment */
    height: 100%;
    padding-top: -10%; /* Adjust padding for larger screens */
  }
`;

const Image = styled.img`
  width: 100%;
  height: 10%;

  @media screen and (min-width: 768px) {
    padding-top: 0%; /* Adjust padding for larger screens */
    height: 100%;
    width: 70%;
  }
`;

const ContentContainer = styled.div`
  text-align: center;
  padding: 20px;
  @media screen and (min-width: 768px) {
    padding-top: 4%; /* Adjust padding for larger screens */
    height: 100%;
    width: 70%;
  }
`;

const Heading = styled.h1`
  font-family: 'righteous';
  font-size: 35px;
`;

const SubHeading1 = styled.p`
  font-size: 25px;
`;

const SubHeading2 = styled.p`
  font-size: 35px;
  text-align: center;
  padding-top: 20px; /* Add some space from the top */
  color: #AD8C8C; /* Lavender color in RGB */
  font-family: 'righteous';
`;

const SubHeading3 = styled.p`
  font-size: 35px;
  text-align: center;
  padding-top: 20px; /* Add some space from the top */
  color: #AD8C8C; /* Lavender color in RGB */
  font-family: 'righteous';

`;

const SubHeading4container = styled.div`
  display: flex; // Enable flex layout
  flex-direction: column; // Default to column layout
  align-items: center; // Center items for column layout
  background-color: rgba(128, 128, 128, 0.1);

  @media screen and (max-width: 480px) { // Adjust this to suit the zoom level that corresponds to 25%
    flex-direction: row; // Switch to row layout for smaller widths
    justify-content: center; // Center items horizontally for row layout
    flex-wrap: wrap; // Allow items to wrap as needed
  }
`;

const SubHeading4 = styled.p`
  font-size: 30px;
  text-align: center;
  padding-top: 20px;
  color: #000;
  font-family: 'righteous';
  margin-bottom: 0;
`;

const SubHeading5 = styled.p`
  font-size: 25px;
  text-align: center;
  color: #000;
  font-family: 'righteous';
  padding-top: 13px;
`;

const SubHeading6 = styled.p`
  font-size: 25px;
  text-align: center;
  color: #000;
  font-family: 'righteous';
`;


const StyledButton = styled.button`
  width: 80%;
  height: 60px;
  align-items: center;
  text-align: center;
  background-color: black !important;
  color: white;
  font-family: 'righteous';
  margin-left: 10%;
  border: 1px solid black; /* Set border color to black */
  font-size:15px;
  margin-top: 10px;
  border-radius: 5px; /* Add border radius for a rounded appearance */

`;

const Contactus = styled.div`
background-color: rgba(256, 256, 256, 1);
padding-bottom : 5%;
padding-top : 10%;

@media screen and (min-width: 768px) {
  margin-bottom: 0%;
}
`;

const SubHeading7 = styled.p`
  font-size: 35px;
  text-align: center;
  padding-top: 1%;
  color: #AD8C8C;
  font-family: 'righteous';
  margin-bottom: 1%;

  @media screen and (min-width: 768px) {
    margin-top: -10%;
  }
`;

const SubHeading8 = styled.p`
  font-size: 25px;
  text-align: center;
  margin-top: -0.5%;
  color: #000000;
  font-family: Arial, Helvetica, sans-serif;
`;

const SubHeading9 = styled.p`
  font-size: 15px;
  text-align: center;
  margin-top: 1%;
  color: #908F8F;
  font-family: Arial, Helvetica, sans-serif;
  margin-left: 4%;
  margin-right: 4%;
`;

const SubHeading10 = styled.p`
  font-size: 20px;
  text-align: center;
  margin-top: 1%;
  color: #908F8F;
  font-family: Arial, Helvetica, sans-serif;
  margin-left: 4%;
  margin-right: 4%;
`;


const ContactusButton = styled(Button)`
  width: 80%;
  height: 60px;
  align-items: center;
  text-align: center;
  background-color: black !important;
  color: white;
  font-family: 'righteous' !important;
  margin-left: 10%;
  border: 1px solid black; /* Set border color to black */
  font-size:15px;
  margin-top: 10px;
  border-radius: 5px; /* Add border radius for a rounded appearance */
  &:hover {
    background-color: black; /* Change background color on hover */
    color: white; /* Change text color on hover */
  }

  &:focus {
    box-shadow: none; /* Remove the focus box shadow */
  }

  &:active {
    background-color: black; /* Ensure the background color remains the same on click */
  }

  @media screen and (min-width: 768px) {
    margin-top: -0%;
  }
`;

const FormLabel = styled(Form.Label)`
  color: black; /* Set label color */
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 5%;

`;

const FormControl = styled(Form.Control)`
  width: 100%; /* Set the width to 100% */
  background-color: white;
  color: black;
  border: 1px solid black;
  border-radius: 5px;
`;


const SubmitButton = styled(ContactusButton)`
  width: 80%;
  height: 60px;
  align-items: center;
  text-align: center;
  background-color: black !important;
  color: white;
  font-family: 'righteous' !important;
  margin-left: 10%;
  border: 1px solid black; /* Set border color to black */
  font-size:15px;
  margin-top: 10px;
  border-radius: 5px; /* Add border radius for a rounded appearance */
  &:hover {
    background-color: black; /* Change background color on hover */
    color: white; /* Change text color on hover */
  }
`;

const defUrl = process.env.REACT_APP_API_URL 

const HomePage = () => {
  // Define state variables using useState hook
  const [showContactForm, setShowContactForm] = useState(false);

  const [showToast, setShowToast] = useState(false);

  // Define function to handle contact button click
  const handleContactButtonClick = () => {
    setShowContactForm(!showContactForm); // Toggle the state to show/hide the contact form
  };

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });



  const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
      e.preventDefault();

      // Check if any field is empty
      if (!formData.name || !formData.email || !formData.message) {
        alert('Please fill in all fields.');
        return;
       }

      try {
          let response;
          // Check if the first server is available
          try {
              response = await axios.post('https://prod.loclshop.com/api/send-email', formData);
          } catch (error) {
              // If the first server is not available, use the second server
              response = await axios.post('https://prod.loclshop.com/api/send-email', formData);
          }

          console.log(response.data);
          // Clear form data after successful submission
          setFormData({
            name: '',
            email: '',
            message: '',
          });
          // Show toast message for 3 seconds
          setShowToast(true);
          setTimeout(() => {
              setShowToast(false);
              // Reload the window after the toast message is hidden
              setTimeout(() => window.location.reload(), 2000);
          }, 1000);
          // Optionally, you can display a success message or redirect the user.
      } catch (error) {
          console.error('Error sending email:', error);
          // Handle errors appropriately (e.g., display error message to the user).
      }
  };
  
  return (
    <StyledContainer>
      <ImgContainer>
        <Image src={homeImage} alt="Home Page Image" />
        <ContentContainer>
          <Heading>SUPPORT LOCAL STORES</Heading>
          <SubHeading1>
            ALL the information, coupons, promotions, and Apps for all the Stores at your local shopping center, personalized for you.
          </SubHeading1>
        </ContentContainer>
      </ImgContainer>
      <DayDeal />
      <SubHeading2>Shoppers in your area also liked these local deals</SubHeading2>
      <Carousel/>

      <SubHeading3>
      EXPLORE YOUR LOCAL SHOPPING CENTER WITH LOCLSHOP
      </SubHeading3>
     <ImageSlide />
      <SubHeading4container>
      <SubHeading4>
      DISCOVER THE
      </SubHeading4>
      <SubHeading5>
      "POWERED BY LOCLSHOP"
      </SubHeading5>
      <SubHeading6>
      SOLUTION
      </SubHeading6>
      </SubHeading4container>
      <a href="https://vnuai.com/" target="_blank" rel="noopener noreferrer">
      <StyledButton>GET YOUR PROMO CODES HERE</StyledButton>
      </a>  
      
      <Contactus>
      <SubHeading7>CONTACT US</SubHeading7>
      <SubHeading8>Phone - 203-273-3737</SubHeading8>
      <SubHeading8>Email - Info@fairchildrmn.com</SubHeading8>
      <SubHeading9>We love our customers, so feel free to contact us 24/7 365.</SubHeading9>
      <SubHeading10>1478 46th Avenue Northeast, St. Petersburg, Florida 33703, United States</SubHeading10>
    
        <ContactusButton onClick={handleContactButtonClick}>Drop us a line!</ContactusButton>
        {/* Conditionally render the contact form */}
        {showContactForm && 
          <> <Form onSubmit={handleSubmit}>
          <div className="row justify-content-center"> {/* Centering the form */}
            <div className="col-md-6"> {/* Setting the width of the form */}
              <Form.Group controlId="formBasicName">
                <FormLabel>Name</FormLabel>
                <FormControl 
                  type="text" 
                  name="name" 
                  placeholder="Enter your name" 
                  value={formData.name} 
                  onChange={handleChange} 
                  className="w-100" // Making the input field full-width
                />
              </Form.Group>
        
              <Form.Group controlId="formBasicEmail">
                <FormLabel>Email address</FormLabel>
                <FormControl 
                  type="email" 
                  name="email" 
                  placeholder="Enter your email" 
                  value={formData.email} 
                  onChange={handleChange} 
                  className="w-100" // Making the input field full-width
                />
              </Form.Group>
        
              <Form.Group controlId="formBasicMessage">
                <FormLabel>Message</FormLabel>
                <FormControl 
                  as="textarea" 
                  name="message" 
                  rows={3} 
                  placeholder="Enter your message" 
                  value={formData.message} 
                  onChange={handleChange} 
                  className="w-100" // Making the input field full-width
                />
              </Form.Group>
              <br></br>
              <SubmitButton variant="primary" type="submit">
                Submit
              </SubmitButton>
            </div>
          </div>
        </Form>
        <Toast
          show={showToast}
          onClose={() => setShowToast(false)}
          delay={3000}
          autohide
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#333', // Background color
            color: '#fff', // Font color
            fontFamily: 'Arial, sans-serif', // Font family
            padding: '10px', // Padding
            borderRadius: '5px', // Border radius
          }}>
          <Toast.Body>Mail sent successfully!</Toast.Body>
        </Toast></>
        }
        <div className="d-flex justify-content-center mt-4"> {/* Center the iframe horizontally */}
                <div className="embed-responsive embed-responsive-16by9">
                <iframe
                className="embed-responsive-item"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3528.856297206767!2d-82.61307662381645!3d27.814193876121458!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88c2e12eb2d52d43%3A0xa55d06b480cc9a04!2s1478%2046th%20Ave%20NE%2C%20St.%20Petersburg%2C%20FL%2033703%2C%20USA!5e0!3m2!1sen!2sin!4v1710757532011!5m2!1sen!2sin"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                style={{ width: '100%', height: '100%' }} // Adjust width and height here
                title="Google Maps Location"
              ></iframe>
                </div>
              </div>              
      </Contactus>
      <Footer />
    </StyledContainer>
  );
}

export default HomePage;
