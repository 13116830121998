import React from 'react';
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import fairchild_img_1 from '../assets/images/fairchild_rmn_img.png';
import fairchild_img_2 from '../assets/images/what_is_fairchild_do_img.png';
import fairchild_img_3 from '../assets/images/Retail_Center_Media_Network_img.png';
import fairchild_img_4 from '../assets/images/steps.png';
import Footer from '../components/sticky_footer'


const Container = styled.div`
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 80%; /* Adjust the width of the container */
  margin-left: 10%; /* Add left margin */
`;


const ContentContainer = styled.div`
  text-align: center;
  padding: 20px;
`;

const Heading = styled.h1`
  font-family: 'righteous';
  font-size: 35px;
  color: #b48395; /* Change font color */
`;

const Image = styled.img`
  width: 100%;
  margin-top: 20px;
`;

const SubHeading = styled.p`
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 30px;
`;

const SubHeading1 = styled.p`
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 30px;
  color: #7A7A7A; /* Change font color */
`;

const ContentinstagramContainer = styled.div`
  text-align: center;
  background-color: #EFEFEF;
  width: 100%;
  padding: 30px;
`;

const InstaSubHeading = styled.p`
  margin-top: 10px;
  margin-bottom: 15px;
  font-family: 'righteous';
  font-size: 35px;
  color: #b48395; /* Change font color */
`;

const InstaSubHeading1 = styled.p`
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 60px;
  color: #7A7A7A; /* Change font color */
`;

const ContentreviewContainer = styled.div`
  text-align: center;
  background-image: url(${fairchild_img_4});
  background-size: cover;
  background-position: center;
  width: 100%;
  padding: 20px;
`;

const ReviewSubHeading = styled.p`
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: 'righteous';
  font-size: 35px;
  color: #fff; /* Change font color */
`;

const ReviewSubHeading1 = styled.p`
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 30px;
  color: white;
  background-color: rgba(6, 6, 6, 0.5); /* Change the last value (0.5) for opacity */
`;

const About = () => {
  return (
   <> <Container>
      <ContentContainer>
        <Heading>LOCLSHOP IS A SERVICE OF FAIRCHILD RMN LLC</Heading>
        <Image src={fairchild_img_1} alt="Fairchild RMN" />
        <SubHeading>A NEW WORLD FOR SHOPPERS</SubHeading>
        <SubHeading1>LOCLshop Media can deliver Retail Proximity Advertising with closed loop in-store attribution reporting. </SubHeading1>
        <SubHeading1>Today LOCLshop can deliver advertising to over 14M unique shoppers each month across over 5,000 shopping centers and more than 900Malls with more than 160,000 stores combined.   </SubHeading1>
        <SubHeading1>LOCLshop Media allows advertisers to Reach their Audience of Shoppers, Where it Matters, When It Matters. The Right Audience, at the Right Time, at the Right Place.</SubHeading1>
        <Image src={fairchild_img_2} alt="Fairchild RMN DO" />
        <SubHeading>What Does Fairchild RMN Do?</SubHeading>
        <SubHeading1>Fairchild RMN acts as a retail media network solution provider for the retail property owner.  We support local stores by connecting them with shoppers. Fairchild RMN specializes in Retail Center Media Networks.</SubHeading1>
        <Image src={fairchild_img_3} alt="Fairchild RMN DO" />
        <SubHeading>Over 5,000 Sites in the Network</SubHeading>
        <SubHeading1>How is the Fairchild Retail Center Media Network different from other Retail Media Networks?</SubHeading1>
        <SubHeading1>The big difference is that Fairchild provides a seamless way for shoppers to conveniently access coupons and promotions for ALL the retailers at a LOCAL retail center or mall.  This provides shoppers with a single screen to access savings without the need to open each retailer’s app individually.</SubHeading1>
        <SubHeading1>Fairchild ONLY sends these messages when shoppers arrive at the shopping center or mall.</SubHeading1>
        <SubHeading1>Fairchild’s retail center media network gives retailers a more effective way to connect the local shopper to the local store at each and every location.</SubHeading1>
      </ContentContainer>
    </Container>
    <ContentinstagramContainer>
        <InstaSubHeading>INSTAGRAM</InstaSubHeading>
        <InstaSubHeading1>Feed coming soon</InstaSubHeading1>
      </ContentinstagramContainer>
        
      <ContentreviewContainer>
        <ReviewSubHeading>REVIEWS</ReviewSubHeading>
        <ReviewSubHeading1>Reviews coming soon!</ReviewSubHeading1>
      </ContentreviewContainer>
    <Footer />
    </>
  );
}

export default About;
