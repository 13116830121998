import React, { useEffect } from 'react';

const BrandEmbeddedDashboard = () => {
    useEffect(() => {
        // Function to fetch guest token
        const fetchGuestToken = () => localStorage.getItem("token");

        // Retrieve dashboardId from localStorage
        const dashboardId = localStorage.getItem("dashboardid");

        // Check if dashboardId and token exist
        if (dashboardId && fetchGuestToken()) {
            // Load Superset Embedded SDK script dynamically
            const script = document.createElement('script');
            script.src = 'https://unpkg.com/@superset-ui/embedded-sdk';
            script.async = true;
            script.onload = () => {
                window.supersetEmbeddedSdk.embedDashboard({
                    id: dashboardId,
                    supersetDomain: 'https://ddp.softroniclabs.com',
                    mountPoint: document.getElementById('container'),
                    fetchGuestToken: fetchGuestToken,
                    dashboardUiConfig: {
                        filters: {
                            expanded: false
                        }
                    }
                });

                // Additional code to modify iframe dimensions
                const dashboardElement = document.getElementById('container');
                if (dashboardElement) {
                    const iframe = dashboardElement.querySelector('iframe');
                    if (iframe) {
                        iframe.style.width = '100%'; // Set the width to 100% by default
                        iframe.style.height = '1960px'; // Set the height as needed
                    }
                }
            };

            document.body.appendChild(script);

            // Cleanup function
            return () => {
                // Remove the dynamically added script when component unmounts
                document.body.removeChild(script);
            };
        }
    }, []); // Empty dependency array ensures this effect runs only once

    return <div id="container" />;
};

export default BrandEmbeddedDashboard;
