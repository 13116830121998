import React, { useState } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import './navbar.css'; // Update to correct CSS file path
import 'bootstrap/dist/css/bootstrap.min.css';

const NavbarComp = () => {
    const [expanded, setExpanded] = useState(false);
    const navigate = useNavigate();

    const handleToggle = () => {
        setExpanded(!expanded);
    };

    const handleLinkClick = () => {
        setExpanded(false);
    };

    const handleAdminClick = () => {
        navigate('/admin');
    };

    const handleAgencyClick = () => {
        navigate('/agency-login');
    };

    return (
        <div>
            <Navbar bg="light" variant="light" expand="lg" expanded={expanded}>
                <Navbar.Brand className="navbar-brand" href="/">LOCLshop</Navbar.Brand>
                <Navbar.Toggle onClick={handleToggle} aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav className="mr-auto my-9 my-lg-0" style={{ maxHeight: '100%' }} navbarScroll>
                        <Nav.Link as={Link} to="/" onClick={handleLinkClick}>
                            Home
                        </Nav.Link>
                        <Nav.Link as={Link} to="/about" onClick={handleLinkClick}>
                            About
                        </Nav.Link>
                        <Nav.Link as={Link} to="/coupons" onClick={handleLinkClick}>
                            Coupon
                        </Nav.Link>
                        <Nav.Link as={Link} to="/blog" onClick={handleLinkClick}>
                            Blog
                        </Nav.Link>
                        <Nav.Link as={Link} to="/demo-library" onClick={handleLinkClick}>
                            Partners Demo Hub
                        </Nav.Link>
                        <Nav.Link as={Link} to="/demo-video" onClick={handleLinkClick}>
                            Demo video
                        </Nav.Link>
                        <NavDropdown
                            title="Login "
                            id="collasible-nav-dropdown"
                            className="nav-item dropdown"
                        >
                            <NavDropdown.Item className="dropdown-item" onClick={handleAdminClick}>
                                As Admin
                            </NavDropdown.Item>
                            <NavDropdown.Item className="dropdown-item" onClick={handleAgencyClick}>
                                As Ad Agency
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    );
};

export default NavbarComp;
