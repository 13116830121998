import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Table, Form, Container as BSContainer, Row, Col } from 'react-bootstrap';
import Sidebar from '../components/SideBar';

const StyledContainer = styled(BSContainer)`
  margin-top: 2rem;
  padding: 1rem;
  background-color: #f8f8f8;
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
`;

const FilterContainer = styled(Row)`
  margin-bottom: 1rem;
`;

const FilterInput = styled(Form.Control)`
  flex: 1;
  margin-right: 1rem;
`;

const UserActivityPage = () => {
  const [userData, setUserData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [email, setEmail] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const loginResponse = await axios.get('https://prod.loclshop.com/api/api/loginactivity');
        const logoutResponse = await axios.get('https://prod.loclshop.com/api/api/logoutactivity');

        const combinedData = loginResponse.data.map((login) => {
          const logoutData = logoutResponse.data.find(
            (logout) =>
              logout.email === login.email &&
              new Date(logout.logOutDateTime) > new Date(login.loginDateTime)
          );
          return {
            email: login.email,
            loginTime: new Date(login.loginDateTime),
            logoutTime: logoutData ? new Date(logoutData.logOutDateTime) : null,
          };
        });

        setUserData(combinedData);
        setFilteredData(combinedData);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, []);

  const handleFilterChange = (e) => {
    const filter = e.target.value.toLowerCase();
    const filtered = userData.filter((user) => user.email.toLowerCase().includes(filter));
    setFilteredData(filtered);
    setEmail(filter);
  };

  return (
    <Sidebar>
      <StyledContainer>
        <FilterContainer>
          <Col xs={12} md={6}>
            <FilterInput
              type="text"
              placeholder="Filter by email"
              value={email}
              onChange={handleFilterChange}
            />
          </Col>
        </FilterContainer>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Email</th>
              <th>Login Time</th>
              <th>Logout Time</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((user, index) => (
              <tr key={index}>
                <td>{user.email}</td>
                <td>{user.loginTime.toLocaleString()}</td>
                <td>{user.logoutTime ? user.logoutTime.toLocaleString() : 'Not logged out yet'}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </StyledContainer>
    </Sidebar>
  );
};

export default UserActivityPage;