import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

const defaultTheme = createTheme();

const UserSignIn = () => {
    const [error, setError] = useState(null);
    const [showPassword, setShowPassword] = useState(false);

    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const username = data.get('email');
        const password = data.get('password');
    
        try {
            const response = await fetch('https://prod.loclshop.com/api/mlogin', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userName: username, password: password }),
            });
    
            if (response.ok) {
                const responseData = await response.json();
                localStorage.setItem('User_authenticated', 'true');

                const { dashboardId, token, repaadashboardId, repaatoken, samplerepaadashboardId, samplerepaatoken, sampledashboardId, sampledashboardtoken, user } = responseData;
                const { emailId } = user;
                if (dashboardId) {
                    localStorage.setItem("dashboardid", dashboardId);
                }
    
                if (token) {
                    localStorage.setItem("token", token);
                }
    
                if (repaadashboardId) {
                    localStorage.setItem("repaadashboardid", repaadashboardId);
                }
    
                if (repaatoken) {
                    localStorage.setItem("repaatoken", repaatoken);
                }
    
                if (samplerepaadashboardId) {
                    localStorage.setItem("samplerepaadashboardid", samplerepaadashboardId);
                }
    
                if (samplerepaatoken) {
                    localStorage.setItem("samplerepaatoken", samplerepaatoken);
                }
    
                if (sampledashboardId) {
                    localStorage.setItem("sampledashboardid", sampledashboardId);
                }
    
                if (sampledashboardtoken) {
                    localStorage.setItem("sampledashboardtoken", sampledashboardtoken);
                }
    
                localStorage.setItem("emailid", emailId);
                navigate('/useroptions');

            } else {
                setError('Invalid username or password. Please try again.');
            }
        } catch (error) {
            console.error('Error:', error);
            setError('An error occurred while trying to log in. Please try again.');
        }
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: '35%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginBottom: '83%',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Ad Agency Admin Log In
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        noValidate
                        sx={{ mt: 3 }}
                    >
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="User Name"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            autoComplete="current-password"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword((prevState) => !prevState)}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {error && (
                            <Typography variant="body2" color="error" align="center" sx={{ mt: 1 }}>
                                {error}
                            </Typography>
                        )}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign In
                        </Button>
                        <Grid container>
                            <Grid item xs>
                            </Grid>
                            <Grid item>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
};

export default UserSignIn;