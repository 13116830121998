import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import DealSelection from './pages/landing_page';
import Home from './pages/home';
import About from './pages/about';
import NavbarComp from './components/navbar';
import Disclosure from './pages/Disclosure_page';
import DealSelection2 from './pages/landing_page_2';
import Blog from './pages/blog';
import Dashboard from './components/dashboard';
import TableComp from './components/table_master';
import SignIn from './components/login';
import NewsWebsite from './components/news_website';
import PGAstore from './pages/pga_store';
import Foreyesv1 from './pages/for-eyes_v1';
import Foreyesv2 from './pages/for-eyes_v2';
import PetCo from './pages/petco';
import Coupons from './pages/coupons.js';
import PgaSms from './pages/pga-sms.js';
import Forwordpage from './pages/forwordpage.js';
import VideoComponent from './components/video_component.js';
// import UrlBasedLandingpage from './pages/url_based_landing_page.js';
import UrlBased from './pages/url_base.js';
import EmptyLocation from './pages/empty_location.js';
import ReactGA from 'react-ga';
import DemoSideBar from './components/demo_sidebar.js';
import Dealoftheday from './pages/Dealoftheday.js';
import SingleBlogPage from './pages/blog_single_page.js';
import UserFormComp from './components/UserFormComp.js';
import ManageUsersComp from './components/ManageUsersComp.js';
import ModifyUserComp from './components/modify_comp.js';
import UnsubscribePage from './pages/emailUnsubscribe.js';
import ResubscribePage from './pages/resubscription.js';
import UserSignIn from './components/user_login.js';
import UserReport from './pages/user_report.js';
import DashboardAdmin from './pages/dashboardadmin.js';
import BrandDashboard from './pages/branddashboardcomp.js';
import RepaaAdmin from './pages/repaaadmin.js';
import BrandRepaa from './pages/repaacomp.js';
// import Homepage from './pages/new_home.js';
import SampleDashboardAdmin from './pages/sampledashboardadmin.js';
import SampleRepaaAdmin from './pages/samplerepaaadmin.js';
import SampleRepaaComp from './pages/samplerepaacomp.js';
import SampleDashboardComp from './pages/sampledashboardcomp.js';
import AssignPage from './components/assign_report.js';
import UserActivityPage from './pages/userLogs.js';
import UserOptions from './pages/user_options.js';
import AdminPortal from './pages/admin_portal.js';
import Dashboard_2 from './components/dashboard_2.js';

ReactGA.initialize('AW-16449170732/LwU1CKDy-KUZEKzayaM9');

const App = () => {
    const admin_authenticated = localStorage.getItem('admin_authenticated');
    const isUserAuthenticated = localStorage.getItem('User_authenticated');
    
    return (
        <div className="App">
            <Router>
            <NavbarComp />
                <Routes>

                    <Route path="/" element={<Home />} />
                    <Route path="/coupon" element={<Coupons />} />
                    <Route path="/userreport" element={isUserAuthenticated ? <UserReport /> : <Navigate to="/login" />} />
                    <Route path="/marblehill" element={<DealSelection />} />
                    <Route path="/for-eyes" element={<DealSelection2 />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/disclosure" element={<Disclosure />} />
                    <Route path="/blog" element={<Blog />} />
                    <Route path="/admin" element={<SignIn />} />
                    <Route path="/agency-login" element={<UserSignIn />} />
                    <Route path="/demo" element={<NewsWebsite />} />
                    <Route path="/pga-store" element={<PGAstore />} />
                    <Route path="/pga-sms" element={<PgaSms />} />
                    <Route path="/for-eyes-v1" element={<Foreyesv1 />} />
                    <Route path="/for-eyes-v2" element={<Foreyesv2 />} />
                    <Route path="/petco" element={<PetCo />} />
                    <Route path="/pga" element={<Forwordpage />} />
                    <Route path="/demo-video" element={<VideoComponent />} />   
                    <Route path="/coupons" element={<UrlBased />} />
                    <Route path="/empty" element={<EmptyLocation />} />
                    <Route path="/demo-library" element={<DemoSideBar />} />
                    <Route path="/Dealoftheday" element={<Dealoftheday />} />
                    <Route path="/single-blog" element={<SingleBlogPage />} />
                    <Route path="/resubscribe" element={<ResubscribePage />} />
                    <Route path="/unsubscribe" element={<UnsubscribePage /> }  />
                    <Route path="/adminportal" element={<AdminPortal /> }  />
                    
                    <Route path="/samplerepaa" element={<SampleRepaaComp />} />   
                    <Route path="/sampledashboard" element={ <SampleDashboardComp />  } /> 


                    <Route path="/modifyuser" element={admin_authenticated ? <ModifyUserComp /> : <Navigate to="/admin" />} />
                    <Route path="/manageusers" element={admin_authenticated ? <ManageUsersComp /> : <Navigate to="/admin" />}  /> 
                    <Route path="/userform" element={admin_authenticated ? <UserFormComp /> : <Navigate to="/admin" />} />
                    <Route path="/table" element={admin_authenticated ? <Dashboard_2 /> : <Navigate to="/admin" />}  />
                    <Route path="/report" element={admin_authenticated ? <Dashboard /> : <Navigate to="/admin" />} />
                    <Route path="/dashboardadmin" element={admin_authenticated ?  <DashboardAdmin /> : <Navigate to="/admin" />} />    
                    <Route path="/repaaadmin" element={admin_authenticated ?  <RepaaAdmin />  : <Navigate to="/admin" />} />  
                    <Route path="/assign" element={admin_authenticated ?  <AssignPage />  : <Navigate to="/agency-login" />} /> 
                    <Route path="/useractivity" element={admin_authenticated ?  <UserActivityPage />  : <Navigate to="/agency-login" />} /> 
                    <Route path="/brandrepaa" element={isUserAuthenticated ?  <BrandRepaa />  : <Navigate to="/agency-login" />} />    
                    {/* <Route path="/samplerepaa" element={isUserAuthenticated ?  <SampleRepaaComp />  : <Navigate to="/login" />} />      
                    <Route path="/sampledashboard" element={isUserAuthenticated ?  <SampleDashboardComp />  : <Navigate to="/login" />} />  */}
                    <Route path="/samplerepaaadmin" element={admin_authenticated ?  <SampleRepaaAdmin />  : <Navigate to="/agency-login" />} />      
                    <Route path="/sampledashboardadmin" element={admin_authenticated ?  <SampleDashboardAdmin />  : <Navigate to="/agency-login" />} /> 
                    <Route path="/useroptions" element={isUserAuthenticated ?  <UserOptions />  : <Navigate to="/agency-login" />} />      
                    <Route path="/branddashboard" element={isUserAuthenticated ?  <BrandDashboard /> : <Navigate to="/agency-login" />} />         




                    {/* <Route path="/modifyuser" element={<ModifyUserComp />} />
                    <Route path="/manageusers" element={<ManageUsersComp />} /> 
                    <Route path="/userform" element={<UserFormComp />} />
                    <Route path="/table" element={<TableComp />} />
                    <Route path="/report" element={<Dashboard />} />
                    <Route path="/dashboardadmin" element={<DashboardAdmin />} />    
                    <Route path="/branddashboard" element={<BrandDashboard />} />  
                    <Route path="/repaaadmin" element={<RepaaAdmin />} />    
                    <Route path="/brandrepaa" element={<BrandRepaa />} />  
                    <Route path="/samplerepaa" element={<SampleRepaaComp />} /> 
                    <Route path="/samplerepaaadmin" element={<SampleRepaaAdmin />} />   
                    <Route path="/sampledashboardadmin" element={<SampleDashboardAdmin />} />   
                    <Route path="/sampledashboard" element={<SampleDashboardComp />} />   
                    <Route path="/assign" element={<AssignPage />} />     
                    <Route path="/useractivity" element={<UserActivityPage />} />  
                    <Route path="/useroptions" element={<UserOptions />} />      */}

                    {/* <Route path="/newhome" element={<Homepage />} />    */}

                              

                </Routes>                
            </Router>
        </div>
    );
};

export default App;