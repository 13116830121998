// SupersetEmbeddedDashboard.js
import React, { useEffect } from 'react';

const SupersetEmbeddedDashboard = ({ isOpen }) => {
    useEffect(() => {
        // Load Superset Embedded SDK script dynamically
        const script = document.createElement('script');
        script.src = 'https://unpkg.com/@superset-ui/embedded-sdk';
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
            const fetchGuestToken = () =>
                'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjp7ImZpcnN0X25hbWUiOiJTb2Z0cm9uaWNsYWJzIiwibGFzdF9uYW1lIjoiQWRtaW4iLCJ1c2VybmFtZSI6ImFkbWluIn0sInJlc291cmNlcyI6W3siaWQiOiIwNTI5ZTliZi0yODAyLTRmMmUtOGNmMS1hZmFlMGEyOTllY2QiLCJ0eXBlIjoiZGFzaGJvYXJkIn1dLCJybHNfcnVsZXMiOltdLCJpYXQiOjE3MTU2ODc4MzEuNzI5NjQzLCJleHAiOjE3NDcyMjM4MzEuNzI5NjQzLCJhdWQiOiJodHRwOi8vc3VwZXJzZXQ6ODA4OC8iLCJ0eXBlIjoiZ3Vlc3QifQ.D4JXHnDH9ze4xMsfAmOX-j80Bo8RHkKxRGH4sRIRuzs'; // guest_token

            // Embed dashboard once the Superset Embedded SDK is loaded
            window.supersetEmbeddedSdk.embedDashboard({
                id: '0529e9bf-2802-4f2e-8cf1-afae0a299ecd',
                supersetDomain: 'https://ddp.softroniclabs.com',
                mountPoint: document.getElementById('container'),
                fetchGuestToken: fetchGuestToken,
                dashboardUiConfig: {
                    filters: {
                        expanded: false
                    }
                }
            });

            // Additional code to modify iframe dimensions
            const dashboardElement = document.getElementById('container');
            if (dashboardElement) {
                const iframe = dashboardElement.querySelector('iframe');
                if (iframe) {
                    iframe.style.width = '100%'; // Set the width to 100% by default
                    iframe.style.height = '1960px'; // Set the height as needed
                }
            }
        };

        // Cleanup function
        return () => {
            // Remove the dynamically added script when component unmounts
            document.body.removeChild(script);
        };
    }, []);

    return <div id="container" />;
};

export default SupersetEmbeddedDashboard;
