import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Title1 = styled.h3`
    margin-bottom: 10px;
`;

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 4 columns */
    gap: 20px; /* Gap between grid items */
    justify-items: center; /* Center items horizontally */
    align-items: center; /* Center items vertically */
    margin-left: 20px; /* Left margin */
    margin-right: 20px; /* Right margin */
    margin-bottom: 20px; /* Right margin */

    @media screen and (min-width: 768px) {
        /* Add your media query styles here */
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* 4 columns */
    gap: 20px; /* Gap between grid items */
    justify-items: center; /* Center items horizontally */
    align-items: center; /* Center items vertically */
    margin-top: -9%;
    margin-left: 20px; /* Left margin */
    margin-right: 20px; /* Right margin */
    margin-bottom: 20px; /* Right margin */
        
    }
`;

const ItemContainer = styled.div`
    text-align: center; /* Center all images */
`;

const Title = styled.h3`
    margin-bottom: 10px;
`;

const ImageLink = styled.a`
    display: block;
    width: 100%;

    img {
        width: 100%;
        height: auto;
        border: 2px solid #000; /* Add border to the image */
        border-radius: 0px; /* Optional: Add border radius for rounded corners */
    }
`;


function PGAstore() {
    const [content, setContent] = useState([]);

    useEffect(() => {
        fetch('https://prod.loclshop.com/api/pga-imp-iframe')
            .then(response => response.json())
            .then(data => {
                setContent(data.content);
            })
            .catch(error => console.error('Error:', error));
    }, []);

    return (
        <Container>
            <Title1 className="text-center title" style={{ fontSize: '29px' }}>Welcome to PGA Store</Title1>
            <GridContainer>
            {content.map(item => (
                <ItemContainer key={item.id}>
                    <Title>
                        {/* Provide content inside the anchor or remove the anchor */}
                        <a href={item.link.url}>{item.title}</a>
                    </Title>
                    <ImageLink href={item.link.url} target="_blank">
                        <img src={item.image.src} alt={item.image.alt} />
                    </ImageLink>
                </ItemContainer>
            ))}
            </GridContainer>
        </Container>
    );
}

export default PGAstore;
