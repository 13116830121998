import React, { useState, useEffect, useRef } from 'react';
import './landing_page.css'; // Import CSS file for styling
import { motion } from 'framer-motion';
import $ from 'jquery'; // Import jQuery
import 'select2'; // Import Select2 libraryimport Checkbox from '@mui/joy/Checkbox';
import styled from 'styled-components'; // Import styled-components   
import Checkbox from '@mui/joy/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import reverseGeocode from '../components/geoencoding'
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import useAnalyticsEventTracker from '../components/useAnalyticsEventTracker';
import { logEvent } from '../components/analytics';
import EmptyLocation from './empty_location';
import ShareButton from '../components/ShareButton.js';  // Import ShareButton

const StyledInput = styled.input`
  &::placeholder {
    color: red;
    font-size: 14px;
  }
`;

export default function UrlBased () {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [imagesData, setImagesData] = useState([]);
    const [isReturningUser, setIsReturningUser] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const selectedSms = useState(null);
    const selectedName = useState(null);
    const [smsSent, setSmsSent] = useState(false); 
    const [showPhoneNumberPopup, setShowPhoneNumberPopup] = useState(false);
    const [openSecond, setOpenSecond] = useState(false);
    const [showError, setShowError] = useState(false);
    const [selectedImages, setSelectedImages] = useState([]);
    const [showTenantAds, setShowTenantAds] = useState(false); 
    const [selectedCategory, setSelectedCategory] = useState('');
    const filteredImages = selectedCategory ? imagesData.filter(image => image.category === selectedCategory) : imagesData;
    const [userLocation, setUserLocation] = useState(null);
    const [userAddress, setUserAddress] = useState(null); // New state for user address
    const [selectAll , setselectAll] = useState(false);
    const selectRef = useRef(null); // Create a ref for the select element
    const newRef = React.useRef(null);
    const smsnewRef = useRef(null);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const brandName = 'pga';
    const [brandData, setBrandData] = useState(null);
    const [selectedImageCount, setSelectedImageCount] = useState(0);
    const defUrl = process.env.REACT_APP_API_URL
    const [locations, setLocations] = useState([]); // State to hold all locations
    const [matchMessage, setMatchMessage] = useState(''); // State to hold match message
    const [isTracking, setIsTracking] = useState(true); // State to track if location 
    let smsSent1 = false; // Initialize flag to track whether SMS has been sent
    const [selectedCountry, setSelectedCountry] = useState('US');
    const size = useWindowSize();
    const currentPageUrl = window.location.href;
    const [isRed, setIsRed] = useState(false); 


    useEffect(() => {              
        //window.gtag('config', 'AW-16449170732');
        //window.gtag('event', 'conversion', {'send_to': 'AW-16449170732/LwU1CKDy-KUZEKzayaM'});
        $(selectRef.current).select2();
        fetchImagesData(); // Fetch images data when the component mounts
        checkReturningUser(); // Check if the user is returning
        const storedPhoneNumber = localStorage.getItem('phoneNumber');
        if (storedPhoneNumber) {
            setShowPhoneNumberPopup(false); // Set showPhoneNumberPopup to false if the phone number is already in local storage
        } else {
            setShowPhoneNumberPopup(true); // Set showPhoneNumberPopup to true if the phone number is not in local storage
        }
        if ("geolocation" in navigator) {
            // Get user's current location initially
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setUserLocation({ latitude, longitude });
                    reverseGeocode(latitude, longitude);
                },
                (error) => {
                    console.error('Error getting user location:', error);
                }
            );
            // Get user's current location every 10 seconds
            const locationInterval = setInterval(async () => {
                navigator.geolocation.getCurrentPosition(
                    async (position) => {
                        const { latitude, longitude } = position.coords;
                        setUserLocation({ latitude, longitude });
                        reverseGeocode(latitude, longitude);
            
                        try {
                            // Fetch location data containing brand information
                            const response = await fetch('https://prod.loclshop.com/api/query-data');
                            if (!response.ok) {
                                throw new Error('Failed to fetch location data');
                            }
                            const locationData = await response.json();
            
                            if ("geolocation" in navigator) {
                                navigator.geolocation.getCurrentPosition(async position => {
                                    const userLat = position.coords.latitude;
                                    const userLong = position.coords.longitude;
                                    let concatenatedSMS = ''; // Initialize an empty string to accumulate SMS messages
            
                                    // Iterate through brands to find a match within 1 km radius
                                    locationData.brands.forEach(brand => {
                                        brand.offers.forEach((offer) => {
                                            const { sms } = offer;
                                            const { lat, long } = brand;
                                            const distance = getDistance(userLat, userLong, lat, long);
                                            if (distance <= 1) {
                                                // Concatenate the SMS message
                                                concatenatedSMS += sms + '\n';
                                            }
                                        });
                                    });
            
                                    // Send the concatenated SMS message
                                    if (concatenatedSMS !== '') {
                                        sendSMS(storedPhoneNumber, concatenatedSMS, userLat, userLong); // Pass latitude and longitude
                                        smsSent1 = true; // Update flag to indicate SMS has been sent

                                    }
                                });
                            } else {
                                console.error('Geolocation is not supported.');
                            }
                        } catch (error) {
                            console.error('Error fetching or processing location data:', error);
                        }
                    },
                    (error) => {
                        console.error('Error getting user location:', error);
                    }
                );
            }, 60000); // 60 seconds interval
            
            
            return () => {
                clearInterval(locationInterval);
            };
        } else {
            console.error("Geolocation is not supported by this browser.");
        }
        document.addEventListener("mousedown", handleOutsideClick1 || handleOutsideClick);
        return () => {
            $(selectRef.current).select2('destroy');
            document.removeEventListener("mousedown", handleOutsideClick1 || handleOutsideClick);
        };
    }, []);

    const containerStyle = {
        display: 'flex',
        justifyContent: 'space-between', // This will push the button to the right
        alignItems: 'center',
        padding: '20px',
      };


    const getDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in kilometers
    return d;
    };

    const gaEventTracker = useAnalyticsEventTracker('Unlock Exclusive Offers!');

    

    // function gtag_report_conversion(url) {
    //     var callback = function () {
    //       if (typeof(url) !== 'undefined') {
    //         window.location = url;
    //       }
    //     };
      
    //     // Replace 'gtag' with your Google Tag Manager function or library
    //     $`gtag`('event', 'conversion', {
    //       'send_to': 'GT-K54JPS56',
    //       'event_callback': callback
    //     });
      
    //     return false;
    //   }


    const buttonClick = () => {
        window.gtag('config', 'AW-16449170732');
        window.gtag('event', 'conversion', {'send_to': 'AW-16449170732/LwU1CKDy-KUZEKzayaM9'});
    }

    const buttonClick1 = () => {
            let currentPageUrl = window.location.href;
            window.gtag('config', 'AW-16449170732');
            window.gtag('event', 'conversion', {'send_to': 'AW-16449170732/LwU1CKDy-KUZEKzayaM9'});
            gtag_report_conversion(currentPageUrl)
    }

    
    const deg2rad = deg => {
        return deg * (Math.PI / 180);
    };
  
    const fetchData = async () => {
        try {
            const response = await fetch('https://prod.loclshop.com/api/query-data');
            const data = await response.json();
            if (data && data.brands) {
                // Get user's current location
                if ("geolocation" in navigator) {
                    navigator.geolocation.getCurrentPosition(async position => {
                        const userLat = position.coords.latitude;
                        const userLong = position.coords.longitude;
    
                        // Iterate through brands to find a match within 1 km radius
                        const matchedBrand = data.brands.find(brand =>
                            getDistance(userLat, userLong, brand.lat, brand.long) <= 1
                        );
    
                        if (matchedBrand) {
                            setBrandData(matchedBrand);
                        } else {
                            setBrandData(null);                        }
                    });
                } else {
                    console.error('Geolocation is not supported.');
                }
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    
    useEffect(() => {
        fetchData();
    }, [brandName]);
  

    useEffect(() => {
            
        // Logic to set default checked state of checkbox
        if (selectAll) {
            setSelectedImages([]);
        } else {
                // Get user's current location
                if ("geolocation" in navigator) {
                    navigator.geolocation.getCurrentPosition(async position => {
                        const userLat = position.coords.latitude;
                        const userLong = position.coords.longitude;

                        const brandImagesData = imagesData.find(brand => getDistance(userLat, userLong, brand.latitude, brand.longitude) <= 1 );

                        
                        if (brandImagesData) {
                            setSelectedImages(brandImagesData.offers);
                            setSelectedImageCount(brandImagesData.offers.length +1 );
                        }
                    });
                } else {
                    console.error('Geolocation is not supported.');
                }
            }
    }, [selectAll]); // Run effect whenever selectAll changes

    // Function to check if current location matches hardcoded coordinates within a tolerance

    const checkMatch = (smslat, smslong, userlat, userlong , phonenumber , sms , userlat1 , userlong1 ) => {
        const latDiff = Math.abs(smslat - userlat);
        const lonDiff = Math.abs(smslong - userlong);
        if (latDiff <= 300 && lonDiff <= 300 && !smsSent1) {
            // Send SMS only if within tolerance and SMS has not been sent yet
            sendSMS(phonenumber, sms, userlat1, userlong1);
            setMatchMessage('Match found!');
            setIsTracking(false); // Stop tracking once match is found
        } else {
            setMatchMessage('No match within tolerance');
        }
};

    const StyledFooterButton = styled.button`
        position: absolute;
        bottom: 10px;
        left: 18%;
        margin-top: 4%;
        padding: 10px 20px;
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: #0056b3;
        }

        @media screen and (min-width: 768px){
            left: 42%; // Adjusted left position for screens <= 768px width
        }

        @media screen and (min-width: 431px) and (max-width: 768px) {
            left: 35%; // Adjusted left position for screens <= 768px width
        }
    `;

    const SFooter = () => (
        <div className='stickyfooter'>
            <StyledFooterButton className='bfooter' type="button" onClick={postData}>
                {selectedImages.length === imagesData.length +1 ? 'Get All Coupon Codes!' : 'Get Selected Coupon Codes!'}
            </StyledFooterButton>  
        </div>
    );

    const handleOutsideClick1 = (e) => {
        if (newRef.current && !newRef.current.contains(e.target)) {
            setOpenSecond(false);
        }
      };
    
    const handleOutsideClick = (e) => {
        setOpenSecond(false);
        const storedPhoneNumber = localStorage.getItem('phoneNumber');
        if (newRef.current && !newRef.current.contains(e.target)) {
            setSelectedImage(null);
        }
        if (storedPhoneNumber && smsnewRef.current && !smsnewRef.current.contains(e.target)) {
            setSelectedImage(null);
        }
      };

    // Function to fetch images data from the server
    const fetchImagesData = async () => {        
        try {
            const response = await fetch('https://prod.loclshop.com/api/query-data', {
                mode: 'cors' // Add the mode option here
            });
            if (!response.ok) {
                throw new Error('Failed to fetch images data');
            }
            const data = await response.json();
            if (data && data.brands) {
                const imagesData = data.brands.map(brand => ({
                    brandName: brand.BrandName,
                    latitude: parseFloat(brand.lat),  // Ensure latitude is a number
                    longitude: parseFloat(brand.long), // Ensure longitude is a number
                    offers: brand.offers.map(offer => ({
                        img_url: offer.img_url,
                        sms: offer.sms,
                        name: offer.name,
                        category: offer.category,
                        text: offer.text,
                        affiliate_url: offer.affiliate_url,
                        affiliate_image_url: offer.affiliate_image_url
                    }))
                }));
                setImagesData(imagesData);
            }
        } catch (error) {
            console.error('Error fetching images data:', error);
        }
    };
    

    const savePhoneNumber = () => {
        if (phoneNumber.trim() !== '') {
            const countryCodes = {
                US: '+1',
                IN: '+91',
                UK: '+44',
                CA: '+1'  // Assuming you want to use the same prefix as the US for Canada
            };
            
            const formattedPhoneNumber = phoneNumber.startsWith(countryCodes[selectedCountry]) 
                ? phoneNumber 
                : countryCodes[selectedCountry] + phoneNumber.trim();
    
            // Check if formattedPhoneNumber is not just the country code
            if (formattedPhoneNumber.length !== countryCodes[selectedCountry].length + 1) {
                localStorage.setItem('phoneNumber', formattedPhoneNumber);
            }
            const currentPageUrl = window.location.href;
            window.gtag('config', 'AW-16449170732');
            window.gtag('event', 'conversion', {'send_to': 'AW-16449170732/LwU1CKDy-KUZEKzayaM9'});
            gtag_report_conversion(currentPageUrl)
            setShowPhoneNumberPopup(false);            
            // Your analytics tracking code            
        } else {
            // Show error message
            setShowError(true);
            // Hide error message after 3 seconds
            setTimeout(() => {
                setShowError(false);
            }, 5000);
        }
    };
    

    const savePhoneNumber1 = () => {
        // Mapping of country codes to phone number prefixes
        if (phoneNumber.trim() !== '') {
            const countryCodes = {
                US: '+1',
                IN: '+91',
                UK: '+44',
                CA: '+1'  // Assuming you want to use the same prefix as the US for Canada
            };
        
            const formattedPhoneNumber = phoneNumber && phoneNumber.startsWith(countryCodes[selectedCountry]) 
                ? phoneNumber 
                : countryCodes[selectedCountry] + (phoneNumber ? phoneNumber.trim() : '');
        
            // Check if formattedPhoneNumber is not just the country code
            if (formattedPhoneNumber.length !== countryCodes[selectedCountry].length + 1) {
                localStorage.setItem('phoneNumber', formattedPhoneNumber);
                setPhoneNumber(formattedPhoneNumber);
            }
            const currentPageUrl = window.location.href;
            const offers = brandData.offers;
            let url = `For more Offers and Coupon codes visit ${currentPageUrl}`;
            // Initialize an empty string to hold the concatenated SMS messages
            let concatenatedSms = '';
            // Loop through the first three offers and concatenate the SMS messages with a newline in between
            for (let i = 0; i < Math.min(3, offers.length); i++) {
                if (i > 0) concatenatedSms += '\n\n'; // Add a newline character before each SMS, except for the first one
                concatenatedSms += offers[i].sms;
            }
            // Append the URL on a new line after all SMS messages
            concatenatedSms += `\n\n${url}`;
            localStorage.setItem('concatenatedSms1', concatenatedSms); // Store concatenated SMS in local storage

            if (phoneNumber.length !== 10) {
                setShowError(true);
            } else {
                if ("geolocation" in navigator) {
                    const currentPageUrl = window.location.href;
                    // Get user's current location
                    navigator.geolocation.getCurrentPosition(
                        (position) => {
                            const { latitude, longitude } = position.coords;
                            const userLocation = { latitude, longitude };
                            
                            reverseGeocode(latitude, longitude)
                                .then(address => {
                                    setUserAddress(address);
                                    axios.post('https://prod.loclshop.com/api/save-phone-number', {
                                        phoneNumber: formattedPhoneNumber,
                                        latitude:  latitude, 
                                        longitude: longitude,
                                        address : address,
                                        pageUrl: currentPageUrl  // Including the current page URL

                                    })
                                    .then(response => {

                                        ReactGA.event({
                                            category: 'User',
                                            action: 'Clicked a button'
                                        });
                                    
                                        // Retrieve the concatenated SMS from local storage
                                        const concatenatedSms1 = localStorage.getItem('concatenatedSms1');
                                        if (concatenatedSms1) {
                                            // Proceed to send SMS if concatenatedSms exists
                                            //sendSMS2(formattedPhoneNumber, concatenatedSms1, latitude, longitude);
                                            console.log('.')
                                        } else {
                                            console.error('Failed to retrieve SMS content from local storage.');
                                        }
                                    
                                        setShowPhoneNumberPopup(false);
                                        localStorage.removeItem('concatenatedSms1');

                                    })
                                    .catch(error => {
                                        console.error('Error saving phone number:', error);
                                    });
                                })
                                .catch(error => {
                                    console.error('Error getting address:', error);
                                });
                        },
                        (error) => {
                            console.error('Error getting user location:', error);
                        }
                    );
                } else {
                    console.error("Geolocation is not supported by this browser.");
                }
            }
        } else {
            // Show error message
            setShowError(true);
            // Hide error message after 3 seconds
            setTimeout(() => {
                setShowError(false);
            }, 5000);
        }
    };

    const sendSMS2 = (phoneNumber, smsValues, latitude, longitude) => {
        // Mapping of country codes to phone number prefixes
        if (!phoneNumber) {
            return;
        }
        // Reverse geocode to get the address
        reverseGeocode(latitude, longitude)
            .then(address => {
                // Concatenate all SMS messages into a single body
                const messageBody = smsValues;
    
                // Send the concatenated message as one SMS
                return fetch('https://prod.loclshop.com/api/loclsms2', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ 
                        phoneNumber: phoneNumber, 
                        adname: messageBody,
                        address: address, // Include user's address
                        latitude: latitude, // Include latitude
                        longitude: longitude // Include longitude
                    }),
                });
            })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to send SMS');
                }
                setSmsSent(true);
                setTimeout(() => {
                    setSmsSent(false);
                }, 3000);
                // Clear the input field if the response is OK
            })
            .catch((error) => {
                console.error('Error sending SMS:', error);
            });
    };

    async function afterSmsSent(latitude, longitude, phoneNumber) {
        try {
            const address = await reverseGeocode(latitude, longitude);
            await axios.post('https://prod.loclshop.com/api/save-phone-number', {
                phoneNumber: phoneNumber,
                latitude: latitude,
                longitude: longitude,
                address: address,
                pageUrl: currentPageUrl
            });
        } catch (error) {
            console.error('Error in reverse geocoding or saving the data:', error);
        }
    }
    

    // Function to check if the user is returning
    const checkReturningUser = async () => {
        const storedPhoneNumber = localStorage.getItem('phoneNumber');
        const returnCount = parseInt(localStorage.getItem('returnCount') || '0', 10); // Retrieve and parse the return count or default to 0
        if (storedPhoneNumber) {
            const currentPageUrl = window.location.href;
    
            try {
                const response = await fetch('https://prod.loclshop.com/api/query-data');
                const data = await response.json();
                if (data && data.brands) {
                    // Get user's current location
                    if ("geolocation" in navigator) {
                        navigator.geolocation.getCurrentPosition(async position => {
                            const userLocation = {
                                latitude: position.coords.latitude,
                                longitude: position.coords.longitude
                            };
    
                            // Find a brand match within a specific radius, e.g., 1 km
                            const matchedBrand = data.brands.find(brand =>
                                getDistance(userLocation.latitude, userLocation.longitude, brand.lat, brand.long) <= 1
                            );
    
                            if (matchedBrand) {
                                // Assuming matchedBrand offers structure is similar to brandData in your snippet
                                const offers = matchedBrand.offers;
                                let url = `For more Offers and Coupon codes visit ${currentPageUrl}`;
                                let concatenatedSms = '';
    
                                // Loop through the first three offers and concatenate the SMS messages
                                for (let i = 0; i < Math.min(3, offers.length); i++) {
                                    if (i > 0) concatenatedSms += '\n\n';
                                    concatenatedSms += offers[i].sms;
                                }
    
                                // Append the URL on a new line after all SMS messages
                                concatenatedSms += `\n\n${url}`;
    
                                // Send SMS if concatenatedSms is not empty
                                    setTimeout(async () => {
                                        await sendSMS2(storedPhoneNumber, concatenatedSms, userLocation.latitude, userLocation.longitude);
                                        afterSmsSent(userLocation.latitude, userLocation.longitude, storedPhoneNumber);
                                    }, 1000);
                                
                    

                                setBrandData(matchedBrand);
                            } else {
                                setBrandData(null);
                            }
                        }, error => {
                            console.error('Error getting location:', error.message);
                        });
                    } else {
                        console.error('Geolocation is not supported by this browser.');
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
    
            setIsReturningUser(true);
            setPhoneNumber(storedPhoneNumber);
            setShowPhoneNumberPopup(false); // Adjust based on your app's requirements
        } else {
            setShowPhoneNumberPopup(true);
        }
    };

    // Event handler for phone number input change
    const handlePhoneChange = (event) => {
        setPhoneNumber(event.target.value);
    };

    const postData = () => {
        let smsValues = []; // Initialize an array to store SMS values
        const url = `For more Offers and Coupon codes visit ${currentPageUrl}`; // Define the URL
    
        // Function to append the URL to each SMS value
        const appendUrlToSmsValues = (values) => values.map(value => `${value} ${url}`);
    
        // If none of the images are selected and neither "Select All" nor "Tenant Ads" checkbox is checked
        if (selectedImages.length === 0 && !showTenantAds && !selectedCategory) {
            // Fetch SMS values from the URL
            fetchImagesData().then(() => {
                // Extract SMS values from imagesData
                smsValues = imagesData.map((imageItem) => imageItem.sms);
                // Append the URL to each SMS value
                const updatedSmsValues = appendUrlToSmsValues(smsValues);
                // Send SMS with fetched SMS values and user's location
                sendSMS(phoneNumber, updatedSmsValues, userLocation.latitude, userLocation.longitude);
            }).catch((error) => {
                console.error('Error fetching images data:', error);
            });
        } else {
            // Extract SMS values based on the selected category and tenant ads checkbox
            let selectedImagesSmsValues;
            if ((showTenantAds || selectedCategory) && selectedImages.length > 0) {
                // Logic to extract SMS values based on selected category or tenant ads
                if (selectedCategory) {
                    // Filter selected images based on the selected category
                    const selectedCategoryImages = selectedImages.filter(imageItem => imageItem.category === selectedCategory);
                    // Extract SMS values from selected images in the specified category
                    selectedImagesSmsValues = selectedCategoryImages.map(imageItem => imageItem.sms);
                } else {
                    // Extract SMS values from selected tenant ads if "Tenant Ads" checkbox is checked
                    const selectedTenantAds = selectedImages.filter(imageItem => imageItem.type === "tenant");
                    selectedImagesSmsValues = selectedTenantAds.map(imageItem => imageItem.sms);
                }
            } else {
                // Extract SMS values from all selected images if no category is selected or "Tenant Ads" checkbox is not checked
                selectedImagesSmsValues = selectedImages.map(imageItem => imageItem.sms);
            }
            
            // Append the URL to each SMS value
            const updatedSmsValues = appendUrlToSmsValues(selectedImagesSmsValues);
    
            // Implement your post data logic here
            const storedPhoneNumber = localStorage.getItem('phoneNumber');
            if (storedPhoneNumber === phoneNumber) {
                sendSMS(phoneNumber, updatedSmsValues, userLocation.latitude, userLocation.longitude); // Pass selected images' SMS values to sendSMS function               
            } else {
                localStorage.setItem('phoneNumber', phoneNumber);
                sendSMS(phoneNumber, updatedSmsValues, userLocation.latitude, userLocation.longitude); // Pass selected images' SMS values to sendSMS function                
            }
        }
    };
    

    const shouldShowFooter = () => {
        return selectedImage || selectedImages.length > 0; // Footer should appear if any image is selected or if any image is selected through "Select All" checkbox
    };

    // Function to send SMS
    const sendSMS = (phoneNumber, smsValues, latitude, longitude) => {
        // Mapping of country codes to phone number prefixes
        if (!phoneNumber) {
            return;
        }
        // Reverse geocode to get the address
        reverseGeocode(latitude, longitude)
            .then(address => {
                // Concatenate all SMS messages into a single body
                const messageBody = Array.isArray(smsValues) ? smsValues.join('\n\n') : smsValues;
    
                // Send the concatenated message as one SMS
                return fetch('https://prod.loclshop.com/api/loclsms2', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ 
                        phoneNumber: phoneNumber, 
                        adname: messageBody,
                        address: address, // Include user's address
                        latitude: latitude, // Include latitude
                        longitude: longitude // Include longitude
                    }),
                });
            })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to send SMS');
                }
                setSmsSent(true);
                setTimeout(() => {
                    setSmsSent(false);
                }, 3000);
                // Clear the input field if the response is OK
            })
            .catch((error) => {
                console.error('Error sending SMS:', error);
            });
    };
    
    
    const handleImageClick = (imageItem) => {
        const index = selectedImages.findIndex(item => item.img_url === imageItem.img_url);
        if (index === -1) {
            setSelectedImages(prevState => [...prevState, imageItem]);
        } else {
            setSelectedImages(prevState => prevState.filter(item => item.img_url !== imageItem.img_url));
        }
    };

    // Function to close the popup
    const closePopup = () => {
        setSelectedImage(null);
        setSmsSent(true); 
        setShowPhoneNumberPopup(false);
        
    };


    const toggleSelectAll = () => {
        // Toggle the selectAll state
        setselectAll(prevSelectAll => !prevSelectAll);
    };
    
    
    
    const blinkBorderKeyframes = `
        @keyframes zoomInOut {
            0% {
                transform: scale(1.2);
                
            }
            50% {
                transform: scale(1.5);
                
            }
            100% {
                transform: scale(1.2);
                
            }
        }

    `;

    const Image_screen = `
        @keyframes zoomInOut {
            0% {
                transform: scale(1.2);
                
            }
            50% {
                transform: scale(1.5);
                
            }
            100% {
                transform: scale(1.2);
                
            }
        }

    `;

    const renderImages = () => {
        let filteredImages = [];
        if (brandData && brandData.offers) {
            // Check if a category is selected; if not, display all images
            if (selectedCategory) {
                // Filter images based on the selected category
                filteredImages = brandData.offers.filter(offer => offer.category === selectedCategory);
            } else {
                // If no category is selected, do not filter; display all offers
                filteredImages = brandData.offers;
            }
        }
        
        return filteredImages.map((imageItem, index) => (
            <div className="col-lg-4 col-md-6 col-sm-12" key={index}>
                <div className="deal" onClick={() => handleImageClick(imageItem)}>
                <img className='bimage'
                        src={imageItem.img_url} 
                        alt={imageItem.sms} 
                        style={{ 
                            borderRadius: '20px', 
                            paddingTop: '10px', 
                            borderColor: '#C6C5C5', 
                            boxShadow: '0 16px 24px rgba(0, 0, 0, 0.2)',
                            height: '470px', 
                            width: '95%',
                        }} 
                    />
                    <Checkbox
                        checked={selectedImages.some(item => item.img_url === imageItem.img_url)}
                        onChange={() => handleImageClick(imageItem)}
                        style={{
                            position: 'absolute',
                            bottom: '36px',
                            left: '39px',
                            borderRadius: '5px',
                            transform: 'scale(2)',
                            animation: 'zoomInOut 0.6s infinite alternate',
                            border: '1px solid black'
                        }}
                    />
                </div>
            </div>
        ));
    };
    
    const handleButtonClick = () => {
        ReactGA.event({
          category: 'User',
          action: 'Clicked a button'
        });
      };
    

    const sleep = (duration) => {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve();
          }, duration);
        });
      }

      const RenderCategoryDropdown = ({ brandData, selectedCategory, setSelectedCategory }) => {
        // Extract categories based on the selected brand
        const categories = brandData ? [...new Set(brandData.offers.map(offer => offer.category))] : [];
    
        const [open, setOpen] = useState(false);
        const [options, setOptions] = useState([]);
        const loading = open && options.length === 0;
    
        useEffect(() => {
            let active = true;
    
            if (!loading) {
                return undefined;
            }
    
            (async () => {
                await sleep(1e3); // For demo purposes.
    
                if (active) {
                    setOptions([...categories]);
                }
            })();
    
            return () => {
                active = false;
            };
        }, [loading, categories]);
    
        useEffect(() => {
            if (!open) {
                setOptions([]);
            }
        }, [open]);
    
        // Filter images based on selected category
        const filteredImages = brandData ? brandData.offers.filter(offer => !selectedCategory || offer.category === selectedCategory) : [];
    
        return (
            <div>
                <Autocomplete
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    getOptionLabel={(option) => option}
                    options={categories} // Make sure this is the array of categories extracted from the offers
                    loading={loading}
                    onChange={handleCategoryChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Select Category"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />

                <div>
                        </div>
                </div>
        );
    }
    

    const StyledSelectAllWrapper = styled.div`
    position: absolute;
    top: 90%;
    right: 9%;
    display: flex;
    align-items: center;
    z-index: 999; /* Add a high z-index value */

    @media screen and (min-width: 768px) {
        top: 85%; // Adjusted top position for screens <= 768px width
        right: 0%; // Adjusted right position for screens <= 768px width
    }
`;

    const StyledCheckboxLabel = styled.label`
        margin-top: 5px;
        text-align: right;

        @media (max-width: 767px) {
            text-align: center;
        }
        @media (max-width: 400px) {
            text-align: left;
        }
    `;

    // Dropdown change handler
    const handleCategoryChange = (event, value) => {
        setSelectedCategory(value);
    };

    function useWindowSize() {
        const [size, setSize] = useState({ width: window.innerWidth, height: window.innerHeight });

        useEffect(() => {
            const handleResize = () => {
                setSize({
                    width: window.innerWidth,
                    height: window.innerHeight
                });
            };

            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }, []);

        return size;
    }


    function getYValue(width, height) {
        // Special condition for larger screens
        if (width > 700 && height > 900) {
            return "0vh";
        }
    
        // Define ranges and their respective y-values
        const ranges = [
            { width: 375, height: 812, y: "-21.5vh" },
            { width: 375, height: 667, y: "-12.5vh" },
            { width: 360, height: 740, y: "-19vh" },
            { width: 411, height: 731, y: "-17vh" },
            { width: 430, height: 932, y: "-25vh" },
            { width: 414, height: 896, y: "-23vh" },
            { width: 414, height: 915, y: "-25vh" },
            { width: 540, height: 720, y: "-13vh" },
            { width: 344, height: 882, y: "-24.5vh" },
            { width: 360, height: 640, y: "-12.5vh" },
            { width: 320, height: 568, y: "-4vh" },
            // Note: The last two entries are duplicates and can be removed
        ];
    
        // Check each range for a match within ±5 of the specified dimensions
        for (let range of ranges) {
            if (Math.abs(width - range.width) <= 5 && Math.abs(height - range.height) <= 5) {
                return range.y;
            }
        }
    
        // Default value if no range matches
        return "-21.5vh";
    }
    
    
        const dropIn = {
            hidden: {
            y: "-150vh",
            opacity: 0,
            },
            visible: {
                y: getYValue(size.width, size.height),        
                opacity: 1,
                transition: {
                duration: 1,
                type: "spring",
                damping: 20,
                stiffness: 500,
            },
            },
            exit: {
            y: "100vh",
            opacity: 0,
            },
        };

      function gtag_report_conversion(url) {
        var callback = function () {
          if (typeof(url) !== 'undefined') {
            window.location = url;
          }
        };
      
        window.gtag('event', 'conversion', {
          'send_to': 'AW-16449170732/LwU1CKDy-KUZEKzayaM9',
          'event_callback': callback
        });
      
        return false;
      }

      const handleCountryChange = (event) => {
        setSelectedCountry(event.target.value);
    };

    

    return (
        
            <div>
                <ShareButton />  {/* Include ShareButton here */}
            {!brandData && (
                <div style={{ height: '100%'}}>
                    <EmptyLocation />
                </div>
            )}
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        {brandData && (
                            <h1 className="text-center title" style={{ fontSize: '29px' }}>
                                {isReturningUser ? `Welcome back to ${brandData.title}` : `Welcome to ${brandData.title}`}
                            </h1>
                            )}
                        {brandData && (
                            <StyledSelectAllWrapper>
                                <Checkbox
                                defaultChecked={false}
                                    type="checkbox" 
                                    checked={selectedImages.length === selectedImageCount -1} 
                                    onChange={toggleSelectAll} 
                                    id="selectAll" 
                                    name="selectAll" 
                                    value="Select All" 
                                    style={{ marginRight: '5px' }}/> 
                                <StyledCheckboxLabel htmlFor="selectAll">Select All Coupons</StyledCheckboxLabel>
                            </StyledSelectAllWrapper>
                            )}   
                        {brandData && (
                            <RenderCategoryDropdown 
                                brandData={brandData} 
                                selectedCategory={selectedCategory} 
                                setSelectedCategory={setSelectedCategory}
                            />
                        ) }
                    </div>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                </div>
            </div>
            <div className="img-container">           
                        <div className="row">
                            {renderImages()}
                        </div>
                    </div>
        
            {showPhoneNumberPopup && (
                <motion.div className="phone-popup">
                        <motion.div 
                        variants={dropIn}
                        initial="hidden"
                        animate="visible"
                        exit="exit"          
                        className="phone-popup-content">
                        <div className="col-12 phone-container d-flex">
                            <div className="row">
                                <div className="col-12 phonenumber">
                                    <div className="form-group text-center">
                                    <div className="phone-form">
                                        {showError && <p className="error-message">Please enter your phone number.</p>}
                                    </div>
                                    <h3>Unlock Exclusive Offers!</h3>
                                        <div className="phone-form">
                                        <div class="input-container">
                                            <select 
                                                id="countrySelect" 
                                                name="country"
                                                onChange={handleCountryChange}
                                                value={selectedCountry}
                                            >
                                                <option value="US">🇺🇸 USA</option>
                                                <option value="IN">🇮🇳 IN</option>
                                                <option value="UK">🇬🇧 UK</option>
                                                <option value="JP">🇯🇵 Japan</option>
                                            </select>

                                            <StyledInput
                                                type="text"
                                                className="form-control phone-number-ip"
                                                id="phoneinp"
                                                name="phone"
                                                value={phoneNumber}
                                                placeholder="Enter Phone No."
                                                onChange={handlePhoneChange}
                                                />

                                        </div>
                                            {/* <button 
                                                type="submit" 
                                                className="submit-link" 
                                                onClick={() => {savePhoneNumber && savePhoneNumber1();}} 
                                                // onClick={() => {buttonClick() && handleConversion && savePhoneNumber && savePhoneNumber1();gtag_report_conversion('/coupons');gaEventTracker(); logEvent('Button Click', 'Primary action', 'Start Saving Now!')}} 
                                                style={{ width: '70%', backgroundColor: phoneNumber.length === 10 ? 'green' : '#007BFF' }}
                                            >Start Saving Now!
                                            </button> */}
                                            {phoneNumber.length === 10 && (
                                                        <button
                                                        type="submit"
                                                        className="submit-link"
                                                        onClick={() => {
                                                            handleButtonClick();
                                                            savePhoneNumber();
                                                            savePhoneNumber1();
                                                            buttonClick();
                                                            buttonClick1();
                                                            window.gtag('config', 'AW-16449170732');
                                                            window.gtag('event', 'conversion', {
                                                                'send_to': 'AW-16449170732/LwU1CKDy-KUZEKzayaM9'
                                                            });
                                                            gtag_report_conversion(currentPageUrl)
                                                        }}
                                                        style={{ width: '70%', backgroundColor: 'green' }}
                                                    >
                                                        Start Saving Now!
                                                    </button>
                                                )}
                                            <p>
                                                By entering your number, you consent to our  
                                                <span className="sec-terms-link" onClick={() => setOpenSecond(true)}> Terms </span> 
                                                and will receive SMS coupons.
                                                <br></br>
                                                <a href="/" className="closeModalLink">No thanks, I prefer to pay full price.</a>
                                            </p>

                                        </div>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
            )}

{openSecond && (
                <motion.div className="second-popup">
                    <motion.div 
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{
                            duration: 0.3,
                            ease: [0, 0.71, 0.2, 1.01],
                            scale: {
                                type: "spring",
                                damping: 5,
                                stiffness: 100,
                                restDelta: 0.001
                            }
                        }}
                        className="second-popup-content" ref={newRef}>
                        <div className="second-container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group text-center">
                                    <label className="text-center info2" htmlFor="phone">
                                    <h4>Privacy Policy</h4>
                                        <ul>
                                            <li>
                                                <strong>Introduction</strong>
                                                <ul>
                                                    <li>This Privacy Policy governs the collection and use of personal information for ad promotions and coupon code distribution by LOCLshop.</li>
                                                    <li>By providing your personal information for ad promotions and coupon code distribution, you agree to abide by this Privacy Policy.</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <strong>Collection of Personal Information</strong>
                                                <ul>
                                                    <li>The Company collects personal information, including phone numbers, for the purpose of sending ad promotions and coupon codes to users.</li>
                                                    <li>By providing your personal information, you consent to receive promotional messages and coupon codes from the Company.</li>
                                                    <li>Your personal information will be kept confidential and will not be shared with third parties without your consent.</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <strong>Use of Personal Information</strong>
                                                <ul>
                                                    <li>The Company may use the collected personal information to send ad promotions and coupon codes via SMS or other messaging platforms.</li>
                                                    <li>You may opt-out of receiving promotional messages and coupon codes at any time by contacting the Company or following the unsubscribe instructions provided in the messages.</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <strong>Coupon Code Distribution</strong>
                                                <ul>
                                                    <li>Coupon codes distributed by the Company are for promotional purposes only and have no cash value.</li>
                                                    <li>Coupon codes may have expiration dates and usage restrictions, which will be clearly communicated at the time of distribution.</li>
                                                    <li>The Company reserves the right to modify or cancel coupon codes at any time without prior notice.</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <strong>User Responsibilities</strong>
                                                <ul>
                                                    <li>You are responsible for providing accurate and up-to-date personal information to the Company.</li>
                                                    <li>You agree not to misuse or abuse the coupon codes distributed by the Company for personal gain or profit.</li>
                                                    <li>You agree to use the coupon codes in accordance with any terms and conditions provided by the Company.</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <strong>Limitation of Liability</strong>
                                                <ul>
                                                    <li>The Company shall not be liable for any damages or losses arising from the use or misuse of coupon codes distributed to users.</li>
                                                    <li>The Company shall not be responsible for any errors or delays in the delivery of promotional messages or coupon codes.</li>
                                                </ul>
                                            </li>                                            
                                            <li>
                                                <strong>Changes to Privacy Policy</strong>
                                                <ul>
                                                    <li>The Company reserves the right to update or modify this Privacy Policy at any time without prior notice.</li>
                                                    <li>Users will be notified of any changes to the Privacy Policy via the contact information provided.</li>
                                                </ul>
                                            </li>
                                        </ul>
                                        <button className="btn btn-primary"onClick={() => setOpenSecond(false)}>Close</button>
                                    </label> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
            )}

        {smsSent && (
                <div className="sms-popup">
                    <div className="sms-popup-content">
                        <h2 className="text-center">Coupon Code sent in SMS Successfully</h2>                        
                    </div>
                </div>
            )}
           {shouldShowFooter() && <SFooter />}    
              
        </div>
    );
};

