import React, { useState, useEffect, useRef } from 'react';
import './landing_page.css'; // Import CSS file for styling
import { motion } from 'framer-motion';
import $ from 'jquery'; // Import jQuery
import 'select2'; // Import Select2 libraryimport Checkbox from '@mui/joy/Checkbox';
import styled from 'styled-components'; // Import styled-components   
import Checkbox from '@mui/joy/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import reverseGeocode from '../components/geoencoding'
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import useAnalyticsEventTracker from '../components/useAnalyticsEventTracker';
import { logEvent } from '../components/analytics';
import ShareButton from '../components/ShareButton.js';  // Import ShareButton


export default function UrlBased () {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [imagesData, setImagesData] = useState([]);
    const [isReturningUser, setIsReturningUser] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [smsSent, setSmsSent] = useState(false); 
    const [showPhoneNumberPopup, setShowPhoneNumberPopup] = useState(false);
    const [openSecond, setOpenSecond] = useState(false);
    const [showError, setShowError] = useState(false);
    const [selectedImages, setSelectedImages] = useState([]);
    const [showTenantAds, setShowTenantAds] = useState(false); 
    const [selectedCategory, setSelectedCategory] = useState('');
    const [userLocation, setUserLocation] = useState(null);
    const [userAddress, setUserAddress] = useState(null); // New state for user address
    const [selectAll , setselectAll] = useState(false);
    const selectRef = useRef(null); // Create a ref for the select element
    const newRef = useRef(null);
    const smsnewRef = useRef(null);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const brandName = searchParams.get('brand');
    const siteimpactphonenumber = searchParams.get('phone') ? '+' + searchParams.get('phone').replace(/\s/g, '') : null;
    const [brandData, setBrandData] = useState(null);
    const [selectedImageCount, setSelectedImageCount] = useState(0);
    const [selectedCountry, setSelectedCountry] = useState('US');

    const currentPageUrl = window.location.href;

    useEffect(() => {
        // window.gtag('config', 'AW-16449170732');
        // window.gtag('event', 'conversion', {'send_to': 'AW-16449170732/LwU1CKDy-KUZEKzayaM'});
        $(selectRef.current).select2();
        fetchImagesData(); // Fetch images data when the component mounts


        // Check if the phone number is present in the URL
        if (siteimpactphonenumber) {
            localStorage.setItem('phoneNumber', siteimpactphonenumber);
            checkReturningUser(); // Check if the user is returning
            setShowPhoneNumberPopup(false); // Set showPhoneNumberPopup to false if the phone number is present in the URL

            fetch('https://prod.loclshop.com/api/store-phone-number', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ phoneNumber: siteimpactphonenumber })
            })
            .then(response => response.json())
            .then(data => console.log(data))
            .catch(error => console.error('Error storing phone number:', error));

            // Push the phone number to the /unique-phone-data endpoint
            const postData = { phoneNumber: siteimpactphonenumber }; // Assuming your endpoint expects the phone number under the key 'phoneNumber'
            fetch('https://prod.loclshop.com/api/unique-phone-data', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(postData)
            })
            .then(response => response.json())
            .then(data => console.log('Data inserted successfully:', data))
            .catch(error => console.error('Error inserting data:', error));
        }

        checkReturningUser(); // Check if the user is returning

        const storedPhoneNumber = localStorage.getItem('phoneNumber');
        if (storedPhoneNumber) {
            setShowPhoneNumberPopup(false); // Set showPhoneNumberPopup to false if the phone number is already in local storage
        } else {
            setShowPhoneNumberPopup(true); // Set showPhoneNumberPopup to true if the phone number is not in local storage
        }
        if ("geolocation" in navigator) {
            // Get user's current location initially
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setUserLocation({ latitude, longitude });
                    reverseGeocode(latitude, longitude);
                },
                (error) => {
                    console.error('Error getting user location:', error);
                }
            );
        } else {
            console.error("Geolocation is not supported by this browser.");
        }
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            $(selectRef.current).select2('destroy');
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);
    

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://prod.loclshop.com/api/query-data');
                const data = await response.json();
                if (data && data.brands) {
                    const brand = data.brands.find(brand => brand.BrandName.toLowerCase() === brandName.toLowerCase());
                    if (brand) {
                        setBrandData(brand);
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (brandName) {
            fetchData();
        }
    }, [brandName]);

    useEffect(() => {
        // Extract brand name from the URL
        const urlParams = new URLSearchParams(window.location.search);
        const brandName = urlParams.get('brand');
        
        // Logic to set default checked state of checkbox
        if (selectAll) {
            setSelectedImages([]);
            const brandImagesData = imagesData.find(brand => brand.brandName.toLowerCase() === brandName.toLowerCase());
        } else {
            const brandImagesData = imagesData.find(brand => brand.brandName.toLowerCase() === brandName.toLowerCase());
            if (brandImagesData) {
                setSelectedImages(brandImagesData.offers);
                setSelectedImageCount(brandImagesData.offers.length +1 );
            }
        }
    }, [selectAll]); // Run effect whenever selectAll changes

    useEffect(() => {
        const fetchImagesData = async () => {
            try {
                const response = await fetch('https://prod.loclshop.com/api/query-data', {
                    mode: 'cors' // Add the mode option here
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch images data');
                }
                const data = await response.json();
                if (data && data.brands) {
                    const imagesData = data.brands.map(brand => ({
                        brandName: brand.BrandName,
                        offers: brand.offers.map(offer => ({
                            img_url: offer.img_url,
                            sms: offer.sms,
                            name: offer.name,
                            category: offer.category,
                            text: offer.text,
                            affiliate_url: offer.affiliate_url,
                            affiliate_image_url: offer.affiliate_image_url
                        }))
                    }));
                    setImagesData(imagesData);
                }
            } catch (error) {
                console.error('Error fetching images data:', error);
            }
        };    
    })

    const fetchImagesData = async () => {
        try {
            const response = await fetch('https://prod.loclshop.com/api/query-data', {
                mode: 'cors' // Add the mode option here
            });
            if (!response.ok) {
                throw new Error('Failed to fetch images data');
            }
            const data = await response.json();
            if (data && data.brands) {
                const imagesData = data.brands.map(brand => ({
                    brandName: brand.BrandName,
                    offers: brand.offers.map(offer => ({
                        img_url: offer.img_url,
                        sms: offer.sms,
                        name: offer.name,
                        category: offer.category,
                        text: offer.text,
                        affiliate_url: offer.affiliate_url,
                        affiliate_image_url: offer.affiliate_image_url
                    }))
                }));
                setImagesData(imagesData);
            }
        } catch (error) {
            console.error('Error fetching images data:', error);
        }
    };
    
    // Function to check if current location matches hardcoded coordinates within a tolerance
    let smsSent1 = false; // Initialize flag to track whether SMS has been sent

    const StyledFooterButton = styled.button`
        position: absolute;
        bottom: 10px;
        left: 18%;
        margin-top: 4%;
        padding: 10px 20px;
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: #0056b3;
        }

        @media screen and (min-width: 768px){
            left: 42%; // Adjusted left position for screens <= 768px width
        }

        @media screen and (min-width: 431px) and (max-width: 768px) {
            left: 35%; // Adjusted left position for screens <= 768px width
        }
    `;

    const SFooter = () => {
        const brandImagesData = imagesData.find(brand => brand.brandName.toLowerCase() === brandName.toLowerCase());
        // Logging the length of offers in brandImagesData    
        return (
            <div className='stickyfooter'>
                <StyledFooterButton className='bfooter' type="button" onClick={postData}>
                    {selectedImages.length === brandImagesData.offers.length  ? 'Get All Coupon Codes!' : 'Get Selected Coupon Codes!'}
                </StyledFooterButton>  
            </div>
        );
    };
    
    
    const gaEventTracker = useAnalyticsEventTracker('Unlock Exclusive Offers!');
    
    const handleOutsideClick = (e) => {
        const storedPhoneNumber = localStorage.getItem('phoneNumber');
        if (newRef.current && !newRef.current.contains(e.target)) {
            setSelectedImage(null);
            setOpenSecond(false);
        }
        if (storedPhoneNumber && smsnewRef.current && !smsnewRef.current.contains(e.target)) {
            setSelectedImage(null);
        }
      };

    // Function to fetch images data from the server
    // Function to save phone number in local storage
    const savePhoneNumber = () => {
        if (phoneNumber.trim() !== '') {
            const countryCodes = {
                US: '+1',
                IN: '+91',
                UK: '+44',
                JP: '+81'  // Assuming you want to use the same prefix as the US for Canada
            };
            
            const formattedPhoneNumber = phoneNumber.startsWith(countryCodes[selectedCountry]) 
                ? phoneNumber 
                : countryCodes[selectedCountry] + phoneNumber.trim();
    
            // Check if formattedPhoneNumber is not just the country code
            if (formattedPhoneNumber.length !== countryCodes[selectedCountry].length + 1) {
                localStorage.setItem('phoneNumber', formattedPhoneNumber);
            }
            const currentPageUrl = window.location.href;
            window.gtag('config', 'AW-16449170732');
            window.gtag('event', 'conversion', {'send_to': 'AW-16449170732/LwU1CKDy-KUZEKzayaM9'});
            gtag_report_conversion(currentPageUrl)
            setShowPhoneNumberPopup(false);            
            // Your analytics tracking code
            
        } else {
            // Show error message
            setShowError(true);
            // Hide error message after 3 seconds
            setTimeout(() => {
                setShowError(false);
            }, 5000);
        }
    };
    

    const savePhoneNumber1 = () => {
        // Mapping of country codes to phone number prefixes
        if (phoneNumber.trim() !== '') {
            const countryCodes = {
                US: '+1',
                IN: '+91',
                UK: '+44',
                CA: '+1'  // Assuming you want to use the same prefix as the US for Canada
            };
        
            const formattedPhoneNumber = phoneNumber && phoneNumber.startsWith(countryCodes[selectedCountry]) 
                ? phoneNumber 
                : countryCodes[selectedCountry] + (phoneNumber ? phoneNumber.trim() : '');
        
            // Check if formattedPhoneNumber is not just the country code
            if (formattedPhoneNumber.length !== countryCodes[selectedCountry].length + 1) {
                localStorage.setItem('phoneNumber', formattedPhoneNumber);
                setPhoneNumber(formattedPhoneNumber);
    
                if (formattedPhoneNumber.replace(countryCodes[selectedCountry], '').length !== 10) {
                    setShowError(true);
                    setTimeout(() => setShowError(false), 5000); // Hide error after 5 seconds
                } else if ("geolocation" in navigator) {
                    navigator.geolocation.getCurrentPosition(position => {
                        const currentPageUrl = window.location.href;
                        const { latitude, longitude } = position.coords;
                        const userLocation = { latitude, longitude };

                        //console.log("images data from inside :", imagesData)

                        const urlParams = new URLSearchParams(window.location.search);
                        const brandName = urlParams.get('brand');
                        const brandImagesData = imagesData.find(brand => brand.brandName.toLowerCase() === brandName.toLowerCase());
                        //console.log("brand images from inside : ", brandImagesData)
                        const offers = brandImagesData.offers;
                        // Initialize an empty string to hold the concatenated SMS messages
                        let url = `For more Offers and Coupon codes visit ${currentPageUrl}`;
                        // Initialize an empty string to hold the concatenated SMS messages
                        let concatenatedSms = '';
                        // Loop through the first three offers and concatenate the SMS messages with a newline in between
                        for (let i = 0; i < Math.min(3, offers.length); i++) {
                            if (i > 0) concatenatedSms += '\n\n'; // Add a newline character before each SMS, except for the first one
                            concatenatedSms += offers[i].sms;
                        }

                        // Append the URL on a new line after all SMS messages
                        concatenatedSms += `\n\n${url}`;
                        localStorage.setItem('concatenatedSms1', concatenatedSms); // Store concatenated SMS in local storage

                        // sendSMS2(formattedPhoneNumber, concatenatedSms, userLocation.latitude, userLocation.longitude);


                        reverseGeocode(latitude, longitude)
                            .then(address => {
                                axios.post('https://prod.loclshop.com/api/save-phone-number', {
                                    phoneNumber: formattedPhoneNumber,
                                    latitude: latitude,
                                    longitude: longitude,
                                    address: address,
                                    pageUrl: currentPageUrl  // Including the current page URL
                                })
                                .then(response => {
                                    //console.log('Phone number, location, and address saved successfully:', response.data);

                                    ReactGA.event({
                                        category: 'User',
                                        action: 'Clicked a button'
                                    });
                                
                                    // Retrieve the concatenated SMS from local storage
                                    const concatenatedSms1 = localStorage.getItem('concatenatedSms1');
                                    if (concatenatedSms1) {
                                        // Proceed to send SMS if concatenatedSms exists
                                        //sendSMS2(formattedPhoneNumber, concatenatedSms1, latitude, longitude);
                                        //console.log('.')
                                    } else {
                                        console.error('Failed to retrieve SMS content from local storage.');
                                    }
                                
                                    setShowPhoneNumberPopup(false);
                                })
                                .catch(error => {
                                    console.error('Error saving phone number:', error);
                                });
                            })
                            .catch(error => {
                                console.error('Error getting address:', error);
                            });
                    }, error => {
                        console.error('Error getting user location:', error);
                    });
                } else {
                    console.error("Geolocation is not supported by this browser.");
                }
            }
        } else {
            setShowError(true);
            setTimeout(() => setShowError(false), 5000); // Hide error after 5 seconds
        }
    };    

    // Function to check if the user is returning
    // Mark the function as async since you're using await inside it
    const checkReturningUser = async () => {
        const storedPhoneNumber = localStorage.getItem('phoneNumber');
        if (storedPhoneNumber) {
            let imagesData = [];
            try {
                const response = await fetch('https://prod.loclshop.com/api/query-data', {
                    mode: 'cors'
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch images data');
                }
                const data = await response.json();
                if (data && data.brands) {
                    imagesData = data.brands.map(brand => ({
                        brandName: brand.BrandName,
                        offers: brand.offers.map(offer => ({
                            img_url: offer.img_url,
                            sms: offer.sms,
                            name: offer.name,
                            category: offer.category,
                            text: offer.text,
                            affiliate_url: offer.affiliate_url,
                            affiliate_image_url: offer.affiliate_image_url
                        }))
                    }));
                    setImagesData(imagesData);
                }
            } catch (error) {
                console.error('Error fetching images data:', error);
            }
    
            navigator.geolocation.getCurrentPosition(async (position) => {
                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;
                const urlParams = new URLSearchParams(window.location.search);
                const brandName = urlParams.get('brand');
                const brandImagesData = imagesData.find(brand => brand.brandName.toLowerCase() === brandName.toLowerCase());
    
                if (!brandImagesData) {
                    console.error('No data found for the specified brand');
                    return; // Exit if no brand data found
                }
    
                const offers = brandImagesData.offers;
                let url = `For more Offers and Coupon codes visit ${currentPageUrl}`;
                let concatenatedSms = '';
    
                for (let i = 0; i < Math.min(3, offers.length); i++) {
                    if (i > 0) concatenatedSms += '\n\n';
                    concatenatedSms += offers[i].sms;
                }
    
                concatenatedSms += `\n\n${url}`;
                //console.log(concatenatedSms);
    
                setTimeout(async () => {
                    await sendSMS2(storedPhoneNumber, concatenatedSms, latitude, longitude);
                    afterSmsSent(latitude, longitude, storedPhoneNumber);
                }, 1000);
    
            }, (error) => {
                console.error('Error obtaining location:', error.message);
            });
    
            setIsReturningUser(true);

            setPhoneNumber(storedPhoneNumber);
            setShowPhoneNumberPopup(false);
        } else {
            setShowPhoneNumberPopup(true);
        }
    };
    
    async function afterSmsSent(latitude, longitude, phoneNumber) {
        try {
            const address = await reverseGeocode(latitude, longitude);
            await axios.post('https://prod.loclshop.com/api/save-phone-number', {
                phoneNumber: phoneNumber,
                latitude: latitude,
                longitude: longitude,
                address: address,
                pageUrl: currentPageUrl
            });
        } catch (error) {
            console.error('Error in reverse geocoding or saving the data:', error);
        }
    }
    
    
    

    // Event handler for phone number input change
    const handlePhoneChange = (event) => {
        setPhoneNumber(event.target.value);
    };

    const postData = () => {
        let smsValues = []; // Initialize an array to store SMS values
        // If none of the images are selected and neither "Select All" nor "Tenant Ads" checkbox is checked
        if (selectedImages.length === 0 && !showTenantAds && !selectedCategory) {
            // Fetch SMS values from the URL
            fetchImagesData().then(() => {
                // Extract SMS values from imagesData
                smsValues = imagesData.map((imageItem) => imageItem.sms);
                // Send SMS with fetched SMS values and user's location
                sendSMS(phoneNumber, smsValues, userLocation.latitude, userLocation.longitude);
            }).catch((error) => {
                console.error('Error fetching images data:', error);
            });
        } else {
            // Extract SMS values based on the selected category and tenant ads checkbox
            let selectedImagesSmsValues;
            if ((showTenantAds || selectedCategory) && selectedImages.length > 0) {
                // Logic to extract SMS values based on selected category or tenant ads
                if (selectedCategory) {
                    // Filter selected images based on the selected category
                    const selectedCategoryImages = selectedImages.filter(imageItem => imageItem.category === selectedCategory);
                    // Extract SMS values from selected images in the specified category
                    selectedImagesSmsValues = selectedCategoryImages.map(imageItem => imageItem.sms);
                } else {
                    // Extract SMS values from selected tenant ads if "Tenant Ads" checkbox is checked
                    const selectedTenantAds = selectedImages.filter(imageItem => imageItem.type === "tenant");
                    selectedImagesSmsValues = selectedTenantAds.map(imageItem => imageItem.sms);
                }
            } else {
                // Extract SMS values from all selected images if no category is selected or "Tenant Ads" checkbox is not checked
                selectedImagesSmsValues = selectedImages.map(imageItem => imageItem.sms);
            }    
            // Implement your post data logic here
            const storedPhoneNumber = localStorage.getItem('phoneNumber');
            if (storedPhoneNumber === phoneNumber) {
                sendSMS(phoneNumber, selectedImagesSmsValues, userLocation.latitude, userLocation.longitude); // Pass selected images' SMS values to sendSMS function               
            } else {
                localStorage.setItem('phoneNumber', phoneNumber);
                sendSMS(phoneNumber, selectedImagesSmsValues, userLocation.latitude, userLocation.longitude); // Pass selected images' SMS values to sendSMS function                
            }
        }
    };

    const shouldShowFooter = () => {
        return selectedImage || selectedImages.length > 0; // Footer should appear if any image is selected or if any image is selected through "Select All" checkbox
    };

    // Function to send SMS
    const sendSMS = (phoneNumber, smsValues, latitude, longitude) => {
        // Mapping of country codes to phone number prefixes
        if (!phoneNumber) {
            return;
        }
        // Reverse geocode to get the address
        reverseGeocode(latitude, longitude)
            .then(address => {
                // Concatenate all SMS messages into a single body
                const messageBody = Array.isArray(smsValues) ? smsValues.join('\n\n') : smsValues;
    
                // Send the concatenated message as one SMS
                return fetch('https://prod.loclshop.com/api/loclsms2', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ 
                        phoneNumber: phoneNumber, 
                        adname: messageBody,
                        address: address, // Include user's address
                        latitude: latitude, // Include latitude
                        longitude: longitude // Include longitude
                    }),
                });
            })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to send SMS');
                }
                setSmsSent(true);
                setTimeout(() => {
                    setSmsSent(false);
                }, 3000);
                // Clear the input field if the response is OK
            })
            .catch((error) => {
                console.error('Error sending SMS:', error);
            });
    };

    const sendSMS2 = (phoneNumber, smsValues, latitude, longitude) => {
        // Mapping of country codes to phone number prefixes
        if (!phoneNumber) {
            return;
        }
        // Reverse geocode to get the address
        reverseGeocode(latitude, longitude)
            .then(address => {
                // Concatenate all SMS messages into a single body
                const messageBody = smsValues;
    
                // Send the concatenated message as one SMS
                return fetch('https://prod.loclshop.com/api/loclsms2', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ 
                        phoneNumber: phoneNumber, 
                        adname: messageBody,
                        address: address, // Include user's address
                        latitude: latitude, // Include latitude
                        longitude: longitude // Include longitude
                    }),
                });
            })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to send SMS');
                }
                setSmsSent(true);
                setTimeout(() => {
                    setSmsSent(false);
                }, 3000);
                // Clear the input field if the response is OK
            })
            .catch((error) => {
                console.error('Error sending SMS:', error);
            });
    };
    
    const handleImageClick = (imageItem) => {
        const index = selectedImages.findIndex(item => item.img_url === imageItem.img_url);
        if (index === -1) {
            setSelectedImages(prevState => [...prevState, imageItem]);
        } else {
            setSelectedImages(prevState => prevState.filter(item => item.img_url !== imageItem.img_url));
        }
    };

    const buttonClick = () => {
        window.gtag('config', 'AW-16449170732');
        window.gtag('event', 'conversion', {'send_to': 'AW-16449170732/LwU1CKDy-KUZEKzayaM9'});
    };

    const buttonClick1 = () => {
            let currentPageUrl = window.location.href;
            window.gtag('config', 'AW-16449170732');
            window.gtag('event', 'conversion', {'send_to': 'AW-16449170732/LwU1CKDy-KUZEKzayaM9'});
            gtag_report_conversion(currentPageUrl)
    }

    // Function to close the popup
    const closePopup = () => {
        setSelectedImage(null);
        setSmsSent(true); 
        setShowPhoneNumberPopup(false);
        
    };

    const handleButtonClick = () => {
        ReactGA.event({
          category: 'User',
          action: 'Clicked a button'
        });
      };

    const toggleSelectAll = () => {
        // Toggle the selectAll state
        setselectAll(prevSelectAll => !prevSelectAll);
    };
    
    const renderImages = () => {
        let filteredImages = [];
        if (brandData && brandData.offers) {
            // Check if a category is selected; if not, display all images
            if (selectedCategory) {
                // Filter images based on the selected category
                filteredImages = brandData.offers.filter(offer => offer.category === selectedCategory);
            } else {
                // If no category is selected, do not filter; display all offers
                filteredImages = brandData.offers;
            }
        }
        
        return filteredImages.map((imageItem, index) => (
            <div className="col-lg-4 col-md-6 col-sm-12" key={index}>
                <div className="deal" onClick={() => handleImageClick(imageItem)}>
                <img className='bimage'
                        src={imageItem.img_url} 
                        alt={imageItem.sms} 
                        style={{ 
                            borderRadius: '20px', 
                            paddingTop: '10px', 
                            borderColor: '#C6C5C5', 
                            boxShadow: '0 16px 24px rgba(0, 0, 0, 0.2)',
                            height: '470px', 
                            width: '95%',
                        }} 
                    />
                    <Checkbox
                        checked={selectedImages.some(item => item.img_url === imageItem.img_url)}
                        onChange={() => handleImageClick(imageItem)}
                        style={{
                            position: 'absolute',
                            bottom: '22px',
                            left: '30px',
                            borderRadius: '5px',
                            transform: 'scale(2)',
                            animation: 'zoomInOut 0.6s infinite alternate',
                            border: '2px solid black'
                        }}
                    />
                </div>
            </div>
        ));
    };

    const sleep = (duration) => {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve();
          }, duration);
        });
      }

      const RenderCategoryDropdown = ({ brandData, selectedCategory, setSelectedCategory }) => {
        // Extract categories based on the selected brand
        const categories = brandData ? [...new Set(brandData.offers.map(offer => offer.category))] : [];
        const [open, setOpen] = useState(false);
        const [options, setOptions] = useState([]);
        const loading = open && options.length === 0;
    
        useEffect(() => {
            let active = true;
    
            if (!loading) {
                return undefined;
            }
    
            (async () => {
                await sleep(1e3); // For demo purposes.
    
                if (active) {
                    setOptions([...categories]);
                }
            })();
    
            return () => {
                active = false;
            };
        }, [loading, categories]);
    
        useEffect(() => {
            if (!open) {
                setOptions([]);
            }
        }, [open]);
    
        // Filter images based on selected category
        const filteredImages = brandData ? brandData.offers.filter(offer => !selectedCategory || offer.category === selectedCategory) : [];
    
        return (
            <div>
                <Autocomplete
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    getOptionLabel={(option) => option}
                    options={categories} // Make sure this is the array of categories extracted from the offers
                    loading={loading}
                    onChange={handleCategoryChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Select Category"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
                <div>
                    </div>
                        </div>
                );
            }

    const StyledSelectAllWrapper = styled.div`
        position: absolute;
        top: 90%;
        right: 9%;
        display: flex;
        align-items: center;
        z-index: 999; /* Add a high z-index value */

        @media screen and (min-width: 768px) {
            top: 85%; // Adjusted top position for screens <= 768px width
            right: 0%; // Adjusted right position for screens <= 768px width
        }
    `;

    const StyledCheckboxLabel = styled.label`
        margin-top: 5px;
        text-align: right;

        @media (max-width: 767px) {
            text-align: center;
        }
        @media (max-width: 400px) {
            text-align: left;
        }
    `;

    // Dropdown change handler
const handleCategoryChange = (event, value) => {
    setSelectedCategory(value);
};

    const dropIn = {
        hidden: {
          y: "-100vh",
          opacity: 0,
        },
        visible: {
          y: "0",
          opacity: 1,
          transition: {
            duration: 1,
            type: "spring",
            damping: 20,
            stiffness: 500,
          },
        },
        exit: {
          y: "100vh",
          opacity: 0,
        },
      };

      function gtag_report_conversion(url) {
        var callback = function () {
          if (typeof(url) !== 'undefined') {
            window.location = url;
          }
        };
      
        window.gtag('event', 'conversion', {
          'send_to': 'AW-16449170732/LwU1CKDy-KUZEKzayaM9',
          'event_callback': callback
        });
      
        return false;
      }

      const handleCountryChange = (event) => {
        setSelectedCountry(event.target.value);
    };

    return (
        
        <div>
            <ShareButton />  {/* Include ShareButton here */}
        <div className="container">
            <div className="row">
                <div className="col-12">
                {brandData && (
                    <h1 className="text-center title" style={{ fontSize: '29px' }}>
                    {siteimpactphonenumber ? `Welcome to ${brandData.title}` : isReturningUser ? `Welcome back to ${brandData.title}` : `Welcome to ${brandData.title}`}      
                    </h1>
            )}
                <StyledSelectAllWrapper>
                    <Checkbox
                    defaultChecked={false}
                        type="checkbox" 
                        checked={selectedImages.length === selectedImageCount -1} 
                        onChange={toggleSelectAll} 
                        id="selectAll" 
                        name="selectAll" 
                        value="Select All" 
                        style={{ marginRight: '5px' }}/> 
                    <StyledCheckboxLabel htmlFor="selectAll">Select All Coupons</StyledCheckboxLabel>
                </StyledSelectAllWrapper>
                        {RenderCategoryDropdown({brandData, selectedCategory, setSelectedCategory})}
                    </div>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                </div>
            </div>
            <div className="img-container">           
                        <div className="row">
                            {renderImages()}
                        </div>
                    </div>
        
                    {showPhoneNumberPopup && (
    <motion.div className="phone-popup">
        <motion.div 
            variants={dropIn}
            initial="hidden"
            animate="visible"
            exit="exit"          
            className="phone-popup-content">
            <div className="col-12 phone-container d-flex justify-content-center">
                <div className="row">
                    <div className="col-12 phonenumber">
                        <div className="form-group text-center">
                            <div className="phone-form">
                                {showError && <p className="error-message">Please enter your phone number.</p>}
                            </div>
                            <h2>Unlock Exclusive Offers!</h2>
                            <div className="phone-form">
                            <div class="input-container">
                                <select 
                                    id="countrySelect" 
                                    name="country"
                                    onChange={handleCountryChange}
                                    value={selectedCountry}
                                >
                                    <option value="US">🇺🇸 USA</option>
                                    <option value="IN">🇮🇳 IN</option>
                                    <option value="UK">🇬🇧 UK</option>
                                    <option value="JP">🇯🇵 Japan</option>
                                </select>

                                <input 
                                    type="text" 
                                    className="form-control phone-number-ip" 
                                    id="phoneinp" 
                                    name="phone" 
                                    value={phoneNumber || 'Enter Phone Number'} 
                                    onChange={handlePhoneChange} 
                                    onFocus={(e) => e.target.value === 'Enter Phone Number' && (e.target.value = '')}
                                    onBlur={(e) => !e.target.value && (e.target.value = 'Enter Phone Number')}
                                    style={{ color: phoneNumber && phoneNumber !== 'Enter Phone Number' ? 'black' : 'red' }}
                                />

                            </div>

                                            {/* <button 
                                                type="submit" 
                                                className="submit-link" 
                                                onClick={() => {savePhoneNumber && savePhoneNumber1();}} 
                                                // onClick={() => {buttonClick() && handleConversion && savePhoneNumber && savePhoneNumber1();gtag_report_conversion('/coupons');gaEventTracker(); logEvent('Button Click', 'Primary action', 'Start Saving Now!')}} 
                                                style={{ width: '70%', backgroundColor: phoneNumber.length === 10 ? 'green' : '#007BFF' }}
                                            >Start Saving Now!
                                            </button> */}
                                            {phoneNumber.length === 10 && (
                                                    <button
                                                    type="submit"
                                                    className="submit-link"
                                                    onClick={() => {
                                                        handleButtonClick();
                                                        savePhoneNumber();
                                                        savePhoneNumber1();
                                                        buttonClick();
                                                        buttonClick1();
                                                        window.gtag('config', 'AW-16449170732');
                                                        window.gtag('event', 'conversion', {
                                                            'send_to': 'AW-16449170732/LwU1CKDy-KUZEKzayaM9'
                                                        });
                                                        gtag_report_conversion(currentPageUrl)
                                                    }}
                                                    style={{ width: '70%', backgroundColor: 'green' }}
                                                >
                                                    Start Saving Now!
                                                </button>
                                                
                                                )}
                                <p>
                                    By entering your number, you consent to our 
                                    <span className="sec-terms-link" onClick={() => setOpenSecond(true)}>Terms</span> 
                                    and will receive SMS coupons.
                                </p>
                                <a href="/" className="closeModalLink">No thanks, I prefer to pay full price.</a>
                            </div>                                     
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    </motion.div>
)}


{openSecond && (
                <motion.div className="second-popup">
                    <motion.div 
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{
                            duration: 0.3,
                            ease: [0, 0.71, 0.2, 1.01],
                            scale: {
                                type: "spring",
                                damping: 5,
                                stiffness: 100,
                                restDelta: 0.001
                            }
                        }}
                        className="second-popup-content" ref={newRef}>
                        <div className="second-container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group text-center">
                                    <label className="text-center info2" htmlFor="phone">
                                    <h4>Privacy Policy</h4>
                                        <ul>
                                            <li>
                                                <strong>Introduction</strong>
                                                <ul>
                                                    <li>This Privacy Policy governs the collection and use of personal information for ad promotions and coupon code distribution by [Your Company Name] ("the Company").</li>
                                                    <li>By providing your personal information for ad promotions and coupon code distribution, you agree to abide by this Privacy Policy.</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <strong>Collection of Personal Information</strong>
                                                <ul>
                                                    <li>The Company collects personal information, including phone numbers, for the purpose of sending ad promotions and coupon codes to users.</li>
                                                    <li>By providing your personal information, you consent to receive promotional messages and coupon codes from the Company.</li>
                                                    <li>Your personal information will be kept confidential and will not be shared with third parties without your consent.</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <strong>Use of Personal Information</strong>
                                                <ul>
                                                    <li>The Company may use the collected personal information to send ad promotions and coupon codes via SMS or other messaging platforms.</li>
                                                    <li>You may opt-out of receiving promotional messages and coupon codes at any time by contacting the Company or following the unsubscribe instructions provided in the messages.</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <strong>Coupon Code Distribution</strong>
                                                <ul>
                                                    <li>Coupon codes distributed by the Company are for promotional purposes only and have no cash value.</li>
                                                    <li>Coupon codes may have expiration dates and usage restrictions, which will be clearly communicated at the time of distribution.</li>
                                                    <li>The Company reserves the right to modify or cancel coupon codes at any time without prior notice.</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <strong>User Responsibilities</strong>
                                                <ul>
                                                    <li>You are responsible for providing accurate and up-to-date personal information to the Company.</li>
                                                    <li>You agree not to misuse or abuse the coupon codes distributed by the Company for personal gain or profit.</li>
                                                    <li>You agree to use the coupon codes in accordance with any terms and conditions provided by the Company.</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <strong>Limitation of Liability</strong>
                                                <ul>
                                                    <li>The Company shall not be liable for any damages or losses arising from the use or misuse of coupon codes distributed to users.</li>
                                                    <li>The Company shall not be responsible for any errors or delays in the delivery of promotional messages or coupon codes.</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <strong>Governing Law</strong>
                                                <ul>
                                                    <li>This Privacy Policy shall be governed by and construed in accordance with the laws of [Your Country/State].</li>
                                                    <li>Any disputes arising out of or relating to this Privacy Policy shall be subject to the exclusive jurisdiction of the courts in [Your Jurisdiction].</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <strong>Changes to Privacy Policy</strong>
                                                <ul>
                                                    <li>The Company reserves the right to update or modify this Privacy Policy at any time without prior notice.</li>
                                                    <li>Users will be notified of any changes to the Privacy Policy via the contact information provided.</li>
                                                </ul>
                                            </li>
                                        </ul>
                                        <button className="btn btn-primary"onClick={() => setOpenSecond(false)}>Close</button>
                                    </label> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
            )}

        {smsSent && (
                <div className="sms-popup">
                    <div className="sms-popup-content">
                        <h2 className="text-center">Coupon Code sent in SMS Successfully</h2>                        
                    </div>
                </div>
            )}
           {shouldShowFooter() && <SFooter />}    
              
        </div>
    );
};