import React from 'react';
import BrandEmbeddedDashboard from './brandDashboardpage';
import SideBar from '../components/UserSideBar'


const BrandDashboard = () => {

    return (
        <div>
            <SideBar>
                <BrandEmbeddedDashboard />
            </SideBar>
        </div>
    );
};

export default BrandDashboard;
