import styled from 'styled-components';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './UserForm.css'; // Make sure your CSS path is correct

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center; /* Align items vertically */
  margin-bottom: 1rem;

  input[type='checkbox'] {
    margin-right: 1rem;
    width: 20px; /* Adjust the width */
    height: 20px; /* Adjust the height */
  }
`;

const CheckboxLabel = styled.label`
  color: #333;
  margin-top: 4%;

  @media (min-width: 768px) {
    margin-top: 1.5%; /* Adjust margin-top for smaller screens */
  }
`;

const SearchButton = styled.button`
  background-color: #6c757d;
  color: #ffffff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100px;
  height: 37px;
  padding-left: 10px;

  &:hover {
    background-color: #495057;
  }
`;

const BrandNameInput = styled.input`
  flex-grow: 1;
`;

function UserForm({ addUser }) {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    userName: '',
    password: '',
    emailId: '',
    brandName: '',
    location: '',
    privileges: {
      dashboard: false,
      report: false,
      sampleR: false,
      sampleD: false
    },
    frequency: {
      daily: false,
      weekly: false,
      monthly: false
    },
    status: {
      active: false,
      deactive: false
    }
  });

  const [errors, setErrors] = useState({});
  const [searchResult, setSearchResult] = useState('');
  const [isDashboardFound, setIsDashboardFound] = useState(false);

  const handleChange = e => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      if (name === 'dashboard' || name === 'report' || name === 'sampleD' || name === 'sampleR') {
        setFormData(prev => ({
          ...prev,
          privileges: {
            ...prev.privileges,
            [name]: checked
          }
        }));
      } else if (name === 'active' || name === 'deactive') {
        setFormData(prev => ({
          ...prev,
          status: {
            active: name === 'active' ? checked : false,
            deactive: name === 'deactive' ? checked : false
          }
        }));
      } else {
        setFormData(prev => ({
          ...prev,
          frequency: {
            ...prev.frequency,
            [name]: checked
          }
        }));
      }
    } else {
      if (name === 'brandName') {
        const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
        setFormData(prev => ({
          ...prev,
          [name]: capitalizedValue
        }));
      } else {
        setFormData(prev => ({
          ...prev,
          [name]: value
        }));
      }
    }
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: null
      }));
    }
  };
  
  

  const validateForm = data => {
    let newErrors = {};
    Object.keys(data).forEach(key => {
      if (!data[key] && key !== 'privileges' && key !== 'frequency') {
        newErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} is required.`;
      }
    });

    if (data.emailId && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(data.emailId)) {
      newErrors.emailId = "Invalid email format";
    }

    if (data.password && !/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/.test(data.password)) {
      newErrors.password = "Password must include at least one uppercase letter, one lowercase letter, one number, and one special character.";
    }

    return newErrors;
  };

  const handleSubmit = e => {
    e.preventDefault();
    const formErrors = validateForm(formData);
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      fetch('https://prod.loclshop.com/api/regusers', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      })
        .then(response => {
          if (!response.ok) {
            throw response;
          }
          return response.json();
        })
        .then(data => {
          setFormData({
            firstName: '',
            lastName: '',
            userName: '',
            password: '',
            emailId: '',
            brandName: '',
            location: '',
            privileges: {
              dashboard: false,
              report: false,
              sampleR: false,
              sampleD: false
            },
            frequency: {
              daily: false,
              weekly: false,
              monthly: false
            },
            status: {
              active: false,
              deactive: false
            }
          });
          setErrors({});
          navigate('/manageusers');
        })
        .catch(error => {
          if (error instanceof Response) {
            error.text().then(errorMessage => {
              setErrors({ ...errors, apiError: errorMessage });
            });
          } else {
            setErrors({ ...errors, apiError: error.message });
          }
        });
    }
  };

  const handleSearch = () => {
    fetch('https://prod.loclshop.com/api/brandsearch', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ brandName: formData.brandName })
    })
      .then(response => response.json())
      .then(data => {
        if (data.found) {
          setSearchResult('Dashboard found');
          setIsDashboardFound(true);
        } else {
          setSearchResult('Dashboard not found');
          setIsDashboardFound(false);
        }
      })
      .catch(error => {
        console.error('Error:', error);
        setSearchResult('Error occurred during search');
        setIsDashboardFound(false);
      });
  };

  return (
    <div className="container my-5">
      <h2 className="text-center mb-4 regtitle">Register New User</h2>
      <form onSubmit={handleSubmit} className="form-container card card-body" autoComplete="off">
        {Object.keys(formData).map(
          (key) =>
            key !== 'privileges' &&
            key !== 'password' &&
            key !== 'userName' &&
            key !== 'frequency' &&
            key !== 'brandName' &&
            key !== 'status' && (
              <div className="mb-3" key={key}>
                <label htmlFor={key} className="form-label">
                  {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1').trim()}
                </label>
                <input
                  id={key}
                  type={key === 'emailId' ? 'email' : 'text'}
                  className={`form-control ${errors[key] ? 'is-invalid' : ''}`}
                  name={key}
                  placeholder={`Enter ${key}`}
                  value={formData[key]}
                  onChange={handleChange}
                  autoComplete="new-password"
                />
                {errors[key] && <div className="invalid-feedback">{errors[key]}</div>}
              </div>
            )
        )}
        <div className="mb-3">
          <label htmlFor="userName" className="form-label">
            Username
          </label>
          <input
            id="userName"
            type="text"
            className={`form-control ${errors.userName ? 'is-invalid' : ''}`}
            name="userName"
            placeholder="Enter Username"
            value={formData.userName}
            onChange={handleChange}
            autoComplete="new-password"
          />
          {errors.userName && <div className="invalid-feedback">{errors.userName}</div>}
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">
            Password
          </label>
          <input
            id="password"
            type="password"
            className={`form-control ${errors.password ? 'is-invalid' : ''}`}
            name="password"
            placeholder="Enter Password"
            value={formData.password}
            onChange={handleChange}
            autoComplete="new-password"
          />
          {errors.password && <div className="invalid-feedback">{errors.password}</div>}
        </div>
        <div className="mb-3">
          <label htmlFor="brandName" className="form-label">
            Brand Name
          </label>
          <div className="d-flex">
            <BrandNameInput
              id="brandName"
              type="text"
              className={`form-control ${errors.brandName ? 'is-invalid' : ''}`}
              name="brandName"
              placeholder="Enter Brand Name"
              value={formData.brandName}
              onChange={handleChange}
              autoComplete="new-password"
            />
            <SearchButton type="button" onClick={handleSearch}>
              Search
            </SearchButton>
          </div>
          {errors.brandName && <div className="invalid-feedback">{errors.brandName}</div>}
        </div>
        {searchResult && <div className="alert alert-info">{searchResult}</div>}
        <div className="mb-3">
          <label className="form-label">Privileges</label>
          <CheckboxContainer>
            <input
              type="checkbox"
              id="dashboard"
              name="dashboard"
              checked={formData.privileges.dashboard}
              onChange={handleChange}
              disabled={!isDashboardFound}
            />
            <CheckboxLabel htmlFor="dashboard">Dashboard</CheckboxLabel>
          </CheckboxContainer>
          <CheckboxContainer>
            <input
              type="checkbox"
              id="report"
              name="report"
              checked={formData.privileges.report}
              onChange={handleChange}
              disabled={!isDashboardFound}
            />
            <CheckboxLabel htmlFor="report">Report</CheckboxLabel>
          </CheckboxContainer>
          <CheckboxContainer>
            <input
              type="checkbox"
              id="sampleD"
              name="sampleD"
              checked={formData.privileges.sampleD}
              onChange={handleChange}
              disabled={isDashboardFound}
            />
            <CheckboxLabel htmlFor="dashboard">Sample Dashboard</CheckboxLabel>
          </CheckboxContainer>
          <CheckboxContainer>
            <input
              type="checkbox"
              id="sampleR"
              name="sampleR"
              checked={formData.privileges.sampleR}
              onChange={handleChange}
              disabled={isDashboardFound}
            />
            <CheckboxLabel htmlFor="report">Sample Report</CheckboxLabel>
          </CheckboxContainer>
        </div>
        <div className="mb-3">
          <label className="form-label">User Status</label>
          <CheckboxContainer>
            <input
              type="checkbox"
              id="active"
              name="active"
              checked={formData.status.active}
              onChange={handleChange}
              disabled={!isDashboardFound}
            />
            <CheckboxLabel htmlFor="active">Active</CheckboxLabel>
          </CheckboxContainer>
          <CheckboxContainer>
            <input
              type="checkbox"
              id="deactive"
              name="deactive"
              checked={formData.status.deactive}
              onChange={handleChange}
              disabled={!isDashboardFound}
            />
            <CheckboxLabel htmlFor="deactive">Deactive</CheckboxLabel>
          </CheckboxContainer>
        </div>

      <div className="mb-3">
        <label className="form-label">Report Frequency</label>
        <CheckboxContainer>
          <input
            type="checkbox"
            id="daily"
            name="daily"
            checked={formData.frequency.daily}
            onChange={handleChange}
            disabled={!isDashboardFound || !formData.status.active}
          />
          <CheckboxLabel htmlFor="daily">Daily</CheckboxLabel>
        </CheckboxContainer>
        <CheckboxContainer>
          <input
            type="checkbox"
            id="weekly"
            name="weekly"
            checked={formData.frequency.weekly}
            onChange={handleChange}
            disabled={!isDashboardFound || !formData.status.active}
          />
          <CheckboxLabel htmlFor="weekly">Weekly</CheckboxLabel>
        </CheckboxContainer>
        <CheckboxContainer>
          <input
            type="checkbox"
            id="monthly"
            name="monthly"
            checked={formData.frequency.monthly}
            onChange={handleChange}
            disabled={!isDashboardFound || !formData.status.active}
          />
          <CheckboxLabel htmlFor="monthly">Monthly</CheckboxLabel>
        </CheckboxContainer>
        {errors.frequency && <div className="invalid-feedback">{errors.frequency}</div>}
      </div>

        {errors.apiError && <div className="alert alert-danger" role="alert">{errors.apiError}</div>}
        <button type="submit" className="btn btn-primary">
          Register User
        </button>
      </form>
    </div>
  );
}

export default UserForm;