import React from 'react';
import SupersetEmbeddedDashboard from './dashboard_config_2';
import SideBar from './SideBar';


const Dashboard_2 = () => {
    return (
        <div>
            <SideBar>
                <SupersetEmbeddedDashboard />
            </SideBar>
        </div>
    );
};

export default Dashboard_2;
