import React, { useState, useEffect } from 'react';
import Marquee from 'react-fast-marquee';


export default function Carousel() {
  const [images, setImages] = useState([]);
  const iframeUrl = 'https://prod.loclshop.com/api/pga-imp-iframe';

  useEffect(() => {
    // Fetch data from the JSON endpoint
    fetch(iframeUrl)
      .then(response => response.json())
      .then(data => {
        // Extract image URLs and destination URLs
        const imageDetails = data.content.map(item => ({
          imgUrl: item.image.src,
          destinationLink: item.link.url
        }));
        // Set the state with extracted data
        setImages(imageDetails);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []); // Empty dependency array ensures effect runs only once

  return (
    <>
      <section className="flex h-screen justify-center items-center w-screen">
        <div className="h-[50px] rounded-2xl w-1/2">
          <Marquee play={true} speed={70} pauseOnHover={true} className="border-t rounded-2xl border-b py-3 overflow-hidden grid">
            {images.map((image, index) => (
              <div key={index} className="flex pr-10 flex-col justify-center items-center h-[350px] mx-3">
                <a href={image.destinationLink}>
                  {/* <img src={image.imgUrl} alt={`Image ${index}`} /> */}
                  <img src={image.imgUrl} alt={`Promotion ${index + 1} in carousel`} />

                </a>
              </div>
            ))}
          </Marquee>
        </div>
      </section>
      <style jsx>{`
        img {
          width: 250px; /* Adjust the width as needed */
          height: auto; /* Maintain aspect ratio */
        }
        .carousel-track {
          animation-duration: 10ms; /* Adjust the animation duration as needed */
        }
      `}</style>
    </>
  )
}
