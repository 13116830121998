import React from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import styled from 'styled-components';

const Header = styled.header`
  color: #000000;
  text-align: center;
  padding: 1em 0;
`;

const Main = styled.main`
  padding: 20px;
`;

const ExclusiveOffersAlert = styled.div`
  background-color: #ffcc00;
  color: #000;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 20px;
`;

const MapContainer = styled.section`
  height: 600px;
  width: 100%;
  border-radius: 4px;

  @media (max-width: 768px) {
    height: 300px;
  }
`;

const Button = styled.button`
  background-color: #0072CE;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;

  &:hover {
    background-color: #0056b3;
  }
`;

class Home_coupons extends React.Component {
  componentDidMount() {
    if (!this.mapInitialized) {
      const map = L.map('mapContainer').setView([37.7749, -122.4194], 13);
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        maxZoom: 22,
      }).addTo(map);

      const marker = L.marker([37.7749, -122.4194]).addTo(map);
      marker.bindPopup("<b>Nearby Malls Location!</b><br>Discover great offers here.").openPopup();

      this.mapInitialized = true;
    }
  }

  scrollToMap = () => {
    document.getElementById('mapContainer').scrollIntoView({
      behavior: 'smooth'
    });
  }

  render() {
    return (
      <div>
      <Header>
        <h1>Welcome to loclshop</h1>
      </Header>
      <Main>
        <ExclusiveOffersAlert>
          <h2>Exclusive Offers Await Near You!</h2>
          <p>Step into the proximity of our partner malls and tenants to unlock special deals and discounts, exclusively for you.</p>
          <p>Found a nearby offer? Visit <a href="https://prod.loclshop.com/coupons" target="_blank" rel="noopener noreferrer">loclshop.com/coupons</a> to claim your exclusive mall or tenant discounts!</p>
          <Button onClick={this.scrollToMap}>Show Me Where <span role="img" aria-label="Pin emoji">📍</span></Button>
        </ExclusiveOffersAlert>
        <MapContainer id="mapContainer"></MapContainer>
      </Main>
    </div>
    );
  }
}

export default Home_coupons;
