import React from 'react';

export default function DemoVideo() {
  const videoUrl = "https://prod.loclshop.com/api/uploads/demo_4.mov";

  return (
    <>
      <style>
        {`
          .center-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100vh; // This makes the container fill the viewport height
          }

          .video {
            width: 80%;
            height: 80%;
            margin-bottom: 20px; // Optional: adds space below the video
          }
        `}
      </style>
      <div className="center-content">
        <h1>Demo Video</h1>
        <video
          className="video"
          controls
        >
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </>
  );
}
