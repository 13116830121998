import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';

// Define styled components
const Container = styled.div`
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormField = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  margin-bottom: 8px;
  font-size: 16px;
  color: #333;
`;

const Input = styled.input`
  width: 100%;
  height: 40px;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  &:focus {
    border-color: #0056b3;
    outline: none;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CheckboxInput = styled.input`
  margin-right: 8px;
`;

const CheckboxLabel = styled.label`
  font-size: 14px;
`;

const Button = styled.button`
  padding: 10px 15px;
  background-color: #0056b3;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #003580;
  }
`;

function ModifyUser() {
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    userName: '',
    emailId: '',
    brandName: '',
    location: '',
    privileges: {
      dashboard: false,
      report: false,
      sampleR: false,
      sampleD: false
    },
    frequency: {
      daily: false,
      weekly: false,
      monthly: false
    },
    status: {
      activate: false,
      deactivate: false
    }
  });
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const userId = params.get('id');

    if (userId) {
      fetch(`https://prod.loclshop.com/api/regusers`)
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP status ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          const user = data.find(user => user._id === userId);
          if (user) {
            setUserData(user);
          } else {
            console.log('No user found with ID');
          }
        })
        .catch(error => {
          console.error('Error fetching user data:', error);
        });
    } else {
      console.error('No ID provided in the URL');
      navigate('/manageusers');
    }
  }, [location.search, navigate]);

  const handleChange = e => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      const [field, key] = name.split('.'); // Split field name and key
      setUserData(prev => ({
        ...prev,
        [field]: {
          ...prev[field],
          [key]: checked
        }
      }));
    } else if (name.includes('.')) {
      const [field, key] = name.split('.'); // Split field name and key
      setUserData(prev => ({
        ...prev,
        [field]: {
          ...prev[field],
          [key]: value
        }
      }));
    } else {
      setUserData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };
  

  const handleSubmit = e => {
    e.preventDefault();
    fetch(`https://prod.loclshop.com/api/api/users/update`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(userData)
    })
      .then(response => response.json())
      .then(() => {
        navigate('/manageusers');
      })
      .catch(error => console.error('Failed to update user:', error));
  };

  return (
    <Container>
      <h2>Modify User</h2>
      <StyledForm onSubmit={handleSubmit}>
        {Object.keys(userData).map(key => (
          key !== 'password' && key !== '_id' ? (
            <FormField key={key}>
              <Label htmlFor={key}>{key.charAt(0).toUpperCase() + key.slice(1)}</Label>
              {typeof userData[key] === 'object' ? (
                Object.keys(userData[key]).map(subKey => (
                  <CheckboxContainer key={subKey}>
                    <CheckboxInput
                      type="checkbox"
                      name={`${key}.${subKey}`} // Concatenate field name and subKey
                      checked={userData[key][subKey]}
                      onChange={handleChange}
                    />
                    <CheckboxLabel>{subKey.charAt(0).toUpperCase() + subKey.slice(1)}</CheckboxLabel>
                  </CheckboxContainer>
                ))
              ) : (
                <Input
                  id={key}
                  type={key === 'emailId' ? 'email' : 'text'}
                  name={key}
                  value={userData[key] || ''}
                  onChange={handleChange}
                  disabled={key === 'userName' || key === 'emailId'}
                />
              )}
            </FormField>
          ) : null
        ))}
        <Button type="submit">Update User</Button>
      </StyledForm>
    </Container>
  );
}

export default ModifyUser;