import React from 'react';
import { useLocation } from 'react-router-dom';

import UrlBased from './url_based_landing_page';

import LocationBasedLandingpage from './location_based_landingpage';

const Urlbase = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const brandName = searchParams.get('brand');

    if (brandName) {
        return (
            <UrlBased/>
        );
    } else {
        return (
            <LocationBasedLandingpage/>
        );
    }
};

export default Urlbase;
