import React from "react";
import { Fade } from "react-slideshow-image";
import styled from "styled-components"; // Import styled-components
import "react-slideshow-image/dist/styles.css";

// Define the array of image URLs
const fadeImages = [
  "https://prod.loclshop.com/api/uploads/1710417154276-694162817-target.png",
  "https://prod.loclshop.com/api/uploads/1710417177637-70505525-inf1.png",
  "https://prod.loclshop.com/api/uploads/1710417186970-943123678-inf2.png",
  "https://prod.loclshop.com/api/uploads/1710419597350-46038650-inf3.png",
  "https://prod.loclshop.com/api/uploads/1710419606347-188792866-inf4.png",
  "https://prod.loclshop.com/api/uploads/1710419613609-347685102-steps.png"
];

// Styled component for the container div
const SlideContainer = styled.div`
  margin-top: 20px;
  text-align: center;
`;

// This is a new styled component for each slide
const EachFade = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain; // ensures the image fits within the container
  width: 100%;
  height: 500px; // set a fixed height or make it responsive

  @media (max-width: 768px) { // adjust the breakpoint as needed
    height: 300px; // you might want a smaller height on mobile devices
  }
`;

export default function ImageSlide() {
  return (
    <SlideContainer>
      <Fade duration={1000}>
        {fadeImages.map((image, index) => (
          <EachFade
            key={index}
            style={{ backgroundImage: `url(${image})` }}
            aria-label={`Image ${index + 1}`}
          />
        ))}
      </Fade>
    </SlideContainer>
  );
}