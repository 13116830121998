import React, { useState, useEffect, Children } from 'react';
import { NavLink } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import styled from 'styled-components';
import './sidebar.css';

const StyledContainer = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
`;

const StyledArticle = styled.article`
    margin-bottom: 40px;
    margin-left: 20%;
    width: 180px;

    @media screen and (min-width: 768px) {
        margin-left: 30%;
        width: 1080px;
    }
`;

const StyledParagraph = styled.p`
    line-height: 1.2;
    text-align: justify;
    margin: 20px auto;
    max-width: 700px;
`;

const StyledAds = styled.div`
    text-align: center;
`;

// const StyledAdImage = styled.img`
//     display: block;
//     margin: 0 auto;
//     max-width: 100%;
//     height: auto;
//     border: 2px solid black;
//     border-radius: 5px;
//     animation: borderBlink 0.6s infinite alternate;  
// `;

const StyledAdImage1 = styled.img`
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: 170px;
    border: 2px solid black;
    border-radius: 5px;
    animation: borderBlink 0.6s infinite alternate;  
`;

const StyledAdImage2 = styled.img`
    display: block;
    margin-left:-20%;
    max-width: 240px;
    height: 200px;
    border: 2px solid black;
    border-radius: 5px;
    animation: borderBlink 0.6s infinite alternate;

    @media (min-width: 768px) {
        /* Add styles for screens smaller than 768px */
        max-width: 400px;
        height: 350px;
        margin-left:32%;
    }
`;


const StyledAdImage3 = styled.img`
    display: block;
    margin: 0 auto;
    width: 62%;
    height: auto;
    border: 2px solid black;
    border-radius: 5px;
    animation: borderBlink 0.6s infinite alternate;

    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;

const Sidebar = styled(motion.div)`
    width: 880px;
    transition: width 0.5s ease-in-out;

    @media screen and (min-width: 768px) {
        width: ${({ isOpen }) => (isOpen ? '500px' : '500px')};
    }
`;

const DemoSideBar = ({ children, onDashboardToggle }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [selectedAd, setSelectedAd] = useState(null);

  useEffect(() => {
      // Fetch data from API endpoint
      fetch('https://prod.loclshop.com/api/unbounce-landingpage-data')
          .then(response => response.json())
          .then(data => {
              // Extract brand data from the response
              const brands = data.brands;
              // Map brand data to routes array
              const updatedRoutes = brands.map(brand => ({
                  name: brand.name,
                  videoLink: brand.videoLink,
                  pageLink: brand.pageLink
              }));
              // Update the routes state with the new data
              setRoutes(updatedRoutes);
              // Set the selected advertisement to the first one
              setSelectedAd(updatedRoutes[0]);
          })
          .catch(error => console.error('Error fetching data:', error));
  }, []);

  const toggle = () => {
      setIsOpen(!isOpen);
  };

  const handleRouteClick = (route) => {
      const matchedRoute = routes.find(r => r.name === route.name);
      if (matchedRoute) {
          setSelectedAd(matchedRoute);
      }
      setIsOpen(false);
  };

  const handleVideoClick = (pageLink) => {
      window.open(pageLink, "_self");
  };

  const showAnimation = {
      hidden: {
          width: 0,
          opacity: 0,
          transition: {
              duration: 0.5,
          },
      },
      show: {
          opacity: 1,
          width: "auto",
          transition: {
              duration: 0.5,
          },
      },
  };

  return (
      <div className="main-container">
          <Sidebar
              animate={{
                  transition: {
                      duration: 0.5,
                      type: "spring",
                      damping: 5,
                  },
              }}
              className={`sidebar `}
          >
              <section className="routes">
                  {routes.map((route, index) => (
                      <NavLink
                          key={index}
                          className="link"
                          activeClassName="active"
                          onClick={() => handleRouteClick(route)}
                      >
                          <AnimatePresence>
                              <motion.div
                                  variants={showAnimation}
                                  initial="hidden"
                                  animate="show"
                                  exit="hidden"
                                  className="link_text"
                              >
                                  <b><span>{route.name}</span></b>
                              </motion.div>
                          </AnimatePresence>
                      </NavLink>
                  ))}
              </section>
          </Sidebar>
          <StyledContainer>
              <StyledArticle>
                  <div style={{ textAlign: 'center' }}>
                      <h2>NBA Trade Deadline 2024 Overview</h2>
                  </div>
                  <StyledAdImage1 src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRE5n9O_X9pGV0C95vXCMqsgAo_xxplAIeO0NaN3nr1pFccPtLnwbwUAtu3SrEUqBv95hI&usqp=CAU" alt="NBA Image" />
                  <StyledParagraph>As of the 2024 NBA trade deadline, the Los Angeles Lakers chose not to make any significant moves, despite the rampant speculation leading up to the deadline. </StyledParagraph>
              </StyledArticle>

              <StyledArticle>
                  <StyledAds>
                      <div style={{ textAlign: 'center' }}>
                          <h2>Coupon Advertisement</h2>
                      </div>
                      <div id="adContainer">
                          {selectedAd && (
                              <a href={selectedAd.pageLink} target="_self" rel="noopener noreferrer">
                                  <StyledAdImage2 src={selectedAd.videoLink} alt="Advertisement" onClick={() => handleVideoClick(selectedAd.pageLink)} />
                              </a>
                          )}
                      </div>
                  </StyledAds>
              </StyledArticle>

              <StyledArticle>
                  <div style={{ textAlign: 'center' }}>
                      <h2>Super Bowl LVIII Preview</h2>
                  </div>
                  <StyledAdImage3 src="https://static.www.nfl.com/image/private/t_new_photo_album/league/xa1aqqu4qzk2hytetzsu.jpg" alt="Super Bowl Image" />
                  <StyledParagraph>Super Bowl LVIII promises to be a thrilling showdown, with the San Francisco 49ers facing off against the Kansas City Chiefs at Allegiant Stadium in Las Vegas, Nevada , Super Bowl LVIII promises to be a thrilling showdown, with the San Francisco 49ers facing off against the Kansas City Chiefs at Allegiant Stadium in Las Vegas, Nevada.</StyledParagraph>
              </StyledArticle>
          </StyledContainer>

          <main style={{ width: isOpen ? '90vw' : '95vw' }}>
              {Children.toArray(children)}
          </main>
      </div>
  );
};

export default DemoSideBar;

