import React, { useState, useEffect, useRef } from 'react';
import './landing_page.css'; // Import CSS file for styling
import { motion } from 'framer-motion';
import $ from 'jquery'; // Import jQuery
import 'select2'; // Import Select2 libraryimport Checkbox from '@mui/joy/Checkbox';
import styled from 'styled-components'; // Import styled-components   
import Checkbox from '@mui/joy/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import reverseGeocode from '../components/geoencoding'


export default function PgaSms () {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [imagesData, setImagesData] = useState([]);
    const [isReturningUser, setIsReturningUser] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const selectedSms = useState(null);
    const selectedName = useState(null);
    const [smsSent, setSmsSent] = useState(false); 
    const [showPhoneNumberPopup, setShowPhoneNumberPopup] = useState(false);
    const [openSecond, setOpenSecond] = useState(false);
    const [showError, setShowError] = useState(false);
    const [selectedImages, setSelectedImages] = useState([]);
    const [showTenantAds, setShowTenantAds] = useState(false); 
    const [selectedCategory, setSelectedCategory] = useState('');
    const filteredImages = selectedCategory ? imagesData.filter(image => image.category === selectedCategory) : imagesData;
    const [userLocation, setUserLocation] = useState(null);
    const [userAddress, setUserAddress] = useState(null); // New state for user address
    const [selectAll , setselectAll] = useState(false);
    const selectRef = useRef(null); // Create a ref for the select element
    const newRef = useRef(null);
    const smsnewRef = useRef(null);
    const [firstUser , setfirstUser] = useState(false)
    
    const defUrl = process.env.REACT_APP_API_URL
    const [locations, setLocations] = useState([]); // State to hold all locations
    const [matchMessage, setMatchMessage] = useState(''); // State to hold match message
    const [isTracking, setIsTracking] = useState(true); // State to track if location 

    useEffect(() => {
        $(selectRef.current).select2();
        fetchImagesData(); // Fetch images data when the component mounts
        checkReturningUser(); // Check if the user is returning        
        const userCount =  localStorage.getItem('user-return-count');
        if (userCount === 1) {
            setfirstUser(true); // Set showPhoneNumberPopup to false if the phone number is already in local storage
            userCount++;
        } else {
            setfirstUser(false); // Set showPhoneNumberPopup to true if the phone number is not in local storage
        }
        const storedPhoneNumber = localStorage.getItem('phoneNumber');
        if (storedPhoneNumber) {
            setShowPhoneNumberPopup(false); // Set showPhoneNumberPopup to false if the phone number is already in local storage
        } else {
            setShowPhoneNumberPopup(true); // Set showPhoneNumberPopup to true if the phone number is not in local storage
        }
        if ("geolocation" in navigator) {
            // Get user's current location initially
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setUserLocation({ latitude, longitude });
                    reverseGeocode(latitude, longitude);
                },
                (error) => {
                    console.error('Error getting user location:', error);
                }
            );
            // Get user's current location every 10 seconds
            const locationInterval = setInterval(async () => {
                navigator.geolocation.getCurrentPosition(
                    async (position) => {
                        const { latitude, longitude } = position.coords;
                        setUserLocation({ latitude, longitude });
                        reverseGeocode(latitude, longitude);
    
                        // Fetch location SMS data and send SMS
                        try {
                            const response = await fetch('https://prod.loclshop.com/api/location-sms');
                            if (!response.ok) {
                                throw new Error('Failed to fetch location SMS data');
                            }
                            const locationData = await response.json();
    
                            locationData.forEach((location) => {
                                const { lat, long, sms } = location;
                                console.log('Current Latitude: 1', latitude);
                                console.log('Current Longitude: 1', longitude);
                                const distance = checkMatch(lat, long, latitude, longitude, storedPhoneNumber , sms , lat , long);
                                if (distance <= 300) {
                                    // Send SMS if location is within 300 meters
                                    console.log("inside the location")
                                    sendSMS(storedPhoneNumber, sms, latitude, longitude); // Pass latitude and longitude
                                }
                            });
                        } catch (error) {
                            console.error('Error fetching or processing location SMS data:', error);
                        }
                    },
                    (error) => {
                        console.error('Error getting user location:', error);
                    }
                );
            }, 10000); // 10 seconds interval
            
            return () => {
                clearInterval(locationInterval);
            };
        } else {
            console.error("Geolocation is not supported by this browser.");
        }
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            $(selectRef.current).select2('destroy');
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);
    

    // Function to check if current location matches hardcoded coordinates within a tolerance
    let smsSent1 = false; // Initialize flag to track whether SMS has been sent

    const checkMatch = (smslat, smslong, userlat, userlong , phonenumber , sms , userlat1 , userlong1 ) => {
        const latDiff = Math.abs(smslat - userlat);
        const lonDiff = Math.abs(smslong - userlong);
        if (latDiff <= 1000 && lonDiff <= 1000 && !smsSent1) {
            // Send SMS only if within tolerance and SMS has not been sent yet
            sendSMS(phonenumber, sms, userlat1, userlong1);
            smsSent1 = true; // Update flag to indicate SMS has been sent
            setMatchMessage('Match found!');
            setIsTracking(false); // Stop tracking once match is found
        } else {
            setMatchMessage('No match within tolerance');
        }
};

    const StyledFooterButton = styled.button`
        position: absolute;
        bottom: 10px;
        left: 18%;
        margin-top: 4%;
        padding: 10px 20px;
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: #0056b3;
        }

        @media screen and (min-width: 768px){
            left: 42%; // Adjusted left position for screens <= 768px width
        }

        @media screen and (min-width: 431px) and (max-width: 768px) {
            left: 35%; // Adjusted left position for screens <= 768px width
        }
    `;

    const SFooter = () => (
        <div className='stickyfooter'>
            <StyledFooterButton type="button" onClick={postData}>
                {selectedImages.length === imagesData.length ? 'Get All Coupon Codes!' : 'Get Selected Coupon Codes!'}
            </StyledFooterButton>  
        </div>
    );
    
    const handleOutsideClick = (e) => {
        const storedPhoneNumber = localStorage.getItem('phoneNumber');
        if (newRef.current && !newRef.current.contains(e.target)) {
            setSelectedImage(null);
            setOpenSecond(false);
        }
        if (storedPhoneNumber && smsnewRef.current && !smsnewRef.current.contains(e.target)) {
            setSelectedImage(null);
        }
      };

    // Function to fetch images data from the server
    const fetchImagesData = async () => {
        try {
            const response = await fetch('https://prod.loclshop.com/api/pga-images-demo', {
                mode: 'cors' // Add the mode option here
            });
            if (!response.ok) {
                throw new Error('Failed to fetch images data');
            }
            const jsonData = await response.json();
            setImagesData(jsonData);
        } catch (error) {
            console.error('Error fetching images data:', error);
        }
    };

    // Function to save phone number in local storage
    const savePhoneNumber = () => {
        if (phoneNumber.trim() !== '') {
            localStorage.setItem('phoneNumber', phoneNumber);
            setShowPhoneNumberPopup(false);
        } else {
            // Show error message
            setShowError(true);
            // Hide error message after 3 seconds
            setTimeout(() => {
                setShowError(false);
            }, 5000);
        }
    };

    const savePhoneNumber1 = () => {
        localStorage.setItem('phoneNumber', phoneNumber);
        setPhoneNumber(phoneNumber)
        const formattedPhoneNumber = phoneNumber.startsWith('+1') ? phoneNumber : '+1' + phoneNumber.trim();
    
        if (phoneNumber.length !== 10) {
            setShowError(true);
        } else {
            if ("geolocation" in navigator) {
                // Get user's current location
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const { latitude, longitude } = position.coords;
                        console.log(latitude,longitude)
                        const userLocation = { latitude, longitude };
                        reverseGeocode(latitude, longitude)
                            .then(address => {
                                setUserAddress(address)
                                console.log("address from inside :", address)
                                // Post phone number along with location and address to endpoint
                                axios.post('https://prod.loclshop.com/api/save-phone-number', {
                                    phoneNumber: formattedPhoneNumber,
                                    latitude:  latitude, 
                                    longitude: longitude,
                                    address : address
                                })
                                    .then(response => {
                                        // Handle success if needed
                                        console.log('Phone number, location, and address saved successfully:', response.data);
                                        // Reset phone number input
                                        setShowPhoneNumberPopup(false);
                                        setfirstUser(true);
                                        localStorage.setItem('user-return-count', 1);
                                        window.location.reload();
                                    })
                                    .catch(error => {
                                        // Handle error if needed
                                        console.error('Error saving phone number:', error);
                                    });
                            })
                            .catch(error => {
                                console.error('Error getting address:', error);
                            });
                    },
                    (error) => {
                        console.error('Error getting user location:', error);
                    }
                );
            } else {
                console.error("Geolocation is not supported by this browser.");
            }
        }
    };
    

    // Function to check if the user is returning
    const checkReturningUser = () => {
        const storedPhoneNumber = localStorage.getItem('phoneNumber');
        if (storedPhoneNumber) {
            setIsReturningUser(true);
            setPhoneNumber(storedPhoneNumber);
            setShowPhoneNumberPopup(true); // Set showPhoneNumberPopup to false if the phone number is already in local storage
        } else {
            setShowPhoneNumberPopup(false); // Set showPhoneNumberPopup to true if the phone number is not in local storage
        }
    };

    // Event handler for phone number input change
    const handlePhoneChange = (event) => {
        setPhoneNumber(event.target.value);
    };

    const postData = () => {
        let smsValues = []; // Initialize an array to store SMS values
        // If none of the images are selected and neither "Select All" nor "Tenant Ads" checkbox is checked
        if (selectedImages.length === 0 && !showTenantAds && !selectedCategory) {
            // Fetch SMS values from the URL
            fetchImagesData().then(() => {
                // Extract SMS values from imagesData
                smsValues = imagesData.map((imageItem) => imageItem.sms);
                // Send SMS with fetched SMS values and user's location
                sendSMS(phoneNumber, smsValues, userLocation.latitude, userLocation.longitude);
            }).catch((error) => {
                console.error('Error fetching images data:', error);
            });
        } else {
            // Extract SMS values based on the selected category and tenant ads checkbox
            let selectedImagesSmsValues;
            if ((showTenantAds || selectedCategory) && selectedImages.length > 0) {
                // Logic to extract SMS values based on selected category or tenant ads
                if (selectedCategory) {
                    // Filter selected images based on the selected category
                    const selectedCategoryImages = selectedImages.filter(imageItem => imageItem.category === selectedCategory);
                    // Extract SMS values from selected images in the specified category
                    selectedImagesSmsValues = selectedCategoryImages.map(imageItem => imageItem.sms);
                } else {
                    // Extract SMS values from selected tenant ads if "Tenant Ads" checkbox is checked
                    const selectedTenantAds = selectedImages.filter(imageItem => imageItem.type === "tenant");
                    selectedImagesSmsValues = selectedTenantAds.map(imageItem => imageItem.sms);
                }
            } else {
                // Extract SMS values from all selected images if no category is selected or "Tenant Ads" checkbox is not checked
                selectedImagesSmsValues = selectedImages.map(imageItem => imageItem.sms);
            }    
            // Implement your post data logic here
            const storedPhoneNumber = localStorage.getItem('phoneNumber');
            if (storedPhoneNumber === phoneNumber) {
                console.log(phoneNumber);
                sendSMS(phoneNumber, selectedImagesSmsValues, userLocation.latitude, userLocation.longitude); // Pass selected images' SMS values to sendSMS function               
            } else {
                localStorage.setItem('phoneNumber', phoneNumber);
                sendSMS(phoneNumber, selectedImagesSmsValues, userLocation.latitude, userLocation.longitude); // Pass selected images' SMS values to sendSMS function                
            }
        }
    };

    const shouldShowFooter = () => {
        return selectedImage || selectedImages.length > 0; // Footer should appear if any image is selected or if any image is selected through "Select All" checkbox
    };

    // Function to send SMS
    const sendSMS = (phoneNumber, smsValues, latitude, longitude) => {
        const formattedPhoneNumber = phoneNumber.startsWith('+1') ? phoneNumber : '+1' + phoneNumber.trim();
        
        // Reverse geocode to get the address
        reverseGeocode(latitude, longitude)
            .then(address => {
                // Concatenate all SMS messages into a single body
                const messageBody = Array.isArray(smsValues) ? smsValues.join('\n\n') : smsValues;
    
                // Send the concatenated message as one SMS
                return fetch('https://prod.loclshop.com/api/loclsms2', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ 
                        phoneNumber: formattedPhoneNumber, 
                        adname: messageBody,
                        address: address, // Include user's address
                        latitude: latitude, // Include latitude
                        longitude: longitude // Include longitude
                    }),
                });
            })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to send SMS');
                }
                console.log('SMS sent successfully');
                setSmsSent(true);
                setTimeout(() => {
                    setSmsSent(false);
                }, 3000);
                // Clear the input field if the response is OK
            })
            .catch((error) => {
                console.error('Error sending SMS:', error);
            });
    };
    
    
    const handleImageClick = (imageItem) => {
        const index = selectedImages.findIndex(item => item.img_url === imageItem.img_url);
        if (index === -1) {
            setSelectedImages(prevState => [...prevState, imageItem]);
        } else {
            setSelectedImages(prevState => prevState.filter(item => item.img_url !== imageItem.img_url));
        }
    };

    // Function to close the popup
    const closePopup = () => {
        setSelectedImage(null);
        setSmsSent(true); 
        setShowPhoneNumberPopup(false);
        
    };


    const toggleSelectAll = () => {
        setselectAll(!selectAll); // Toggle the selectAll state
        if (!selectAll) {
            setSelectedImages(imagesData); // If selectAll is false, select all images
        } else {
            setSelectedImages([]); // If selectAll is true, deselect all images
        }
    };
    
    const blinkBorderKeyframes = `
        @keyframes zoomInOut {
            0% {
                transform: scale(1.2);
                
            }
            50% {
                transform: scale(1.5);
                
            }
            100% {
                transform: scale(1.2);
                
            }
        }

    `;

    const Image_screen = `
        @keyframes zoomInOut {
            0% {
                transform: scale(1.2);
                
            }
            50% {
                transform: scale(1.5);
                
            }
            100% {
                transform: scale(1.2);
                
            }
        }

    `;

    const renderImages = () => {
        let filteredImages = imagesData; // Initialize with all images
        if (showTenantAds && !selectedCategory) {
            // Filter images to display only those with type "tenant"
            filteredImages = imagesData.filter(image => image.type === "tenant");
        }

        else if (selectedCategory && showTenantAds){
            filteredImages = filteredImages.filter(image => image.category === selectedCategory);
        }

        else if (selectedCategory){
            filteredImages = filteredImages.filter(image => image.category === selectedCategory);
        }
        
        return filteredImages.map((imageItem, index) => (
            <div className="col-lg-4 col-md-6 col-sm-12" key={index}>
                <div className="deal" onClick={() => handleImageClick(imageItem)}>
                <img 
                    src={imageItem.img_url} 
                    alt={imageItem.sms} 
                    style={{ 
                        borderRadius: '20px', 
                        paddingTop: '10px', 
                        borderColor: '#C6C5C5', 
                        boxShadow: '0 16px 24px rgba(0, 0, 0, 0.2)',
                        height: '470px', // Default height for smaller screens
                        width: '95%', // Ensure image fills its container
                    }} 
                />
                <style>{Image_screen}</style>



                <Checkbox
                    checked={selectedImages.some(item => item.img_url === imageItem.img_url)}
                    onChange={() => handleImageClick(imageItem)}
                    style={{
                        position: 'absolute',
                        bottom: '22px',
                        left: '30px',
                        borderRadius: '5px', // Set the border radius to 5px
                        transform: 'scale(2)', // Increase the size of the inner checkbox
                        animation: 'zoomInOut 0.6s infinite alternate', // Add animation property
                        border: '2px solid black' // Set the border color to black
                    }}
                />
                    <style>{blinkBorderKeyframes}</style>

                </div>
            </div>
        ));
        
    };

    const sleep = (duration) => {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve();
          }, duration);
        });
      }

     const RenderCategoryDropdown = ({ imagesData, selectedCategory, setSelectedCategory }) => {
        const categories = [...new Set(imagesData.map(image => image.category))];
        const [open, setOpen] = useState(false);
        const [options, setOptions] = useState([]);
        const loading = open && options.length === 0;
    
        useEffect(() => {
            let active = true;
    
            if (!loading) {
                return undefined;
            }
    
            (async () => {
                await sleep(1e3); // For demo purposes.
    
                if (active) {
                    setOptions([...categories]);
                }
            })();
    
            return () => {
                active = false;
            };
        }, [loading, categories]); // Include categories in the dependency array
    
        useEffect(() => {
            if (!open) {
                setOptions([]);
            }
        }, [open]);
    
        // Filter images based on selected category
        const filteredImages = imagesData.filter(image => !selectedCategory || image.category === selectedCategory);
        return (
            <div>
                <Autocomplete className='custom-dropdown'
                    id="asynchronous-demo"
                    sx={{
                        width: '90%',
                        marginLeft: '5%',
                        '@media only screen and (min-width: 768px)': {
                            width: '50%', // Adjust width for screens wider than 768px
                            marginLeft: '45%', // Adjust margin for screens wider than 768px
                            marginTop: '-7%',
                        }
                    }}
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    getOptionLabel={(option) => option} // Updated getOptionLabel function
                    options={options} // Use options instead of categories here
                    loading={loading}
                    onChange={(event, value) => setSelectedCategory(value)} // Update selected category
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Search"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
                <div>                   
                </div>
            </div>
        );
    }

    const StyledSelectAllWrapper = styled.div`
        position: absolute;
        top: 120%;
        right: 9%;
        display: flex;
        align-items: center;

        @media screen and (min-width: 768px) {
            top: 60%; // Adjusted top position for screens <= 768px width
            left: 0%; // Adjusted right position for screens <= 768px width
        }
    `;

    const StyledCheckboxLabel = styled.label`
        margin-top: 5px;
        text-align: right;

        @media (max-width: 767px) {
            text-align: center;
        }
        @media (max-width: 400px) {
            text-align: left;
        }
    `;


    const dropIn = {
        hidden: {
          y: "-100vh",
          opacity: 0,
        },
        visible: {
          y: "0",
          opacity: 1,
          transition: {
            duration: 1,
            type: "spring",
            damping: 20,
            stiffness: 500,
          },
        },
        exit: {
          y: "100vh",
          opacity: 0,
        },
      };


    return (
        
        <div>
        <div className="container">
            <div className="row">
                <div className="col-12">
                <h1 className="text-center title" style={{ fontSize: '29px' }}>
                {firstUser && isReturningUser ? "Welcome to PGA Tampa, FL" : isReturningUser ? "Welcome back to PGA Tampa, FL" : "Welcome to PGA Tampa, FL"}
                </h1>
                <StyledSelectAllWrapper>
                    <Checkbox 
                        type="checkbox" 
                        checked={selectedImages.length === imagesData.length } 
                        onChange={toggleSelectAll} 
                        id="selectAll" 
                        name="selectAll" 
                        value="Select All" 
                        style={{ marginRight: '5px' }}/> 
                    <StyledCheckboxLabel htmlFor="selectAll">Select All Coupons</StyledCheckboxLabel>
                </StyledSelectAllWrapper>
                        {RenderCategoryDropdown({imagesData, selectedCategory, setSelectedCategory })}
                    </div>
                </div>
            </div>
            <div className="img-container">           
                        <div className="row">
                            {renderImages()}
                        </div>
                    </div>
        
            {showPhoneNumberPopup && (
                <motion.div className="phone-popup">
                        <motion.div 
                        variants={dropIn}
                        initial="hidden"
                        animate="visible"
                        exit="exit"          
                        className="phone-popup-content">
                        <div className="col-12 phone-container d-flex justify-content-center">
                            <div className="row">
                                <div className="col-12 phonenumber">
                                    <div className="form-group text-center">
                                    <div className="phone-form">
                                        {showError && <p className="error-message">Please enter your phone number.</p>}
                                    </div>
                                    <h2>Unlock Exclusive Offers!</h2>
                                        <label className="info" htmlFor="phone">
                                        Select offers to receive exclusive SMS coupons instantly!
                                        <br></br><br></br>Join our fast-growing network of savvy shoppers for daily exclusive savings!<br></br>
                                        </label>
                                        <div className="phone-form">
                                            <input 
                                                type="text" 
                                                className="form-control phone-number-ip" 
                                                id="phoneinp" 
                                                name="phone" 
                                                value={phoneNumber} 
                                                onChange={handlePhoneChange} 
                                                placeholder="Phone Number" 
                                            />
                                            <button 
                                                type="submit" 
                                                className="submit-link" 
                                                onClick={savePhoneNumber && savePhoneNumber1} 
                                                style={{ 
                                                    width: '70%', 
                                                    backgroundColor: phoneNumber.length === 10 ? 'green' : '#007BFF' // Change button color to green if phone number length is 10
                                                }}
                                            >
                                                Start Saving Now!
                                            </button>
                                            <p>
                                                By entering your number, you consent to our 
                                                <span className="sec-terms-link" onClick={() => setOpenSecond(true)}>Terms</span> 
                                                and will receive SMS coupons.
                                            </p>
                                            <a href="/" className="closeModalLink">No thanks, I prefer to pay full price.</a>
                                        </div>                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
            )}

{openSecond && (
                <motion.div className="second-popup">
                    <motion.div 
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{
                            duration: 0.3,
                            ease: [0, 0.71, 0.2, 1.01],
                            scale: {
                                type: "spring",
                                damping: 5,
                                stiffness: 100,
                                restDelta: 0.001
                            }
                        }}
                        className="second-popup-content" ref={newRef}>
                        <div className="second-container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group text-center">
                                    <label className="text-center info2" htmlFor="phone">
                                    <h4>Privacy Policy</h4>
                                        <ul>
                                            <li>
                                                <strong>Introduction</strong>
                                                <ul>
                                                    <li>This Privacy Policy governs the collection and use of personal information for ad promotions and coupon code distribution by [Your Company Name] ("the Company").</li>
                                                    <li>By providing your personal information for ad promotions and coupon code distribution, you agree to abide by this Privacy Policy.</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <strong>Collection of Personal Information</strong>
                                                <ul>
                                                    <li>The Company collects personal information, including phone numbers, for the purpose of sending ad promotions and coupon codes to users.</li>
                                                    <li>By providing your personal information, you consent to receive promotional messages and coupon codes from the Company.</li>
                                                    <li>Your personal information will be kept confidential and will not be shared with third parties without your consent.</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <strong>Use of Personal Information</strong>
                                                <ul>
                                                    <li>The Company may use the collected personal information to send ad promotions and coupon codes via SMS or other messaging platforms.</li>
                                                    <li>You may opt-out of receiving promotional messages and coupon codes at any time by contacting the Company or following the unsubscribe instructions provided in the messages.</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <strong>Coupon Code Distribution</strong>
                                                <ul>
                                                    <li>Coupon codes distributed by the Company are for promotional purposes only and have no cash value.</li>
                                                    <li>Coupon codes may have expiration dates and usage restrictions, which will be clearly communicated at the time of distribution.</li>
                                                    <li>The Company reserves the right to modify or cancel coupon codes at any time without prior notice.</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <strong>User Responsibilities</strong>
                                                <ul>
                                                    <li>You are responsible for providing accurate and up-to-date personal information to the Company.</li>
                                                    <li>You agree not to misuse or abuse the coupon codes distributed by the Company for personal gain or profit.</li>
                                                    <li>You agree to use the coupon codes in accordance with any terms and conditions provided by the Company.</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <strong>Limitation of Liability</strong>
                                                <ul>
                                                    <li>The Company shall not be liable for any damages or losses arising from the use or misuse of coupon codes distributed to users.</li>
                                                    <li>The Company shall not be responsible for any errors or delays in the delivery of promotional messages or coupon codes.</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <strong>Governing Law</strong>
                                                <ul>
                                                    <li>This Privacy Policy shall be governed by and construed in accordance with the laws of [Your Country/State].</li>
                                                    <li>Any disputes arising out of or relating to this Privacy Policy shall be subject to the exclusive jurisdiction of the courts in [Your Jurisdiction].</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <strong>Changes to Privacy Policy</strong>
                                                <ul>
                                                    <li>The Company reserves the right to update or modify this Privacy Policy at any time without prior notice.</li>
                                                    <li>Users will be notified of any changes to the Privacy Policy via the contact information provided.</li>
                                                </ul>
                                            </li>
                                        </ul>
                                        <button className="btn btn-primary"onClick={() => setOpenSecond(false)}>Close</button>
                                    </label> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
            )}

        {smsSent && (
                <div className="sms-popup">
                    <div className="sms-popup-content">
                        <h2 className="text-center">Coupon Code sent in SMS Successfully</h2>                        
                    </div>
                </div>
            )}
           {shouldShowFooter() && <SFooter />}       
        </div>
    );
};

