import React from 'react';
import ManageUsers from './managerusers';
import SideBar from './SideBar';


const ManageUsersComp = () => {
    return (
        <div>
            <SideBar>
                <ManageUsers />
            </SideBar>
        </div>
    );
};

export default ManageUsersComp;